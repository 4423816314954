/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ZZZCharacter } from '../components/zzz-character';
import {
  faHandFist,
  faGavel,
  faFlask,
  faGhost
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZZZBangboo } from '../components/zzz-bangboo';

interface IProps {
  name: string;
  slug: string;
  character_element: string;
}

export const ZZZProfileTeams: React.FC<IProps> = ({
  slug,
  character_element,
  name
}) => {
  return (
    <>
      {slug === 'caesar' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Jane Doe Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Soldier 11 Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="caesar"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support / Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'qingyi' && (
        <>
          <p>
            Due to the way Qingyi's additional ability team requirements work
            she can be quite literally played in almost all standard team
            compositions consisting of a Stunner, Attacker and Support and is in
            many cases actually the best in slot as well. For this reason treat
            the teams below simply as samples of the teams she can be played in
            not the only teams.
          </p>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Zhu Yuan Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="resonaboo" showLabel />
                    <ZZZBangboo mode="icon" slug="devilboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Billy Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="boollseye" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Nekomata Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nekomata"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Grace/Anton Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(slug === 'zhu-yuan' || slug === 'nicole-demara') && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best Zhu Yuan Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="resonaboo" showLabel />
                    <ZZZBangboo mode="icon" slug="devilboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(slug === 'zhu-yuan' ||
        slug === 'nicole-demara' ||
        slug === 'anby-demara') && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Zhu Yuan Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="zhu-yuan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="resonaboo" showLabel />
                    <ZZZBangboo mode="icon" slug="devilboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(slug === 'koleda' || slug === 'lucy') && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Mono-Fire Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="rocketboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'lucy' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>A-Rank Assault Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'piper' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Main Carry Piper Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="rocketboo" showLabel />
                    <ZZZBangboo mode="icon" slug="cryboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Main Carry Piper F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="rocketboo" showLabel />
                    <ZZZBangboo mode="icon" slug="cryboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Max Disorder Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Stun Disorder Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'soldier-11' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Best team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="rocketboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Max Stun</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="rocketboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="cryboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(slug === 'ellen' || slug === 'lycaon' || slug === 'soukaku') && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Mono-Ice</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal-pets">
                    <ZZZBangboo mode="icon" slug="sharkboo" showLabel />
                    <ZZZBangboo mode="icon" slug="butler" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'ellen' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ellen"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="penguinboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(slug === 'corin' || slug === 'lycaon') && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Corin Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="corin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lycaon"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="butler" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(slug === 'rina' || slug === 'grace-howard' || slug === 'anton') && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Shock Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(slug === 'grace-howard' || slug === 'anton') && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Shock Team (F2P)</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'ben' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Max Stun</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="koleda"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="rocketboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>F2P Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="soldier-11"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="ben"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="cryboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'anby-demara' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Billy Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="boollseye" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Corin Team F2P</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="corin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="boollseye" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Nekomata Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nekomata"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Grace Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anton"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'corin' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Corin Team F2P</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="corin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="bangvolver" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'billy-kid' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Billy Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="boollseye" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'nekomata' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Nekomata Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nekomata"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="soukaku"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'nicole-demara' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Billy Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="billy-kid"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="boollseye" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Nekomata Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nekomata"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="nicole-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="amillion" showLabel />
                    <ZZZBangboo mode="icon" slug="bagboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'grace-howard' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Max Disorder Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Stun Disorder Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="piper"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="anby-demara"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'jane-doe' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Stun Jane Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Pure Anomaly Jane Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {slug === 'seth' && (
        <>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Stun Jane Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="qingyi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role stun">
                    <FontAwesomeIcon icon={faGavel} width="18" /> Stun
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Pure Anomaly Jane Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="grace-howard"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role sub-dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> SUB DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`character-teams box ${character_element}`}>
            <div className="team-header">
              <p>Double Support Jane Team</p>
            </div>
            <div className="single-team">
              <div className="team-row with-bangboo">
                <div className="with-role">
                  <div className="character main">
                    <ZZZCharacter
                      mode="icon"
                      slug="jane-doe"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal">
                    <ZZZCharacter
                      mode="icon"
                      slug="seth"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="rina"
                      enablePopover
                      showLabel
                    />
                    <ZZZCharacter
                      mode="icon"
                      slug="lucy"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="role support">
                    <FontAwesomeIcon icon={faFlask} width="18" /> Support
                  </div>
                </div>
                <div className="with-role">
                  <div className="character main multi-equal-pets">
                    <ZZZBangboo mode="icon" slug="plugboo" showLabel />
                    <ZZZBangboo mode="icon" slug="electroboo" showLabel />
                  </div>
                  <div className="role bangboo">
                    <FontAwesomeIcon icon={faGhost} width="18" /> Bangboo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
