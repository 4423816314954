/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IZZZCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './zzz-character-dynamic.scss';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faChartLine,
  faCircleInfo,
  faDiagramProject,
  faPeopleGroup,
  faShieldHalved,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { ZZZStatsCalculator } from '../modules/zzz/common/components/zzz-stats';
import { ZZZSkillNew } from '../modules/zzz/common/components/zzz-skill-new';
import { ZZZSkillCustom } from '../modules/zzz/common/components/zzz-custom-skill';
import { RatingBox } from '../modules/common/components/rating-box';
import { ZZZEngine } from '../modules/zzz/common/components/zzz-engine';
import { ZZZStat } from '../modules/zzz/common/components/zzz-stat';
import { ZZZCoreProfile } from '../modules/zzz/common/profile/zzz-core';
import { ZZZProfileSets } from '../modules/zzz/common/profile/zzz-profile-sets';
import { ZZZProfileSynergies } from '../modules/zzz/common/profile/zzz-profile-synergies';
import { ZZZProfileTeams } from '../modules/zzz/common/profile/zzz-profile-teams';
import { ZZZCharacterCalcs } from '../modules/zzz/common/profile/zzz-profile-calcs';
import { ZZZSkillsPrio } from '../modules/zzz/common/profile/zzz-profile-skill';
import { ZZZWeaponNotes } from '../modules/zzz/common/profile/zzz-profile-engines';
import { YoutubeEmbed } from '../modules/common/components/youtube-embed';
import { ZZZProfileHowTo } from '../modules/zzz/common/profile/zzz-profile-how';
import { ZZZProfileTeamCalcs } from '../modules/zzz/common/profile/zzz-profile-team-calcs';
import { ZZZCharacterCalcsDaze } from '../modules/zzz/common/profile/zzz-profile-calcs-stun';
import { ZZZShiyuCharData } from '../modules/zzz/shiyu/zzz-shiyu-char-stats';
import { ZZZCharacter } from '../modules/zzz/common/components/zzz-character';
import { ShiyuDuos } from '../modules/zzz/shiyu/duos';
import { ShiyuAll } from '../modules/zzz/shiyu/stage-all';
import { ZZZBuilds } from '../modules/zzz/shiyu/builds';
import { ZZZDiskSet } from '../modules/zzz/common/components/zzz-disk-set';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IZZZCharacterNodes {
  nodes: IZZZCharacter[];
}

interface IZZZCharacterEntry {
  currentUnit: IZZZCharacterNodes;
}

interface IProps {
  data: IZZZCharacterEntry;
}

const ZZZCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');
  const [currentTabSkills, setCurrentTabSkills] = useState('Basic');
  const [mocTeams, setMocTeams] = useState(
    ShiyuAll.filter((team) => {
      if (team.char_one.toLowerCase() === character.slug) return true;
      if (team.char_two.toLowerCase() === character.slug) return true;
      if (team.char_three.toLowerCase() === character.slug) return true;
    })
  );

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  const items = mocTeams.slice(0, 10).map((item, index) => (
    <div className="team-row" key={index}>
      <div className="column info">
        <p className="rank">Rank {item.rank}</p>
        <p className="usage">App. rate: {item.app_rate}%</p>
        <p className="rounds">Avg. Score {item.avg_round}</p>
      </div>
      <div className="column characters">
        <ZZZCharacter
          slug={item.char_one.toLowerCase()}
          mode="icon"
          showIcon
          enablePopover
        />
        <ZZZCharacter
          slug={item.char_two.toLowerCase()}
          mode="icon"
          showIcon
          enablePopover
        />
        <ZZZCharacter
          slug={item.char_three.toLowerCase()}
          mode="icon"
          showIcon
          enablePopover
        />
      </div>
    </div>
  ));

  return (
    <DashboardLayout
      className={'generic-page character-page character-zzz '}
      game="zzz"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background ${character.element} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={6} xxl={character.videos ? 6 : 9}>
            <div className={`content-header ${character.element}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  is an
                  {character.rarity === 'Unknown' && <strong> Unknown</strong>}
                  {character.rarity === 'A' && (
                    <StaticImage
                      src="../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                      className="faction-icon"
                    />
                  )}
                  {character.rarity === 'S' && (
                    <StaticImage
                      src="../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                      className="faction-icon"
                    />
                  )}{' '}
                  rank character with the{' '}
                  <strong className={`${character.element}`}>
                    {character.element}
                  </strong>{' '}
                  attribute who belongs to the
                  {character.style === 'Anomaly' && (
                    <StaticImage
                      src="../images/zzz/icons/style_anomaly.png"
                      alt="Anomaly"
                      className="faction-icon"
                    />
                  )}
                  {character.style === 'Stun' && (
                    <StaticImage
                      src="../images/zzz/icons/style_stun.png"
                      alt="Stun"
                      className="faction-icon"
                    />
                  )}
                  {character.style === 'Defence' && (
                    <StaticImage
                      src="../images/zzz/icons/style_defence.png"
                      alt="Defence"
                      className="faction-icon"
                    />
                  )}
                  {character.style === 'Support' && (
                    <StaticImage
                      src="../images/zzz/icons/style_support.png"
                      alt="Support"
                      className="faction-icon"
                    />
                  )}
                  {character.style === 'Attack' && (
                    <StaticImage
                      src="../images/zzz/icons/style_attack.png"
                      alt="Attack"
                      className="faction-icon"
                    />
                  )}{' '}
                  <strong className={`${character.style}`}>
                    {character.style}
                  </strong>{' '}
                  Specialty and who is part of the
                  {character.faction === 'Section 6' && (
                    <StaticImage
                      src="../images/zzz/factions/faction_section.webp"
                      alt="Section 6"
                      className="faction-icon"
                    />
                  )}
                  {character.faction === 'Victoria Housekeeping Co.' && (
                    <StaticImage
                      src="../images/zzz/factions/faction_victoria.webp"
                      alt="Victoria Housekeeping Co."
                      className="faction-icon"
                    />
                  )}
                  {character.faction === 'Cunning Hares' && (
                    <StaticImage
                      src="../images/zzz/factions/faction_gentle.webp"
                      alt="Gentle House AKA Cunning Hares"
                      className="faction-icon"
                    />
                  )}
                  {character.faction === 'Sons of Calydon' && (
                    <StaticImage
                      src="../images/zzz/factions/faction_sons.webp"
                      alt="Sons of Calydon"
                      className="faction-icon"
                    />
                  )}
                  {character.faction === 'OBOLS Squad' && (
                    <StaticImage
                      src="../images/zzz/factions/faction_obols.webp"
                      alt="OBOLS Squad"
                      className="faction-icon"
                    />
                  )}
                  {character.faction === 'N.E.P.S.' && (
                    <StaticImage
                      src="../images/zzz/factions/faction_neps.webp"
                      alt="N.E.P.S."
                      className="faction-icon"
                    />
                  )}
                  {character.faction === 'Belobog Heavy Industries' && (
                    <StaticImage
                      src="../images/zzz/factions/faction_belobog.webp"
                      alt="Belobog Heavy Industries"
                      className="faction-icon"
                    />
                  )}{' '}
                  <strong className={`${character.faction}`}>
                    {character.faction}
                  </strong>{' '}
                  faction.
                </h2>
                <div className="char-intro">
                  {character.introduction ? (
                    <>{renderRichText(character.introduction, options)}</>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
          <Col xs={12} xl={6} xxl={character.videos ? 6 : 3}>
            <div className="video-promo ">
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Video guide
              </div>
              <div className="video-guide">
                {character.videos ? (
                  <>
                    <div>
                      <p>
                        <strong className={`${character.element}`}>
                          {character.name}
                        </strong>{' '}
                        has at least one video guide available, so if you prefer
                        this sort of medium to learn more about a character,
                        check it out:
                      </p>
                    </div>
                    {character.videos.map((videoLink, idx) => {
                      return (
                        <Row key={idx} className="video-row">
                          {idx === 0 && (
                            <Col xs={12} lg={6}>
                              <YoutubeEmbed embedId={videoLink.video} />
                            </Col>
                          )}
                        </Row>
                      );
                    })}
                  </>
                ) : (
                  <p>This character has no video guide yet.</p>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="last-updated">
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Last updated
          </div>
          <div className="info-box with-margin">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              profile has been last updated on{' '}
              <strong>{character.updatedAt}</strong>.
            </p>
          </div>
        </div>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`${character.element}`}>{character.name}</strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Build</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Shiyu' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Shiyu')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faShieldHalved} width="18" />
            </div>
            <p>Shiyu Defense</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Teams' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Teams')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
            </div>
            <p>Rotation & Teams</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Performance' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Performance')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faChartLine} width="18" />
            </div>
            <p>Calculations</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Core skills
          </div>
          {character.upcoming != true || character.slug === 'burnice' ? (
            <ZZZCoreProfile
              name={character.slug}
              character_element={character.element}
            />
          ) : (
            <div className="info-box ">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>core skills aren't available yet.</strong> They will be
                added once more information about the character are released.
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.upcoming != true || character.slug === 'burnice' ? (
            <>
              <div className="skills-v2">
                <div className="tabs-skills">
                  <div
                    className={`single-tab ${character.element} ${
                      currentTabSkills === 'Basic' ? 'active' : ''
                    }`}
                    onClick={() => setCurrentTabSkills('Basic')}
                  >
                    <StaticImage
                      src="../images/zzz/icons/icon_basic.png"
                      alt="Basic"
                    />{' '}
                    Basic
                  </div>
                  <div
                    className={`single-tab ${character.element} ${
                      currentTabSkills === 'Dodge' ? 'active' : ''
                    }`}
                    onClick={() => setCurrentTabSkills('Dodge')}
                  >
                    <StaticImage
                      src="../images/zzz/icons/icon_dodge.png"
                      alt="Dodge"
                    />{' '}
                    Dodge
                  </div>
                  <div
                    className={`single-tab ${character.element} ${
                      currentTabSkills === 'Assist' ? 'active' : ''
                    }`}
                    onClick={() => setCurrentTabSkills('Assist')}
                  >
                    <StaticImage
                      src="../images/zzz/icons/icon_assist_m.png"
                      alt="Assist"
                    />{' '}
                    Assist
                  </div>
                  <div
                    className={`single-tab ${character.element} ${
                      currentTabSkills === 'Special' ? 'active' : ''
                    }`}
                    onClick={() => setCurrentTabSkills('Special')}
                  >
                    <StaticImage
                      src="../images/zzz/icons/icon_special_full.png"
                      alt="Special"
                    />{' '}
                    Special
                  </div>
                  <div
                    className={`single-tab ${character.element} ${
                      currentTabSkills === 'QTE' ? 'active' : ''
                    }`}
                    onClick={() => setCurrentTabSkills('QTE')}
                  >
                    <StaticImage
                      src="../images/zzz/icons/icon_ulti_full.png"
                      alt="qte"
                    />{' '}
                    Chain
                  </div>
                </div>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'Basic' ? 'active' : ''
                  }`}
                >
                  <Row xs={1} xxl={2}>
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Basic_1'}
                          character_element={character.element}
                          type="basic"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    {character.slug != 'corin' &&
                      character.slug != 'billy-kid' &&
                      character.slug != 'lycaon' &&
                      character.slug != 'piper' &&
                      character.slug != 'grace-howard' &&
                      character.slug != 'ben' &&
                      character.slug != 'koleda' && (
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Basic_2'}
                              character_element={character.element}
                              type="basic"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                      )}
                    {(character.slug === 'lucy' ||
                      character.slug === 'jane-doe') && (
                      <Col>
                        <div className="box">
                          <ZZZSkillNew
                            char={character.skillKey}
                            id={character.skillKey + '_Basic_3'}
                            character_element={character.element}
                            type="basic"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                    {character.slug === 'qingyi' && (
                      <>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Basic_3'}
                              character_element={character.element}
                              type="basic"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Basic_4'}
                              character_element={character.element}
                              type="basic"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'Dodge' ? 'active' : ''
                  }`}
                >
                  <Row xs={1} xxl={2}>
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Dodge_1'}
                          character_element={character.element}
                          type="dodge"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    {character.slug === 'ellen' && (
                      <>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Dodge_1_1'}
                              character_element={character.element}
                              type="dodge"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Dodge_1_2'}
                              character_element={character.element}
                              type="dodge"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Dodge_2'}
                          character_element={character.element}
                          type="dodge"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    {(character.slug === 'soldier-11' ||
                      character.slug === 'soukaku' ||
                      character.slug === 'zhu-yuan') && (
                      <Col>
                        <div className="box">
                          <ZZZSkillNew
                            char={character.skillKey}
                            id={character.skillKey + '_Dodge_4'}
                            character_element={character.element}
                            type="dodge"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Dodge_3'}
                          character_element={character.element}
                          type="dodge"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    {character.slug === 'anton' && (
                      <Col>
                        <div className="box">
                          <ZZZSkillNew
                            char={character.skillKey}
                            id={character.skillKey + '_Dodge_4'}
                            character_element={character.element}
                            type="dodge"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                    {(character.slug === 'ellen' ||
                      character.slug === 'jane-doe') && (
                      <>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Dodge_4'}
                              character_element={character.element}
                              type="dodge"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Dodge_5'}
                              character_element={character.element}
                              type="dodge"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'Special' ? 'active' : ''
                  }`}
                >
                  <Row xs={1} xxl={2}>
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Skill_1'}
                          character_element={character.element}
                          type="special"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    {(character.slug === 'anton' ||
                      character.slug === 'soukaku' ||
                      character.slug === 'burnice' ||
                      character.slug === 'piper') && (
                      <Col>
                        <div className="box">
                          <ZZZSkillNew
                            char={character.skillKey}
                            id={character.skillKey + '_Skill_3'}
                            character_element={character.element}
                            type="special"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Skill_2'}
                          character_element={character.element}
                          type="special"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    {character.slug === 'piper' && (
                      <Col>
                        <div className="box">
                          <ZZZSkillNew
                            char={character.skillKey}
                            id={character.skillKey + '_Skill_4'}
                            character_element={character.element}
                            type="special"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                    {character.slug === 'ellen' && (
                      <Col>
                        <div className="box">
                          <ZZZSkillNew
                            char={character.skillKey}
                            id={character.skillKey + '_Skill_3'}
                            character_element={character.element}
                            type="special"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                    {character.slug === 'caesar' && (
                      <>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Skill_3'}
                              character_element={character.element}
                              type="special"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Skill_4'}
                              character_element={character.element}
                              type="special"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="box">
                            <ZZZSkillNew
                              char={character.skillKey}
                              id={character.skillKey + '_Skill_5'}
                              character_element={character.element}
                              type="special"
                              minLevel={1}
                              maxLevel={16}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                    {character.slug === 'lucy' && (
                      <Col>
                        <div className="box">
                          <ZZZSkillCustom
                            id="Lucy_1"
                            character_element={character.element}
                            type="special"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'QTE' ? 'active' : ''
                  }`}
                >
                  <Row xs={1} xxl={2}>
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Chain_1'}
                          character_element={character.element}
                          type="qte"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Ultimate_1'}
                          character_element={character.element}
                          type="qte"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'Assist' ? 'active' : ''
                  }`}
                >
                  <Row xs={1} xxl={2}>
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Recover_1'}
                          character_element={character.element}
                          type="assist"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    {character.slug === 'anton' && (
                      <Col>
                        <div className="box">
                          <ZZZSkillNew
                            char={character.skillKey}
                            id={character.skillKey + '_Recover_2'}
                            character_element={character.element}
                            type="assist"
                            minLevel={1}
                            maxLevel={16}
                          />
                        </div>
                      </Col>
                    )}
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Parry_1'}
                          character_element={character.element}
                          type="assist"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <ZZZSkillNew
                          char={character.skillKey}
                          id={character.skillKey + '_Assist_1'}
                          character_element={character.element}
                          type="assist"
                          minLevel={1}
                          maxLevel={16}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Mindscapes
          </div>
          {character.talents ? (
            <div className="talents eidolons">
              <Row xs={1} xxl={2}>
                {character.talents.map((talent, index) => {
                  return (
                    <Col key={index}>
                      <div className="box">
                        <div className="skill-header">
                          <div className={`skill-icon ${character.element}`}>
                            {index === 0 && 'M1'}
                            {index === 1 && 'M2'}
                            {index === 2 && 'M3'}
                            {index === 3 && 'M4'}
                            {index === 4 && 'M5'}
                            {index === 5 && 'M6'}
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">{talent.name}</p>
                            <p className="skill-type">
                              Mindscape {index === 0 && '1'}
                              {index === 1 && '2'}
                              {index === 2 && '3'}
                              {index === 3 && '4'}
                              {index === 4 && '5'}
                              {index === 5 && '6'}
                            </p>
                          </div>
                        </div>
                        {index === 2 || index === 4 ? (
                          <div
                            className={`skill-with-coloring ${character.element}`}
                          >
                            Basic Attack, Assist, Dodge, Special Attack, and
                            Chain Attack Lv. <b>+2</b>
                          </div>
                        ) : (
                          <>
                            <div
                              className={`skill-with-coloring ${character.element}`}
                              dangerouslySetInnerHTML={{
                                __html: talent.desc
                              }}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>Mindscape information aren't available yet.</strong>{' '}
                They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats (Level 60)
          </div>
          {character.upcoming != true || character.slug === 'burnice' ? (
            <>
              <div className="stats-zzz">
                <div className="stats-header with-margin">
                  <div className="info">
                    <p>
                      <strong className={`${character.element}`}>
                        {character.name}
                      </strong>{' '}
                      Stats at level 60 (including Core passive bonuses).
                    </p>
                  </div>
                </div>
                <ZZZStatsCalculator
                  name={character.skillKey}
                  slug={character.slug}
                  element={character.element}
                  limit={60}
                  tableMode
                />
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>stats aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Voice Actors
          </div>
          <Row xs={1} xxl={4} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">ENG</div>
                <div className="details">
                  {character.voiceActors.en ? character.voiceActors.en : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.voiceActors.jpn ? character.voiceActors.jpn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">CN</div>
                <div className="details">
                  {character.voiceActors.cn ? character.voiceActors.cn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">KR</div>
                <div className="details">
                  {character.voiceActors.kr ? character.voiceActors.kr : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              <strong>images aren't available yet.</strong> They will be added
              soon!
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header ${character.element} `}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis ">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {character.review && character.slug != 'anby-demara' ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          <div className="info-box tier">
            <p>
              This tier list assesses characters based on their combat potential
              in Zenless Zone Zero’s current most demanding content,{' '}
              <strong>Shiyu Defense (SD)</strong>. SD is a mode similar to
              Genshin Impact’s Spiral Abyss or Honkai Star Rail’s Memory of
              Chaos and requires multiple teams, Disk Drives and good W-Engines.
              This mode offers a reasonable mix of Boss encounters (single
              target) and Elite packs (2-3 targets), allowing multiple
              characters to find a niche but in the end, there is a bias toward
              single target focused characters at the top-end and tier list
              ratings will reflect that.
            </p>
            <p>
              Please keep in mind Zenless Zone Zero is a skill-based game and
              each character has a different playstyle, difficulty level and
              even special tricks and combos. Zenless Zone Zero is also a team
              based game and it's only by combining both these factors to the
              highest level a character can reach optimal performance.
            </p>
          </div>
          {character.ratings && character.ratings.shiyu != 1 ? (
            <>
              <div className="ratings-container">
                <RatingBox
                  game="hsr"
                  rating={character.ratings.shiyu}
                  ratingName="Shiyu Defense"
                />
              </div>
            </>
          ) : (
            <div className="info-box with-margin">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                ratings aren't available yet. They will be added as soon as it
                is possible.
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Build and teams
            </p>
          </div>
          <div className="build-tips">
            {character.build ? (
              <>
                <div className={`content-header ${character.element}`}>
                  <FontAwesomeIcon icon={faSquare} width="18" /> Best W-Engines
                </div>
                <div className="info-box with-margin">
                  <p>
                    <strong>
                      The W-Engines are listed in order of priority
                    </strong>
                    . Sometimes the percentages appearing near them won't
                    reflect their performance as pure damage or Stun output
                    isn't the only thing we're looking at - the utility of the
                    W-Engine might be more important than the calculations
                    imply.
                  </p>
                </div>
                {(character.slug === 'ellen' ||
                  character.slug === 'soldier-11' ||
                  character.slug === 'zhu-yuan' ||
                  character.slug === 'jane-doe' ||
                  character.slug === 'corin' ||
                  character.slug === 'nekomata') && (
                  <p>
                    The top number shows the performance of the W-Engine in the{' '}
                    <strong className="burst">Burst rotation</strong> while the
                    bottom number in the{' '}
                    <strong className="standard">Standard rotation</strong>. You
                    can find more information about them in the Calculations
                    tab.
                  </p>
                )}
                {(character.style === 'Stun' ||
                  character.slug === 'caesar') && (
                  <p>
                    The top number shows the performance of the W-Engine when
                    applying <strong className="daze">Daze</strong> while the
                    bottom number when dealing{' '}
                    <strong className="damage">Damage</strong>. You can find
                    more information about them in the Calculations tab.
                  </p>
                )}
                {character.slug === 'caesar' && (
                  <p>
                    <strong className="red">Important!</strong> At the time of
                    her release{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    lacks good Stun/Support Defense W-Engine options that
                    synergise with her kit.{' '}
                    <strong>
                      For this reason using an S or A rank Stun W-Engine with a
                      secondary stat of Impact % can be a reasonable alternative
                      to her Signature
                    </strong>{' '}
                    - potentially stronger than any other Defense option visible
                    on the graph above. The Impact% grants an increase to
                    Caesar's Aegis size and Daze capabilities unconditionally
                    which is a reasonable choice when measured against options
                    outside her Signature - especially for F2P.
                  </p>
                )}
                {character.build.engines.map((weapon, idx) => {
                  return (
                    <>
                      {weapon.weapon != '' && (
                        <>
                          <div
                            className={`single-item ${character.element}`}
                            key={idx}
                          >
                            {character.slug === 'ellen' ||
                            character.slug === 'soldier-11' ||
                            character.slug === 'zhu-yuan' ||
                            character.slug === 'jane-doe' ||
                            character.slug === 'corin' ||
                            character.slug === 'nekomata' ? (
                              <>
                                <div className="percentage split">
                                  <p className="burst">{weapon.percent}</p>
                                  <p className="standard">
                                    {weapon.percent_standard}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                {character.style === 'Stun' ||
                                character.slug === 'caesar' ? (
                                  <>
                                    <div className="percentage split">
                                      <p className="daze">
                                        {weapon.percent_standard}
                                      </p>
                                      <p className="damage">{weapon.percent}</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={`percentage ${
                                        weapon.notes ? 'extended' : 'default '
                                      }`}
                                    >
                                      <p>
                                        {weapon.percent ? (
                                          <>{weapon.percent}</>
                                        ) : (
                                          <>{idx + 1}</>
                                        )}
                                      </p>
                                      {weapon.notes && (
                                        <>
                                          <span>{weapon.notes}</span>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            <div>
                              <ZZZEngine
                                name={weapon.weapon}
                                superImp={weapon.super}
                                mode="profile"
                              />
                            </div>
                          </div>
                          <ZZZWeaponNotes
                            name={weapon.weapon}
                            char={character.slug}
                          />
                        </>
                      )}
                    </>
                  );
                })}
                <div className={`content-header ${character.element}`}>
                  <FontAwesomeIcon icon={faSquare} width="18" /> Best Disk
                  Drives Sets
                </div>
                {(character.slug === 'ellen' ||
                  character.slug === 'corin' ||
                  character.slug === 'zhu-yuan' ||
                  character.slug === 'soldier-11' ||
                  character.slug === 'nekomata') && (
                  <p>
                    The top number shows the performance of the Disk Drive in
                    the <strong className="burst">Burst rotation</strong> while
                    the bottom number in the{' '}
                    <strong className="standard">Standard rotation</strong>. You
                    can find more information about them in the Calculations
                    tab.
                  </p>
                )}
                <ZZZProfileSets
                  name={character.slug}
                  character_element={character.element}
                />
                <div className={`content-header ${character.element}`}>
                  <FontAwesomeIcon icon={faSquare} width="18" /> Best Disk
                  Drives Stats
                </div>
                {character.slug === 'ben' && (
                  <p>
                    In the case of{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    you have to decide if you want to build him more as a
                    Support and focus on his Shields or if you want him to focus
                    more on damage. In the first case, focus on DEF% and in the
                    second one, you can also build CRIT Rate and CRIT DMG (and
                    also use Fire DMG on Disk V). And if you're playing him as a
                    Stun character, go for the usual Stunner build
                    (Impact-focused).
                  </p>
                )}
                <div className="main-stats">
                  <div className="box">
                    <div className="stats-inside">
                      <strong className={`${character.element}`}>Disk 4</strong>
                    </div>
                    <div className="list-stats">
                      {character.build.main_4 && (
                        <>
                          {character.build.main_4.map((stat) => {
                            return (
                              <>
                                <ZZZStat stat={stat.stat} />
                                {stat.sign && stat.sign != '' && (
                                  <span className="order">{stat.sign}</span>
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <div className="stats-inside">
                      <strong className={`${character.element}`}>Disk 5</strong>
                    </div>
                    <div className="list-stats">
                      {character.build.main_5 && (
                        <>
                          {character.build.main_5.map((stat) => {
                            return (
                              <>
                                <ZZZStat stat={stat.stat} />
                                {stat.sign && stat.sign != '' && (
                                  <span className="order">{stat.sign}</span>
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <div className="stats-inside">
                      <strong className={`${character.element}`}>Disk 6</strong>
                    </div>
                    <div className="list-stats">
                      {character.build.main_6 && (
                        <>
                          {character.build.main_6.map((stat) => {
                            return (
                              <>
                                <ZZZStat stat={stat.stat} />
                                {stat.sign && stat.sign != '' && (
                                  <span className="order">{stat.sign}</span>
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <Row>
                  <Col>
                    <div className="box sub-stats">
                      <p>
                        <span>Substats:</span>{' '}
                        {character.build.substats
                          ? character.build.substats
                          : '-'}
                      </p>
                    </div>
                  </Col>
                </Row>
                {character.slug === 'caesar' && (
                  <p>
                    Choose a sub stat priority depending on which damage profile
                    you're aiming for on{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>
                    . Neither set of stat priorities are relevant for her
                    supportive capabilities and should only be considered as
                    nice bonuses.
                  </p>
                )}
                <div className={`content-header ${character.element}`}>
                  <FontAwesomeIcon icon={faSquare} width="18" /> Best Endgame
                  Stats (Level 60)
                </div>
                {character.endgameStats ? (
                  <div className="endgame-stats">
                    <div className={`box raw`}>
                      {character.endgameStats && (
                        <>{renderRichText(character.endgameStats, options)}</>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="info-box">
                    <p>
                      <strong className={`${character.element}`}>
                        {character.name}
                      </strong>{' '}
                      <strong>endgame stats aren't available yet.</strong> They
                      will be added soon.
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={`content-header ${character.element}`}>
                  <FontAwesomeIcon icon={faSquare} width="18" /> Best Build
                </div>
                <div className="info-box">
                  <p>
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    <strong>build information aren't available yet.</strong>{' '}
                    They will be added soon.
                  </p>
                </div>
              </>
            )}
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skill priority
          </div>
          {!character.upcoming ? (
            <>
              <ZZZSkillsPrio
                slug={character.slug}
                character_element={character.element}
              />
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>
                    skill priority information aren't available yet.
                  </strong>{' '}
                  They will be added soon.
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Video guides
          </div>
          {character.videos ? (
            <Row xs={1} xl={3} className="video-row">
              {character.videos.map((videoLink, idx) => {
                return (
                  <Col key={idx}>
                    <YoutubeEmbed embedId={videoLink.video} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>guide videos aren't available yet.</strong>
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Teams' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
              Rotation & Teams
            </p>
          </div>
          {character.slug === 'ellen' ||
          character.slug === 'zhu-yuan' ||
          character.slug === 'caesar' ||
          character.slug === 'jane-doe' ||
          character.slug === 'qingyi' ? (
            <>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> How to play
              </div>
              <ZZZProfileHowTo
                name={character.slug}
                character_element={character.element}
              />
            </>
          ) : (
            <></>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Synergy
          </div>
          {character.slug === 'caesar' ? (
            <>
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                is incredibly versatile and can find a place in most team
                compositions thanks to her Defensive and Offensive buffs,
                flexible roles and strong kit. Because of this, Caesar
                synergises excellently with most of the characters in-game and
                it’s very likely any team you have in mind for her will work.
              </p>
            </>
          ) : (
            <>
              {character.upcoming != true && character.slug != 'zhu-yuan' ? (
                <>
                  <ZZZProfileSynergies
                    name={character.slug}
                    character_element={character.element}
                  />
                </>
              ) : (
                <>
                  <div className="info-box">
                    <p>
                      <strong className={`${character.element}`}>
                        {character.name}
                      </strong>{' '}
                      <strong>synergy information aren't available yet.</strong>{' '}
                      They will be added soon.
                    </p>
                  </div>
                </>
              )}
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Teams
          </div>
          {character.upcoming != true ? (
            <>
              <div className="info-box with-margin">
                <p>
                  If a column has multiple characters, the{' '}
                  <strong>
                    bigger one is the priority choice for the team
                  </strong>{' '}
                  while the smaller ones are alternatives. If they are the same
                  size, either of them can be used.
                </p>
              </div>
              <ZZZProfileTeams
                name={character.name}
                slug={character.slug}
                character_element={character.element}
              />
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>team information aren't available yet.</strong> They
                  will be added soon.
                </p>
              </div>
            </>
          )}
        </div>
        <div
          className={`tab-inside ${
            currentTab === 'Performance' ? 'active' : ''
          }`}
        >
          {character.upcoming === true && (
            <>
              <div className={`mobile-header ${character.element}`}>
                <p>
                  <FontAwesomeIcon icon={faCircleInfo} width="18" />
                  Calculations
                </p>
              </div>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Calculations
              </div>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>calculations aren't available yet.</strong>
                </p>
              </div>
            </>
          )}
          {character.slug != 'soukaku' &&
          character.slug != 'rina' &&
          character.slug != 'lucy' &&
          character.slug != 'seth' &&
          character.slug != 'ben' &&
          character.slug != 'nicole-demara' ? (
            <>
              <div className={`mobile-header ${character.element}`}>
                <p>
                  <FontAwesomeIcon icon={faCircleInfo} width="18" />
                  Calculations
                </p>
              </div>
              {(character.slug === 'ellen' ||
                character.slug === 'soldier-11' ||
                character.slug === 'zhu-yuan' ||
                character.slug === 'billy-kid' ||
                character.slug === 'anton' ||
                character.slug === 'nekomata' ||
                character.slug === 'corin') && (
                <>
                  <div className={`content-header ${character.element}`}>
                    <FontAwesomeIcon icon={faSquare} width="18" /> Damage output
                  </div>
                  <ZZZCharacterCalcs
                    slug={character.slug}
                    element={character.element}
                    onlyBurst={
                      character.slug === 'billy-kid' ||
                      character.slug === 'anton'
                        ? true
                        : false
                    }
                  />
                  {(character.slug === 'ellen' ||
                    character.slug === 'soldier-11' ||
                    character.slug === 'zhu-yuan' ||
                    character.slug === 'corin') && (
                    <>
                      <div className={`content-header ${character.element}`}>
                        <FontAwesomeIcon icon={faSquare} width="18" /> Damage
                        output (including team buffs)
                      </div>
                      <ZZZProfileTeamCalcs
                        name={character.slug}
                        character_element={character.element}
                      />
                    </>
                  )}
                </>
              )}
              {(character.style === 'Stun' || character.slug === 'caesar') && (
                <>
                  <div className={`content-header ${character.element}`}>
                    <FontAwesomeIcon icon={faSquare} width="18" /> Damage and
                    Daze output
                  </div>
                  <ZZZCharacterCalcsDaze
                    slug={character.slug}
                    element={character.element}
                  />
                </>
              )}
              {character.slug === 'qingyi' && (
                <>
                  <div className={`content-header ${character.element}`}>
                    <FontAwesomeIcon icon={faSquare} width="18" /> Additional
                    calculations
                  </div>
                  <ZZZProfileTeamCalcs
                    name={character.slug}
                    character_element={character.element}
                  />
                </>
              )}
              {character.style === 'Anomaly' && (
                <>
                  <div className={`content-header ${character.element}`}>
                    <FontAwesomeIcon icon={faSquare} width="18" /> Damage
                  </div>
                  <ZZZCharacterCalcs
                    slug={character.slug}
                    element={character.element}
                    onlyBurst={character.slug === 'jane-doe' ? false : true}
                    anomaly={true}
                  />
                  {character.slug === 'jane-doe' && (
                    <>
                      <div className={`content-header ${character.element}`}>
                        <FontAwesomeIcon icon={faSquare} width="18" /> Damage
                        output (including team buffs)
                      </div>
                      <ZZZProfileTeamCalcs
                        name={character.slug}
                        character_element={character.element}
                      />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className={`mobile-header ${character.element}`}>
                <p>
                  <FontAwesomeIcon icon={faCircleInfo} width="18" />
                  Calculations
                </p>
              </div>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Calculations
              </div>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>calculations aren't available yet.</strong>
                </p>
              </div>
            </>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Shiyu' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faShieldHalved} width="18" />
              Shiyu Defense
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Shiyu Defense
          </div>
          {character.upcoming != true ? (
            <>
              <p>
                This section contains information about{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                performance in the latest <strong>Shiyu Defense</strong> cycle
                (1.2.1). The page will be automatically updated every patch once
                enough data is gathered.
              </p>
              <ul>
                <li>
                  The data has been last updated on <strong>13/08/2024</strong>,
                </li>
                <li>
                  <strong>4522 players</strong> shared their{' '}
                  <strong>1.2.1</strong> Shiyu Defense clear data with us (
                  <strong>868</strong> of the sample are self-reported players
                  that filled our form, <strong>3654</strong> are from random
                  UIDs that we scanned).
                </li>
              </ul>
              <p>
                If you want to share your clear information with us, you simply
                need to fill the form below and{' '}
                <strong>
                  provide us with your UID and make your Hoyo profile public
                </strong>{' '}
                - we will automatically parse the Shiyu Defense clear
                information from your profile after that!
              </p>
              <Button
                variant="primary"
                href="https://bit.ly/shiyustats"
                target="_blank"
                rel="noreferrer"
                className="custom-button"
              >
                Shiyu Defense Stats Form
              </Button>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Usage rate
              </div>
              <p>
                Characters are ranked with appearance rate, which is how often a
                character was used in Shiyu Defense by the players who we have
                scanned, regardless of whether they own the character or not.{' '}
                <strong>Ownership rate currently cannot be obtained.</strong>
              </p>

              <div className={`moc-data-grid`}>
                {ZZZShiyuCharData.sort((a, b) =>
                  b.current_app_rate > a.current_app_rate ? 1 : -1
                ).map((shiyuCharacter, index) => {
                  return (
                    <div
                      key={index}
                      className={`char-box ${
                        character.slug === shiyuCharacter.char ? 'current' : ''
                      }`}
                    >
                      <Card className="avatar-card">
                        <ZZZCharacter
                          slug={shiyuCharacter.char}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{shiyuCharacter.current_app_rate}%</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Common partners
              </div>
              <p>
                This section lists the characters that were most commonly paired
                with{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                in the current phase of Shiyu Defense.
              </p>
              {ShiyuDuos.filter(
                (char) => char.char.toLowerCase() === character.slug
              ).map((duo, idx) => {
                return (
                  <div key={idx} className="moc-duo on-moc">
                    <div className={`single-char`}>
                      <Card className="avatar-card">
                        <ZZZCharacter
                          slug={duo.char_1.toLowerCase()}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{duo.app_rate_1}%</p>
                      </div>
                    </div>
                    {duo.char_2 != '-' && (
                      <div className={`single-char`}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={duo.char_2.toLowerCase()}
                            mode="icon"
                            enablePopover
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{duo.app_rate_2}%</p>
                        </div>
                      </div>
                    )}
                    {duo.char_3 != '-' && (
                      <div className={`single-char`}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={duo.char_3.toLowerCase()}
                            mode="icon"
                            enablePopover
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{duo.app_rate_3}%</p>
                        </div>
                      </div>
                    )}
                    {duo.char_4 != '-' && (
                      <div className={`single-char`}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={duo.char_4.toLowerCase()}
                            mode="icon"
                            enablePopover
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{duo.app_rate_4}%</p>
                        </div>
                      </div>
                    )}
                    {duo.char_5 != '-' && (
                      <div className={`single-char`}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={duo.char_5.toLowerCase()}
                            mode="icon"
                            enablePopover
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{duo.app_rate_5}%</p>
                        </div>
                      </div>
                    )}
                    {duo.char_6 != '-' && (
                      <div className={`single-char`}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={duo.char_6.toLowerCase()}
                            mode="icon"
                            enablePopover
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{duo.app_rate_6}%</p>
                        </div>
                      </div>
                    )}
                    {duo.char_7 != '-' && (
                      <div className={`single-char`}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={duo.char_7.toLowerCase()}
                            mode="icon"
                            enablePopover
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{duo.app_rate_7}%</p>
                        </div>
                      </div>
                    )}
                    {duo.char_8 != '-' && (
                      <div className={`single-char`}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={duo.char_8.toLowerCase()}
                            mode="icon"
                            enablePopover
                          />
                        </Card>
                        <div className="column percentage">
                          <p>{duo.app_rate_8}%</p>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Teams
              </div>
              <p>
                This section lists most popular teams featuring{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                in the current phase of Shiyu Defense.
              </p>
              {items.length === 0 ? (
                <>
                  <div className="info-box">
                    <p>
                      There are no teams featuring{' '}
                      <strong className={`${character.element}`}>
                        {character.name}
                      </strong>{' '}
                      who have cleared Memory of Chaos in the current cycle.
                    </p>
                  </div>
                </>
              ) : (
                <div className="team-container-moc">{items}</div>
              )}
              {ZZZBuilds.filter(
                (char) => char.char.toLowerCase() === character.slug
              ).map((shiyuCharacter, index) => {
                return (
                  <div key={index} className="build-tips">
                    <div className={`content-header ${character.element}`}>
                      <FontAwesomeIcon icon={faSquare} width="18" /> Mindscapes
                    </div>
                    <p>
                      Mindscapes data comes from{' '}
                      <strong>{shiyuCharacter.sample}</strong> players we have
                      scanned who used{' '}
                      <strong className={`${character.element}`}>
                        {character.name}
                      </strong>{' '}
                      in the current Shiyu Defense.
                    </p>
                    <div
                      className={`single-item mindscape mind-0 ${character.element}`}
                    >
                      <div className="percentage extended">
                        <p>{shiyuCharacter.app_0}%</p>
                        <span>
                          {shiyuCharacter.round_0 != 1
                            ? shiyuCharacter.round_0
                            : 'No'}{' '}
                          score
                        </span>
                      </div>
                      <div className="empty">
                        <p>Mindscape 0</p>
                      </div>
                    </div>
                    {character.talents.map((talent, index) => {
                      return (
                        <>
                          <div
                            className={`single-item mindscape ${character.element}`}
                          >
                            <div className="percentage extended">
                              {index === 0 && (
                                <>
                                  <p>{shiyuCharacter.app_1}%</p>
                                  <span>
                                    {shiyuCharacter.round_1 != 1
                                      ? shiyuCharacter.round_1
                                      : 'No'}{' '}
                                    score
                                  </span>
                                </>
                              )}
                              {index === 1 && (
                                <>
                                  <p>{shiyuCharacter.app_2}%</p>
                                  <span>
                                    {shiyuCharacter.round_2 != 1
                                      ? shiyuCharacter.round_2
                                      : 'No'}{' '}
                                    score
                                  </span>
                                </>
                              )}
                              {index === 2 && (
                                <>
                                  <p>{shiyuCharacter.app_3}%</p>
                                  <span>
                                    {shiyuCharacter.round_3 != 1
                                      ? shiyuCharacter.round_3
                                      : 'No'}{' '}
                                    score
                                  </span>
                                </>
                              )}
                              {index === 3 && (
                                <>
                                  <p>{shiyuCharacter.app_4}%</p>
                                  <span>
                                    {shiyuCharacter.round_4 != 1
                                      ? shiyuCharacter.round_4
                                      : 'No'}{' '}
                                    score
                                  </span>
                                </>
                              )}
                              {index === 4 && (
                                <>
                                  <p>{shiyuCharacter.app_5}%</p>
                                  <span>
                                    {shiyuCharacter.round_5 != 1
                                      ? shiyuCharacter.round_5
                                      : 'No'}{' '}
                                    score
                                  </span>
                                </>
                              )}
                              {index === 5 && (
                                <>
                                  <p>{shiyuCharacter.app_6}%</p>
                                  <span>
                                    {shiyuCharacter.round_6 != 1
                                      ? shiyuCharacter.round_6
                                      : 'No'}{' '}
                                    score
                                  </span>
                                </>
                              )}
                            </div>
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <strong>Mindscape {index + 1}</strong> (
                                  {talent.name})
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    className={`skill-with-coloring eidolon ${character.element}`}
                                  >
                                    {index === 2 || index === 4 ? (
                                      <div
                                        className={`skill-with-coloring ${character.element}`}
                                      >
                                        Basic Attack, Assist, Dodge, Special
                                        Attack, and Chain Attack Lv. <b>+2</b>
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className={`skill-with-coloring ${character.element}`}
                                          dangerouslySetInnerHTML={{
                                            __html: talent.desc
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </>
                      );
                    })}
                    <div className={`content-header ${character.element}`}>
                      <FontAwesomeIcon icon={faSquare} width="18" /> W-Engines
                    </div>
                    <p>
                      W-Engines usage data comes from{' '}
                      <strong>{shiyuCharacter.sample}</strong> players we have
                      scanned who used{' '}
                      <strong className={`${character.element}`}>
                        {character.name}
                      </strong>{' '}
                      in the current Shiyu Defense.
                    </p>
                    <div className={`single-item ${character.element}`}>
                      <div className={`percentage extended`}>
                        <p>{shiyuCharacter.weapon_1_app}%</p>
                        <span>{shiyuCharacter.weapon_1_round} score</span>
                      </div>
                      <div>
                        <ZZZEngine
                          name={shiyuCharacter.weapon_1}
                          mode="profile"
                        />
                      </div>
                    </div>
                    {shiyuCharacter.weapon_2 && (
                      <div className={`single-item ${character.element}`}>
                        <div className={`percentage extended`}>
                          <p>{shiyuCharacter.weapon_2_app}%</p>
                          <span>{shiyuCharacter.weapon_2_round} score</span>
                        </div>
                        <div>
                          <ZZZEngine
                            name={shiyuCharacter.weapon_2}
                            mode="profile"
                          />
                        </div>
                      </div>
                    )}
                    {shiyuCharacter.weapon_3 && (
                      <div className={`single-item ${character.element}`}>
                        <div className={`percentage extended`}>
                          <p>{shiyuCharacter.weapon_3_app}%</p>
                          <span>{shiyuCharacter.weapon_3_round} score</span>
                        </div>
                        <div>
                          <ZZZEngine
                            name={shiyuCharacter.weapon_3}
                            mode="profile"
                          />
                        </div>
                      </div>
                    )}
                    {shiyuCharacter.weapon_4 && (
                      <div className={`single-item ${character.element}`}>
                        <div className={`percentage extended`}>
                          <p>{shiyuCharacter.weapon_4_app}%</p>
                          <span>{shiyuCharacter.weapon_4_round} score</span>
                        </div>
                        <div>
                          <ZZZEngine
                            name={shiyuCharacter.weapon_4}
                            mode="profile"
                          />
                        </div>
                      </div>
                    )}
                    {shiyuCharacter.weapon_5 && (
                      <div className={`single-item ${character.element}`}>
                        <div className={`percentage extended`}>
                          <p>{shiyuCharacter.weapon_5_app}%</p>
                          <span>{shiyuCharacter.weapon_5_round} score</span>
                        </div>
                        <div>
                          <ZZZEngine
                            name={shiyuCharacter.weapon_5}
                            mode="profile"
                          />
                        </div>
                      </div>
                    )}
                    {shiyuCharacter.weapon_6 && (
                      <div className={`single-item ${character.element}`}>
                        <div className={`percentage extended`}>
                          <p>{shiyuCharacter.weapon_6_app}%</p>
                          <span>{shiyuCharacter.weapon_6_round} score</span>
                        </div>
                        <div>
                          <ZZZEngine
                            name={shiyuCharacter.weapon_6}
                            mode="profile"
                          />
                        </div>
                      </div>
                    )}
                    <div className={`content-header ${character.element}`}>
                      <FontAwesomeIcon icon={faSquare} width="18" /> Disk Drives
                    </div>
                    <p>
                      Disk Drives usage data comes from{' '}
                      <strong>{shiyuCharacter.sample}</strong> players we have
                      scanned who used{' '}
                      <strong className={`${character.element}`}>
                        {character.name}
                      </strong>{' '}
                      in the current Shiyu Defense.
                    </p>
                    <>
                      <div className={`single-item ${character.element}`}>
                        <div className={`percentage extended`}>
                          <p>{shiyuCharacter.artifact_1_app}%</p>
                          <span>{shiyuCharacter.artifact_1_round} score</span>
                        </div>
                        <div className="split-shiyu">
                          <ZZZDiskSet
                            name={shiyuCharacter.artifact_1_1}
                            onProfile
                            setup="4"
                          />
                          {shiyuCharacter.artifact_1_2 === 'Flex' ? (
                            <>
                              <div className="flex-placeholder">Flex</div>
                            </>
                          ) : (
                            <>
                              <ZZZDiskSet
                                name={shiyuCharacter.artifact_1_2}
                                onProfile
                                setup="2"
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {shiyuCharacter.artifact_2_1 && (
                        <>
                          <div className={`single-item ${character.element}`}>
                            <div className={`percentage extended`}>
                              <p>{shiyuCharacter.artifact_2_app}%</p>
                              <span>
                                {shiyuCharacter.artifact_2_round} score
                              </span>
                            </div>
                            <div className="split-shiyu">
                              <ZZZDiskSet
                                name={shiyuCharacter.artifact_2_1}
                                onProfile
                                setup="4"
                              />
                              {shiyuCharacter.artifact_2_2 === 'Flex' ? (
                                <>
                                  <div className="flex-placeholder">Flex</div>
                                </>
                              ) : (
                                <>
                                  <ZZZDiskSet
                                    name={shiyuCharacter.artifact_2_2}
                                    onProfile
                                    setup="2"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {shiyuCharacter.artifact_3_1 && (
                        <>
                          <div className={`single-item ${character.element}`}>
                            <div className={`percentage extended`}>
                              <p>{shiyuCharacter.artifact_3_app}%</p>
                              <span>
                                {shiyuCharacter.artifact_3_round} score
                              </span>
                            </div>
                            <div className="split-shiyu">
                              <ZZZDiskSet
                                name={shiyuCharacter.artifact_3_1}
                                onProfile
                                setup="4"
                              />
                              {shiyuCharacter.artifact_3_2 === 'Flex' ? (
                                <>
                                  <div className="flex-placeholder">Flex</div>
                                </>
                              ) : (
                                <>
                                  <ZZZDiskSet
                                    name={shiyuCharacter.artifact_3_2}
                                    onProfile
                                    setup="2"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {shiyuCharacter.artifact_4_1 && (
                        <>
                          <div className={`single-item ${character.element}`}>
                            <div className={`percentage extended`}>
                              <p>{shiyuCharacter.artifact_4_app}%</p>
                              <span>
                                {shiyuCharacter.artifact_4_round} score
                              </span>
                            </div>
                            <div className="split-shiyu">
                              <ZZZDiskSet
                                name={shiyuCharacter.artifact_4_1}
                                onProfile
                                setup="4"
                              />
                              {shiyuCharacter.artifact_4_2 === 'Flex' ? (
                                <>
                                  <div className="flex-placeholder">Flex</div>
                                </>
                              ) : (
                                <>
                                  <ZZZDiskSet
                                    name={shiyuCharacter.artifact_4_2}
                                    onProfile
                                    setup="2"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {shiyuCharacter.artifact_5_1 && (
                        <>
                          <div className={`single-item ${character.element}`}>
                            <div className={`percentage extended`}>
                              <p>{shiyuCharacter.artifact_5_app}%</p>
                              <span>
                                {shiyuCharacter.artifact_5_round} score
                              </span>
                            </div>
                            <div className="split-shiyu">
                              <ZZZDiskSet
                                name={shiyuCharacter.artifact_5_1}
                                onProfile
                                setup="4"
                              />
                              {shiyuCharacter.artifact_5_2 === 'Flex' ? (
                                <>
                                  <div className="flex-placeholder">Flex</div>
                                </>
                              ) : (
                                <>
                                  <ZZZDiskSet
                                    name={shiyuCharacter.artifact_5_2}
                                    onProfile
                                    setup="2"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {shiyuCharacter.artifact_6_1 && (
                        <>
                          <div className={`single-item ${character.element}`}>
                            <div className={`percentage extended`}>
                              <p>{shiyuCharacter.artifact_6_app}%</p>
                              <span>
                                {shiyuCharacter.artifact_6_round} score
                              </span>
                            </div>
                            <div className="split-shiyu">
                              <ZZZDiskSet
                                name={shiyuCharacter.artifact_6_1}
                                onProfile
                                setup="4"
                              />
                              {shiyuCharacter.artifact_6_2 === 'Flex' ? (
                                <>
                                  <div className="flex-placeholder">Flex</div>
                                </>
                              ) : (
                                <>
                                  <ZZZDiskSet
                                    name={shiyuCharacter.artifact_6_2}
                                    onProfile
                                    setup="2"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                    <div className={`content-header ${character.element}`}>
                      <FontAwesomeIcon icon={faSquare} width="18" /> Stats
                    </div>
                    <div className="info-box">
                      <p>
                        <strong className={`${character.element}`}>
                          {character.name}
                        </strong>{' '}
                        <strong> stats aren't available yet.</strong> They will
                        be added soon.
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>Shiyu Defense stats aren't available yet.</strong> They
                will be added soon.
              </p>
            </div>
          )}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default ZZZCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={
        character.name + ' (ZZZ) Guide and Build | Zenless Zone Zero | Prydwen'
      }
      description={
        'Discover the best builds, teams and detailed guides for ' +
        character.name +
        ' in Zenless Zone Zero (ZZZ).'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulZzzCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...ZzzCharacterFieldsFragment
      }
    }
  }
`;
