/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  faAnglesRight,
  faAnglesDown,
  faEquals
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';

interface IProps {
  slug: string;
  character_element: string;
}

export const ZZZSkillsPrio: React.FC<IProps> = ({
  slug,
  character_element
}) => {
  return (
    <>
      {slug === 'caesar' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
        </div>
      )}
      {slug === 'seth' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'jane-doe' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
        </div>
      )}
      {slug === 'qingyi' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
        </div>
      )}
      {slug === 'soukaku' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'nicole-demara' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'rina' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'ben' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'zhu-yuan' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
        </div>
      )}
      {slug === 'ellen' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
        </div>
      )}
      {slug === 'corin' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {slug === 'soldier-11' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
        </div>
      )}
      {(slug === 'anby-demara' || slug === 'lycaon') && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
        </div>
      )}
      {slug === 'nekomata' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
        </div>
      )}
      {slug === 'koleda' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
        </div>
      )}
      {slug === 'piper' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'grace-howard' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
        </div>
      )}
      {slug === 'lucy' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'billy-kid' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
      {slug === 'anton' && (
        <div className={`skill-priority ${character_element}`}>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_ulti_full.png"
              alt="Core"
            />
            <p>Chain Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_special_full.png"
              alt="Core"
            />
            <p>Special Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_assist_m.png"
              alt="Core"
            />
            <p>Assist</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
            <p>Dodge</p>
          </div>
        </div>
      )}
    </>
  );
};
