/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ZZZDiskSet } from '../components/zzz-disk-set';

interface IProps {
  name: string;
  character_element: string;
}

export const ZZZProfileSets: React.FC<IProps> = ({
  name,
  character_element
}) => {
  return (
    <>
      {name === 'zhu-yuan' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100%</p>
              <p className="standard">100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Chaotic Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for CRIT-based Ether DPS characters, granting
              10% permanent Ether DMG% from the 2P and 20% CRIT DMG from the 4P.
              The 4P Bonus also gives a stacking buff, granting 5.5% CRIT DMG
              any time Corruption deals damage stacking up to 6 times and
              lasting 8 seconds - with the duration refreshing every new stack.
              The total bonus of the 4P comes out at 53% CRIT DMG under ideal
              circumstances. Given Corruption lasts for 10 seconds and triggers
              twice a second and the buff lasts 8 seconds, you can expect this
              set bonus to be fully active in most Ether focused teams. But if
              you feel you'll have downtime during critical damage windows (can
              occur in teams without at least 2 Ether characters) this set will
              perform lower than site listed calculations.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">98.96%</p>
              <p className="standard">103.16%</p>
            </div>
            <div>
              <ZZZDiskSet name="Woodpecker Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Great option for all direct damage dealers that make use of some
              form of Basic ATK and EX frequently. The set grants 8% permanent
              CRIT RATE% from the 2-PC and a 9% increased combat ATK% buff for 6
              seconds when triggering a CRIT with each a Basic Attack, Dodge
              Counter or EX Special Attack from the 4P. Each buff belongs to an
              attack type so you'll need to CRIT with each type every 6 seconds
              to maintain 3 stacks. You can expect 2 stacks of this set most of
              the time one from basics and one from either EX's during a burst
              window or Dodge Counters during downtime granting 18% combat ATK%
              that multiplies all ATK stats like FLAT ATK and ATK% ((Base ATK *
              (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              Despite all this, the set has ramp-up time and can quickly fall
              off without access to energy for EX's making it most suitable for
              characters with cheap EX's, frequent Dodge Counters or
              concentrated burst damage windows.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Chaotic Metal</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100.40%</p>
              <p className="standard">85.79%</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for burst damage characters aiming to deal most of
              their damage in a short window following their Ultimate. The set
              grants 8% permanent PEN Ratio% from the 2P, 15% increased combat
              ATK% for 12 seconds after using an ultimate and 20% increased
              Ultimate DMG from the 4P. When evaluating this set we assume
              you'll gain and save your damaging ultimate for the enemy stun
              window and use it before executing the rest of your combo. If
              you're unable to gain your Ultimate every stun window this sets
              value will drop compared to its listing.
            </p>
            <p>
              <strong className="red">Important!</strong> If this set's damage
              numbers are larger than sets ranked higher than it, it's because
              our team doesn't consider the damage increase to be worth the
              restriction of requiring your ultimate to gain the bonus. Use this
              set if you consider the increase to burst damage to be worth it
              for you.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Chaotic Metal</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'ellen' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100%</p>
              <p className="standard">100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Woodpecker Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Great option for all direct damage dealers that make use of some
              form of Basic ATK and EX frequently. The set grants 8% permanent
              CRIT RATE% from the 2-PC and a 9% increased combat ATK% buff for 6
              seconds when triggering a CRIT with each a Basic Attack, Dodge
              Counter or EX Special Attack from the 4P. Each buff belongs to an
              attack type so you'll need to CRIT with each type every 6 seconds
              to maintain 3 stacks. You can expect 2 stacks of this set most of
              the time one from basics and one from either EX's during a burst
              window or Dodge Counters during downtime granting 18% combat ATK%
              that multiplies all ATK stats like FLAT ATK and ATK% ((Base ATK *
              (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              Despite all this, the set has ramp-up time and can quickly fall
              off without access to energy for EX's making it most suitable for
              characters with cheap EX's, frequent Dodge Counters or
              concentrated burst damage windows.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Polar Metal</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">90.11%</p>
              <p className="standard">101.38%</p>
            </div>
            <div>
              <ZZZDiskSet name="Polar Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Reasonable option for direct Ice damage dealers specializing in
              Dash Attacks and Basic attack specifically. The set grants 10%
              permanent Ice DMG% from the 2-PC and 20% increased DMG with Basic
              and Dash Attacks from the 4P. Upon Freezing or Shattering an
              enemy, the bonus from the 4P increases from 20% to 40% for 12
              seconds. Due to the very specific nature of the buffs of this set
              it can fall behind other more powerful damaging options.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">102.63%</p>
              <p className="standard">83.87%</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for burst damage characters aiming to deal most of
              their damage in a short window following their Ultimate. The set
              grants 8% permanent PEN Ratio% from the 2-PC, 15% increased combat
              ATK% for 12 seconds after using an Ultimate and 20% increased
              Ultimate DMG from the 4P. When evaluating this set we assume
              you'll gain and save your damaging Ultimate for the enemy Stun
              window and use it before executing the rest of your combo. If
              you're unable to gain your Ultimate every stun window, this sets
              value will drop compared to its listing.
            </p>
            <p>
              <strong className="red">Important!</strong> If this set's damage
              numbers are larger than sets ranked higher than it, it's because
              our team doesn't consider the damage increase to be worth the
              restriction of requiring your Ultimate to gain the bonus. Use this
              set if you consider the increase to burst damage to be worth it
              for you.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Polar Metal</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'anton' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Thunder Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct and Anomaly-based Electric damage
              dealers granting 10% permanent Electric DMG% from the 2-PC and 28%
              increased combat ATK% as long as any enemy is Shocked. This set is
              incredibly powerful as Shock lasts for 10 seconds baseline, giving
              this bonus a long duration and due to the strength of combat ATK%
              multiplying all a characters standard ATK stats like FLAT ATK and
              ATK% ((Base ATK * (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>88.93%</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for burst damage characters aiming to deal most of
              their damage in a short window following their Ultimate. The set
              grants 8% permanent PEN Ratio% from the 2-PC, 15% increased combat
              ATK% for 12 seconds after using an Ultimate and 20% increased
              Ultimate DMG from the 4P. When evaluating this set we assume
              you'll gain and save your damaging Ultimate for the enemy Stun
              window and use it before executing the rest of your combo. If
              you're unable to gain your Ultimate every stun window, this sets
              value will drop compared to its listing.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Thunder Metal</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>85.59%</p>
            </div>
            <div>
              <ZZZDiskSet name="Woodpecker Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Great option for all direct damage dealers that make use of some
              form of Basic ATK and EX frequently. The set grants 8% permanent
              CRIT RATE% from the 2-PC and a 9% increased combat ATK% buff for 6
              seconds when triggering a CRIT with each a Basic Attack, Dodge
              Counter or EX Special Attack from the 4P. Each buff belongs to an
              attack type so you'll need to CRIT with each type every 6 seconds
              to maintain 3 stacks. You can expect 2 stacks of this set most of
              the time one from basics and one from either EX's during a burst
              window or Dodge Counters during downtime granting 18% combat ATK%
              that multiplies all ATK stats like FLAT ATK and ATK% ((Base ATK *
              (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              Despite all this, the set has ramp-up time and can quickly fall
              off without access to energy for EX's making it most suitable for
              characters with cheap EX's, frequent Dodge Counters or
              concentrated burst damage windows.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Thunder Metal</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'soldier-11' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100%</p>
              <p className="standard">100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Inferno Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct Fire damage dealers granting 10%
              permanent Fire DMG% from the 2-PC and 28% increased CRIT RATE for
              8 8 seconds upon hitting a Burning enemy from the 4-PC. The 4-PC
              bonus of this set is equivalent to almost 12 sub-stats of CRIT
              RATE making it absolutely absurd - it's also not hard to keep
              active either as Burn lasts 10 seconds and even if you hit it at
              the last second of Burn you'll still gain an 8 second buff.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">92.40%</p>
              <p className="standard">82.23%</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for burst damage characters aiming to deal most of
              their damage in a short window following their Ultimate. The set
              grants 8% permanent PEN Ratio% from the 2-PC, 15% increased combat
              ATK% for 12 seconds after using an Ultimate and 20% increased
              Ultimate DMG from the 4P. When evaluating this set we assume
              you'll gain and save your damaging Ultimate for the enemy Stun
              window and use it before executing the rest of your combo. If
              you're unable to gain your Ultimate every stun window, this sets
              value will drop compared to its listing.
            </p>
            <p>
              <strong className="red">Important!</strong> This set has
              anti-synergy with Soldier 11's signature W-Engine these
              calculations are performed with. For this reason when not using
              "The Brimstone" this set will performs slightly better than
              represented here.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Inferno Metal</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">92.65%</p>
              <p className="standard">95.14%</p>
            </div>
            <div>
              <ZZZDiskSet name="Woodpecker Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Great option for all direct damage dealers that make use of some
              form of Basic ATK and EX frequently. The set grants 8% permanent
              CRIT RATE% from the 2-PC and a 9% increased combat ATK% buff for 6
              seconds when triggering a CRIT with each a Basic Attack, Dodge
              Counter or EX Special Attack from the 4P. Each buff belongs to an
              attack type so you'll need to CRIT with each type every 6 seconds
              to maintain 3 stacks. You can expect 2 stacks of this set most of
              the time one from basics and one from either EX's during a burst
              window or Dodge Counters during downtime granting 18% combat ATK%
              that multiplies all ATK stats like FLAT ATK and ATK% ((Base ATK *
              (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              Despite all this, the set has ramp-up time and can quickly fall
              off without access to energy for EX's making it most suitable for
              characters with cheap EX's, frequent Dodge Counters or
              concentrated burst damage windows.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Inferno Metal</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'nekomata' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100%</p>
              <p className="standard">100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Fanged Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct and Anomaly-based Physical damage
              dealers granting 10% permanent Physical DMG% from the 2-PC and 35%
              increased DMG against enemies inflicted by Assault by any party
              member for 12 seconds. DMG% bonus against enemies will be up the
              majority of most fights against boss-type enemies thanks to its
              long duration and ability to trigger from any team member allowing
              a Physical based Bangboo/teammate to assist with uptime via their
              own Assaults.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">95.47%</p>
              <p className="standard">100.61%</p>
            </div>
            <div>
              <ZZZDiskSet name="Woodpecker Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Great option for all direct damage dealers that make use of some
              form of Basic ATK and EX frequently. The set grants 8% permanent
              CRIT RATE% from the 2-PC and a 9% increased combat ATK% buff for 6
              seconds when triggering a CRIT with each a Basic Attack, Dodge
              Counter or EX Special Attack from the 4P. Each buff belongs to an
              attack type so you'll need to CRIT with each type every 6 seconds
              to maintain 3 stacks. You can expect 2 stacks of this set most of
              the time one from basics and one from either EX's during a burst
              window or Dodge Counters during downtime granting 18% combat ATK%
              that multiplies all ATK stats like FLAT ATK and ATK% ((Base ATK *
              (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              Despite all this, the set has ramp-up time and can quickly fall
              off without access to energy for EX's making it most suitable for
              characters with cheap EX's, frequent Dodge Counters or
              concentrated burst damage windows.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Fanged Metal</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100.08%</p>
              <p className="standard">84.43%</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for burst damage characters aiming to deal most of
              their damage in a short window following their Ultimate. The set
              grants 8% permanent PEN Ratio% from the 2-PC, 15% increased combat
              ATK% for 12 seconds after using an Ultimate and 20% increased
              Ultimate DMG from the 4P. When evaluating this set we assume
              you'll gain and save your damaging Ultimate for the enemy Stun
              window and use it before executing the rest of your combo. If
              you're unable to gain your Ultimate every stun window, this sets
              value will drop compared to its listing.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Fanged Metal</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'corin' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100%</p>
              <p className="standard">100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Fanged Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct and Anomaly-based Physical damage
              dealers granting 10% permanent Physical DMG% from the 2-PC and 35%
              increased DMG against enemies inflicted by Assault by any party
              member for 12 seconds. DMG% bonus against enemies will be up the
              majority of most fights against boss-type enemies thanks to its
              long duration and ability to trigger from any team member allowing
              a Physical based Bangboo/teammate to assist with uptime via their
              own Assaults.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">100.77%</p>
              <p className="standard">80.64%</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for burst damage characters aiming to deal most of
              their damage in a short window following their Ultimate. The set
              grants 8% permanent PEN Ratio% from the 2-PC, 15% increased combat
              ATK% for 12 seconds after using an Ultimate and 20% increased
              Ultimate DMG from the 4P. When evaluating this set we assume
              you'll gain and save your damaging Ultimate for the enemy Stun
              window and use it before executing the rest of your combo. If
              you're unable to gain your Ultimate every stun window, this sets
              value will drop compared to its listing.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Fanged Metal</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className="percentage split">
              <p className="burst">93.62%</p>
              <p className="standard">97.50%</p>
            </div>
            <div>
              <ZZZDiskSet name="Woodpecker Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Great option for all direct damage dealers that make use of some
              form of Basic ATK and EX frequently. The set grants 8% permanent
              CRIT RATE% from the 2-PC and a 9% increased combat ATK% buff for 6
              seconds when triggering a CRIT with each a Basic Attack, Dodge
              Counter or EX Special Attack from the 4P. Each buff belongs to an
              attack type so you'll need to CRIT with each type every 6 seconds
              to maintain 3 stacks. You can expect 2 stacks of this set most of
              the time one from basics and one from either EX's during a burst
              window or Dodge Counters during downtime granting 18% combat ATK%
              that multiplies all ATK stats like FLAT ATK and ATK% ((Base ATK *
              (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              Despite all this, the set has ramp-up time and can quickly fall
              off without access to energy for EX's making it most suitable for
              characters with cheap EX's, frequent Dodge Counters or
              concentrated burst damage windows.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Fanged Metal</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'billy-kid' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Woodpecker Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Great option for all direct damage dealers that make use of some
              form of Basic ATK and EX frequently. The set grants 8% permanent
              CRIT RATE% from the 2-PC and a 9% increased combat ATK% buff for 6
              seconds when triggering a CRIT with each a Basic Attack, Dodge
              Counter or EX Special Attack from the 4P. Each buff belongs to an
              attack type so you'll need to CRIT with each type every 6 seconds
              to maintain 3 stacks. You can expect 2 stacks of this set most of
              the time one from basics and one from either EX's during a burst
              window or Dodge Counters during downtime granting 18% combat ATK%
              that multiplies all ATK stats like FLAT ATK and ATK% ((Base ATK *
              (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              Despite all this, the set has ramp-up time and can quickly fall
              off without access to energy for EX's making it most suitable for
              characters with cheap EX's, frequent Dodge Counters or
              concentrated burst damage windows.
            </p>
            <p>
              <strong>
                This options becomes much stronger once you reach M2 on Billy!
              </strong>
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Fanged Metal</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>101.08%</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for burst damage characters aiming to deal most of
              their damage in a short window following their Ultimate. The set
              grants 8% permanent PEN Ratio% from the 2-PC, 15% increased combat
              ATK% for 12 seconds after using an Ultimate and 20% increased
              Ultimate DMG from the 4P. When evaluating this set we assume
              you'll gain and save your damaging Ultimate for the enemy Stun
              window and use it before executing the rest of your combo. If
              you're unable to gain your Ultimate every stun window, this sets
              value will drop compared to its listing.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Polar Metal</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>99.85%</p>
            </div>
            <div>
              <ZZZDiskSet name="Fanged Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct and Anomaly-based Physical damage
              dealers granting 10% permanent Physical DMG% from the 2-PC and 35%
              increased DMG against enemies inflicted by Assault by any party
              member for 12 seconds. DMG% bonus against enemies will be up the
              majority of most fights against boss-type enemies thanks to its
              long duration and ability to trigger from any team member allowing
              a Physical based Bangboo/teammate to assist with uptime via their
              own Assaults.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Woodpecker Electro</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {/* Stun */}
      {name === 'lycaon' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Shockstar Disco" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best option for all Stun-focused characters granting 6% permanent
              Impact% from the 2-PPC and 20% increased Daze for Basic Attacks,
              Dash Attacks and Dodge Counters. As Stun characters top priority
              is dealing as much Daze as possible as fast as possible, no other
              set comes close to this one and the 4-PC should be pursued even at
              the cost of good sub-stats.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> for 20% Energy Regen (Recommended),
              </li>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost anomaly damage,
              </li>
              <li>
                <strong>Polar Metal</strong>, <strong>Puffer Electro</strong> or{' '}
                <strong>Hormone Punk</strong> to boost overall damage.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'koleda' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Shockstar Disco" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best option for all Stun-focused characters granting 6% permanent
              Impact% from the 2-PPC and 20% increased Daze for Basic Attacks,
              Dash Attacks and Dodge Counters. As Stun characters top priority
              is dealing as much Daze as possible as fast as possible, no other
              set comes close to this one and the 4-PC should be pursued even at
              the cost of good sub-stats.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> for 20% Energy Regen (Recommended),
              </li>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost anomaly damage,
              </li>
              <li>
                <strong>Thunder Metal</strong>, <strong>Puffer Electro</strong>{' '}
                or <strong>Hormone Punk</strong> to boost overall damage.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'anby-demara' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Shockstar Disco" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best option for all Stun-focused characters granting 6% permanent
              Impact% from the 2-PPC and 20% increased Daze for Basic Attacks,
              Dash Attacks and Dodge Counters. As Stun characters top priority
              is dealing as much Daze as possible as fast as possible, no other
              set comes close to this one and the 4-PC should be pursued even at
              the cost of good sub-stats.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> for 20% Energy Regen (Recommended),
              </li>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost anomaly damage,
              </li>
              <li>
                <strong>Thunder Metal</strong>, <strong>Puffer Electro</strong>{' '}
                or <strong>Hormone Punk</strong> to boost overall damage.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'qingyi' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Shockstar Disco" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best option for all Stun-focused characters granting 6% permanent
              Impact% from the 2-PPC and 20% increased Daze for Basic Attacks,
              Dash Attacks and Dodge Counters. As Stun characters top priority
              is dealing as much Daze as possible as fast as possible, no other
              set comes close to this one and the 4-PC should be pursued even at
              the cost of good sub-stats.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> for 20% Energy Regen (Recommended),
              </li>
              <li>
                <strong>Thunder Metal</strong>, <strong>Puffer Electro</strong>{' '}
                or <strong>Woodpecker Electro</strong> to boost overall damage.
              </li>
            </ul>
          </div>
        </>
      )}
      {/* Support */}
      {name === 'caesar' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Proto Punk" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              An alternative buffing option to Swing Jazz and ideal for Caesar
              if another character is already covering that set bonus. Grants
              the team 15% increased DMG for 10 seconds whenever any squad
              member triggers a Defensive or Evasive assist. Caesar's primary
              combo revolves completely around Defensive Assists so keeping this
              buff active during standard combat isn't hard. Though, aligning
              its duration or a Defensive/Evasive assist right before a Stun
              window can be challenging, making this set a little more difficult
              to use for teams focused on dealing most of their damage during
              stun windows.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> for energy regen,
              </li>
              <li>
                <strong>Shockstar Disco</strong> for max Daze.
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>2</p>
            </div>
            <div>
              <ZZZDiskSet name="Freedom Blues" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Strong set option for Caesar when supporting teams with mainly
              Physical Anomaly characters. Causes all EX Special Attacks to
              apply an additional 20% Anomaly Buildup Resistance reduction of
              the character's element (Physical) for 8 seconds to all enemies
              hit, resulting in effectively 20% more Assaults for the team
              (before Anomaly cap and cooldown). This set bonus is easier to
              apply for Caesar than most thanks to her EX being an integral and
              accessible part of her kit - timing her EX's to align with your
              anomaly characters burst windows will still be essential though.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> for energy regen,
              </li>
              <li>
                <strong>Shockstar Disco</strong> for max Daze.
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>3</p>
            </div>
            <div>
              <ZZZDiskSet name="Swing Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              This set is a strong option in burst damage teams and offers the
              entire squad a 15% Damage increase for 12 seconds after the wearer
              launches a Chain Attack or Ultimate. It’s particularly effective
              when activated through a Chain Attack, as it perfectly buffs all
              allies just before the team’s largest damage window during a Stun.
              However, its value diminishes in team compositions or combat
              situations where Stuns are less frequent.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Proto Punk</strong> for larger Aegis shields.
              </li>
              <li>
                <strong>Shockstar Disco</strong> for max Daze.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'lucy' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Swing Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best set for Supports aiming to support direct damage dealers or
              Anomaly characters that do not require the buff from Freedom
              Blues. Grants the entire squad a 15% DMG increase for 12 seconds
              following a Chain Attack or Ultimate. Almost always activated via
              a Chain Attack which perfectly buffs all allies right before the
              team's largest damage window during stun.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Hormone Punk</strong> to help cap out the Cheer On! buff
                (Recommended),
              </li>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost Anomaly damage.
              </li>
              <li>
                <strong>Puffer Electro</strong> for 8% PEN Ratio if you want to
                improve Lucy's damage output.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'soukaku' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Swing Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best set for Supports aiming to support direct damage dealers or
              Anomaly characters that do not require the buff from Freedom
              Blues. Grants the entire squad a 15% DMG increase for 12 seconds
              following a Chain Attack or Ultimate. Almost always activated via
              a Chain Attack which perfectly buffs all allies right before the
              team's largest damage window during stun.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Hormone Punk</strong> to help cap out Soukaku's Fly the
                Flag buff (Recommended),
              </li>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost Anomaly damage,
              </li>
              <li>
                <strong>Puffer Electro</strong> for 8% PEN Ratio or{' '}
                <strong>Polar Metal</strong> for 10% Ice DMG if you want to
                improve Soukaku's damage output.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'rina' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Swing Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best set for Supports aiming to support direct damage dealers or
              Anomaly characters that do not require the buff from Freedom
              Blues. Grants the entire squad a 15% DMG increase for 12 seconds
              following a Chain Attack or Ultimate. Almost always activated via
              a Chain Attack which perfectly buffs all allies right before the
              team's largest damage window during stun.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> to maximize Rina's PEN Ratio
                buff (Recommended),
              </li>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost Anomaly damage,
              </li>
              <li>
                <strong>Hormone Punk</strong> or <strong>Thunder Metal</strong>{' '}
                to buff overall damage.
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>2</p>
            </div>
            <div>
              <ZZZDiskSet name="Freedom Blues" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best set for supports aiming to support Anomaly characters that
              causes all EX Special Attacks to apply a 20% Anomaly Buildup
              Resistance reduction for 8 seconds to all enemies hit. If this
              debuff is the only source of Anomaly Buildup Resistance reduction
              you have, its equivalent to enemies triggering Anomaly at minimum
              20% faster. This set is a huge boon for Anomaly-based teams and is
              a top priority to have on at least one character in the team.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Puffer Electro</strong> to maximize Rina's PEN Ratio
                buff (Recommended),
              </li>
              <li>
                <strong>Swing Jazz</strong> for 20% Energy Regen,
              </li>
              <li>
                <strong>Hormone Punk</strong> or <strong>Thunder Metal</strong>{' '}
                to buff overall damage.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'nicole-demara' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Swing Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best set for Supports aiming to support direct damage dealers or
              Anomaly characters that do not require the buff from Freedom
              Blues. Grants the entire squad a 15% DMG increase for 12 seconds
              following a Chain Attack or Ultimate. Almost always activated via
              a Chain Attack which perfectly buffs all allies right before the
              team's largest damage window during stun.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost Anomaly damage (Recommended),
              </li>
              <li>
                <strong>Hormone Punk</strong> or <strong>Puffer Electro</strong>{' '}
                to buff overall damage.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'ben' && (
        <>
          <h6>Support</h6>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Swing Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              <strong>If you're playing Ben in the Support role.</strong> Best
              set for Supports aiming to support direct damage dealers or
              Anomaly characters that do not require the buff from Freedom
              Blues. Grants the entire squad a 15% DMG increase for 12 seconds
              following a Chain Attack or Ultimate. Almost always activated via
              a Chain Attack which perfectly buffs all allies right before the
              team's largest damage window during stun.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Soul Rock</strong> (Recommended for highest Shields),
              </li>
              <li>
                <strong>Puffer Electro</strong> for 8% PEN Ratio or{' '}
                <strong>Inferno Metal</strong> for 10% Fire DMG.
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>2</p>
            </div>
            <div>
              <ZZZDiskSet name="Proto Punk" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Powerful supportive alternative or addition to Swing Jazz
              especially for agents that possess a shield within their kit.
              Increases the size of all shields provided by the wearer by 15%
              and also provides a 15% damage increase for 10 seconds when any
              squad member triggers a Defensive Assist or Evasive Assist.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> (Recommended),
              </li>
              <li>
                <strong>Shockstar Disco</strong> for more Daze.
              </li>
            </ul>
          </div>
          <h6 className="with-margin">Stun</h6>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Shockstar Disco" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              <strong>If you're playing Ben in the Stun role.</strong> Best
              option for all Stun-focused characters granting 6% permanent
              Impact% from the 2-PC and 20% increased Daze for Basic Attacks,
              Dash Attacks and Dodge Counters. As Stun characters top priority
              is dealing as much Daze as possible as fast as possible, no other
              set comes close to this one and the 4-PC should be pursued even at
              the cost of good sub-stats.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Soul Rock</strong> (Recommended for highest Shields),
              </li>
              <li>
                <strong>Swing Jazz</strong> for 20% Energy Regen,
              </li>
              <li>
                <strong>Freedom Blues</strong> for 30 Anomaly Proficiency to
                boost anomaly damage,
              </li>
              <li>
                <strong>Inferno Metal</strong>, <strong>Puffer Electro</strong>{' '}
                or <strong>Hormone Punk</strong> to boost overall damage.
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'seth' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Swing Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Best set for Supports aiming to support direct damage dealers or
              Anomaly characters that do not require the buff from Freedom
              Blues. Grants the entire squad a 15% DMG increase for 12 seconds
              following a Chain Attack or Ultimate. Almost always activated via
              a Chain Attack which perfectly buffs all allies right before the
              team's largest damage window during stun.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Hormone Punk/Proto Punk</strong> (Recommended for
                highest Shields/DMG),
              </li>
              <li>
                <strong>Shockstar Disco</strong> for a slightly increased Daze
                effect.
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>2</p>
            </div>
            <div>
              <ZZZDiskSet name="Proto Punk" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Powerful supportive alternative or addition to Swing Jazz
              especially for agents that possess a shield within their kit.
              Increases the size of all Shields provided by the wearer by 15%
              and also provides a 15% damage increase for 10 seconds when any
              squad member triggers a Defensive Assist or Evasive Assist.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> (Recommended),
              </li>
              <li>
                <strong>Hormone Punk</strong> (Recommended for highest Shields),
              </li>
              <li>
                <strong>Shockstar Disco</strong> for a slightly increased Daze
                effect.
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>3</p>
            </div>
            <div>
              <ZZZDiskSet name="Freedom Blues" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Solid supportive set for Seth when supporting Shock Anomaly
              characters or Disorder teams that capitalize on these effects. It
              causes all EX Special Attacks to apply an additional 20% Anomaly
              Buildup Resistance reduction of the character's element (Electric)
              for 8 seconds to all enemies hit. Although this debuff does suffer
              some lower returns due to overlapping with Seth's Additional
              Ability, it remains beneficial, when Seth’s EXs are used before
              the team applies their Shocks. Despite all of its utility, this
              set often falls short when compared to the benefits of Swing Jazz,
              especially in Disorder teams, unless another support is already
              using that set.
            </p>
            <p>Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Swing Jazz</strong> (Recommended),
              </li>
              <li>
                <strong>Hormone Punk/Proto Punk</strong>
              </li>
              <li>
                <strong>Shockstar Disco</strong> for a slightly increased Daze
                effect.
              </li>
            </ul>
          </div>
        </>
      )}
      {/* Anomaly */}
      {name === 'grace-howard' && (
        <>
          <h6>Mono-Electric Teams</h6>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Thunder Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct and Anomaly-based Electric damage
              dealers granting 10% permanent Electric DMG% from the 2-PC and 28%
              increased combat ATK% as long as any enemy is Shocked. This set is
              incredibly powerful as Shock lasts for 10 seconds baseline, giving
              this bonus a long duration and due to the strength of combat ATK%
              multiplying all a characters standard ATK stats like FLAT ATK and
              ATK% ((Base ATK * (1 + ATK%)) + Flat ATK) * (1 + Combat ATK%).
            </p>
            <p className="notes">
              <strong>
                This set is primarily suitable for mono-electric Grace teams
                that go all in on maintaining Shocks on enemies throughout the
                fights and do not consume them with disorders
              </strong>
              . This set is not good in Disorder Grace teams as you wont have
              good Shock uptime as you'll be consuming them with Disorder
              disabling the 4P bonus.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>2</p>
            </div>
            <div>
              <ZZZDiskSet name="Chaos Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Strong and flexible option for Grace's Disorder and Mono electric
              play styles that synergizes well with her quick swapping play
              style and powerful amount of EX Anomaly build up application.
              Grants 30 Anomaly Proficiency and 15% Fire/Electric Dmg%
              unconditionally as well as a combat buff boosting EX Special and
              Assist damage by 20% for 5 seconds after switching on field with a
              7.5 seconds cooldown, also grants the buff at all times the agent
              remains off the field.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>3</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="2" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A mix and match of the most beneficial 2P bonuses for Grace. A set
              combination that falls noticeably behind alternatives but has no
              conditions on its damage bonuses and can be used freely without
              penalty or redundancy in both Mono-Electric and Disorder Grace
              teams.
            </p>
            <p className="notes">Paired together with:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong>
              </li>
              <li>
                <strong>Thunder Metal</strong>
              </li>
            </ul>
          </div>
          <h6 className="with-margin">Disorder Teams</h6>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>1</p>
            </div>
            <div>
              <ZZZDiskSet name="Freedom Blues" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A top option for Disorder Grace teams thanks to its effect that
              causes all EX Special Attacks to apply a 20% Anomaly Buildup
              Resistance reduction of the same element as the wearer for 8
              seconds to all enemies hit. Assuming this debuff is the only
              source of Anomaly Buildup Resistance reduction it's equivalent to
              enemies triggering Anomaly at minimum 20% faster of the specific
              element. This bonus is huge for Grace in Disorder teams as it
              allows her to apply her Shocks more frequently in order to trigger
              more Disorder reactions.
            </p>
            <p className="notes">
              Another reason this set is so appealing is that the signature 4P
              Electric set effect (Thunder Metal) does not function for Grace in
              Disorder teams.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Thunder Metal</strong> (Recommended)
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>2</p>
            </div>
            <div>
              <ZZZDiskSet name="Chaos Jazz" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Strong and flexible option for Grace's Disorder and Mono electric
              play styles that synergizes well with her quick swapping play
              style and powerful amount of EX Anomaly build up application.
              Grants 30 Anomaly Proficiency and 15% Fire/Electric Dmg%
              unconditionally as well as a combat buff boosting EX Special and
              Assist damage by 20% for 5 seconds after switching on field with a
              7.5 seconds cooldown, also grants the buff at all times the agent
              remains off the field.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>3</p>
            </div>
            <div>
              <ZZZDiskSet name="Puffer Electro" onProfile setup="2" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              A mix and match of the most beneficial 2P bonuses for Grace. A set
              combination that falls noticeably behind alternatives but has no
              conditions on its damage bonuses and can be used freely without
              penalty or redundancy in both Mono-Electric and Disorder Grace
              teams.
            </p>
            <p className="notes">Paired together with:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong>
              </li>
              <li>
                <strong>Thunder Metal</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'piper' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Fanged Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct and Anomaly-based Physical damage
              dealers granting 10% permanent Physical DMG% from the 2-PC and 35%
              increased DMG against enemies inflicted by Assault by any party
              member for 12 seconds. DMG% bonus against enemies will be up the
              majority of most fights against boss-type enemies thanks to its
              long duration and ability to trigger from any team member allowing
              a Physical based Bangboo/teammate to assist with uptime via their
              own Assaults.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
        </>
      )}
      {name === 'jane-doe' && (
        <>
          <div className={`single-item ${character_element}`}>
            <div className={`percentage`}>
              <p>100%</p>
            </div>
            <div>
              <ZZZDiskSet name="Fanged Metal" onProfile setup="4" />
            </div>
          </div>
          <div className="information">
            <p className="notes">
              Competitive option for direct and Anomaly-based Physical damage
              dealers granting 10% permanent Physical DMG% from the 2-PC and 35%
              increased DMG against enemies inflicted by Assault by any party
              member for 12 seconds. DMG% bonus against enemies will be up the
              majority of most fights against boss-type enemies thanks to its
              long duration and ability to trigger from any team member allowing
              a Physical based Bangboo/teammate to assist with uptime via their
              own Assaults.
            </p>
            <p className="notes">Best combined with your choice of 2-PC Set:</p>
            <ul>
              <li>
                <strong>Freedom Blues</strong> (Recommended)
              </li>
              <li>
                <strong>Hormone Punk</strong>
              </li>
              <li>
                <strong>Puffer Electro</strong>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};
