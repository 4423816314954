export const ZZZSkillsJSONRevised = {
  Anby_Basic_1: {
    name: 'Basic Attack: Turbo Volt',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 4 slashes. The first 3 hits deal Physical DMG the 4th hit deals Electric DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 31.2,
        dmg_growth: 2.9,
        stun_base: 15.6,
        stun_growth: 0.8
      },
      {
        name: '2nd-Hit',
        dmg_base: 33.7,
        dmg_growth: 3.1,
        stun_base: 28.7,
        stun_growth: 1.4
      },
      {
        name: '3rd-Hit',
        dmg_base: 113.6,
        dmg_growth: 10.4,
        stun_base: 89.6,
        stun_growth: 4.1
      },
      {
        name: '4th-Hit',
        dmg_base: 239.1,
        dmg_growth: 21.8,
        stun_base: 187.4,
        stun_growth: 8.6
      }
    ]
  },
  Anby_Basic_2: {
    name: 'Basic Attack: Thunderbolt',
    description:
      'After unleashing the 3rd hit of the Basic Attack, hold down or pause for a short while, and then press <IconMap:Icon_Normal> to activate.\nUnleashes a downward strike that deals Electric DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 328.6,
        dmg_growth: 29.9,
        stun_base: 142.4,
        stun_growth: 6.5
      }
    ]
  },
  Anby_Skill_1: {
    name: 'Special Attack: Fork Lightning',
    description:
      'Press <IconMap:Icon_Special> to activate.\nUnleashes an upward slash that deals Electric DMG.\nThis attack launches faster when used after the 3rd-hit of the Basic Attack or Basic Attack: Thunderbolt.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 93.4,
        dmg_growth: 8.5,
        stun_base: 93.4,
        stun_growth: 4.3
      }
    ]
  },
  Anby_Skill_2: {
    name: 'EX Special Attack: Lightning Bolt',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nUnleashes a powerful upward slash that deals Electric DMG.\nThis attack launches faster when used after the 3rd hit of her Basic Attack or Basic Attack: Thunderbolt.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 583,
        dmg_growth: 53,
        stun_base: 481.8,
        stun_growth: 21.9
      },
      {
        name: 'Energy Cost',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Anby_Dodge_1: {
    name: 'Dodge: Slide',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Anby_Dodge_2: {
    name: 'Dash Attack: Taser Blast',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nSlashes all nearby enemies, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 56.7,
        dmg_growth: 5.2,
        stun_base: 28.4,
        stun_growth: 1.3
      }
    ]
  },
  Anby_Dodge_3: {
    name: 'Dodge Counter: Thunderclap',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nSlashes enemies in front, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 180.2,
        dmg_growth: 16.4,
        stun_base: 161.7,
        stun_growth: 7.4
      }
    ]
  },
  Anby_Chain_1: {
    name: 'Chain Attack: Electro Engine',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nUnleashes a powerful upwards slash at enemies in a small area, dealing massive Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 542.4,
        dmg_growth: 49.4,
        stun_base: 143.4,
        stun_growth: 6.6
      }
    ]
  },
  Anby_Ultimate_1: {
    name: 'Ultimate: Overdrive Engine',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nUnleashes a powerful upward slash at enemies in a small area followed by several falling attacks, dealing massive Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1512.6,
        dmg_growth: 137.6,
        stun_base: 991.6,
        stun_growth: 45.1
      }
    ]
  },
  Anby_Recover_1: {
    name: 'Quick Assist: Thunderfall',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nSlashes enemies in front, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 61.7,
        dmg_growth: 12.4,
        stun_base: 61.7,
        stun_growth: 6.2
      }
    ]
  },
  Anby_Parry_1: {
    name: 'Defensive Assist: Flash',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 246.7,
        stun_growth: 11.3
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 311.7,
        stun_growth: 14.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 151.7,
        stun_growth: 6.9
      }
    ]
  },
  Anby_Assist_1: {
    name: 'Assist Follow-Up: Lightning Whirl',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nPerform a spinning slash against enemies in front, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 335.2,
        dmg_growth: 30.5,
        stun_base: 291.4,
        stun_growth: 13.3
      }
    ]
  },
  Neko_Basic_1: {
    name: 'Basic Attack: Kitty Slash',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 5 slashes, dealing Physical DMG.\nOn the last hit, there is a 33.3% chance to repeat this hit 3 times.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 55.2,
        dmg_growth: 5.1,
        stun_base: 18,
        stun_growth: 0.9
      },
      {
        name: '2nd-Hit',
        dmg_base: 62.6,
        dmg_growth: 5.7,
        stun_base: 37.1,
        stun_growth: 1.7
      },
      {
        name: '3rd-Hit',
        dmg_base: 72.7,
        dmg_growth: 6.7,
        stun_base: 46.7,
        stun_growth: 2.2
      },
      {
        name: '4th-Hit',
        dmg_base: 170.2,
        dmg_growth: 15.5,
        stun_base: 103.7,
        stun_growth: 4.8
      },
      {
        name: '5th-Hit',
        dmg_base: 123.6,
        dmg_growth: 11.3,
        stun_base: 58.9,
        stun_growth: 2.7
      }
    ]
  },
  Neko_Basic_2: {
    name: 'Basic Attack: Crimson Blade',
    description:
      "Hold down <IconMap:Icon_Normal> during the first 3 hits of Nekomata's Basic Attack to activate.\nSlashes enemies in front and passes through them, dealing Physical DMG.\nUpon activation, there is a 33.3% chance to repeat this attack 3 times.",
    multipliers: [
      {
        name: '',
        dmg_base: 71.8,
        dmg_growth: 6.6,
        stun_base: 58.9,
        stun_growth: 2.7
      }
    ]
  },
  Neko_Skill_1: {
    name: 'Special Attack: Surprise Attack',
    description:
      'Press <IconMap:Icon_Special> to activate.\nUnleashes a downward strike, dealing Physical DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 47.3,
        dmg_growth: 4.3,
        stun_base: 47.3,
        stun_growth: 2.2
      }
    ]
  },
  Neko_Skill_2: {
    name: 'EX Special Attack: Super Surprise Attack!',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nUnleashes a powerful falling slash that deals massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 539.7,
        dmg_growth: 49.1,
        stun_base: 455.4,
        stun_growth: 20.7
      },
      {
        name: 'Energy Cost',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Neko_Dodge_1: {
    name: "Dodge: Can't Touch Me-ow~",
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Neko_Dodge_2: {
    name: 'Dash Attack: Over Here!',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nSlashes enemies in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 35.1,
        dmg_growth: 3.2,
        stun_base: 17.6,
        stun_growth: 0.8
      }
    ]
  },
  Neko_Dodge_3: {
    name: 'Dodge Counter: Phantom Claws',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nSlashes enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 227.9,
        dmg_growth: 20.8,
        stun_base: 199.5,
        stun_growth: 9.1
      }
    ]
  },
  Neko_Chain_1: {
    name: 'Chain Attack: Claw Swipe',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nUnleashes a powerful slash at enemies ahead in a large area, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 536.2,
        dmg_growth: 48.8,
        stun_base: 159.2,
        stun_growth: 7.4
      }
    ]
  },
  Neko_Ultimate_1: {
    name: 'Ultimate: Claw Smash',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nUnleashes powerful slashes at enemies ahead in a large area, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1571.1,
        dmg_growth: 142.9,
        stun_base: 118.1,
        stun_growth: 5.4
      }
    ]
  },
  Neko_Recover_1: {
    name: "Quick Assist: Cat's Paw",
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nSlashes enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 94.5,
        dmg_growth: 8.6,
        stun_base: 94.5,
        stun_growth: 4.3
      }
    ]
  },
  Neko_Parry_1: {
    name: 'Defensive Assist: Desperate Defense',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate: \nParries the enemy's attack, dealing massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 259,
        stun_growth: 11.8
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 327.3,
        stun_growth: 14.9
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 159.3,
        stun_growth: 7.3
      }
    ]
  },
  Neko_Assist_1: {
    name: 'Assist Follow-Up: Shadow Strike',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCharge and slash enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 300.4,
        dmg_growth: 60.1,
        stun_base: 258.1,
        stun_growth: 25.9
      }
    ]
  },
  Nicole_Basic_1: {
    name: 'Basic Attack: Cunning Combo',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 3 strikes, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 65.9,
        dmg_growth: 6,
        stun_base: 40.2,
        stun_growth: 2.1
      },
      {
        name: '2nd-Hit',
        dmg_base: 71.3,
        dmg_growth: 6.5,
        stun_base: 56.6,
        stun_growth: 2.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 304.3,
        dmg_growth: 27.3,
        stun_base: 242.3,
        stun_growth: 10.8
      }
    ]
  },
  Nicole_Basic_2: {
    name: 'Basic Attack: Do As I Please',
    description:
      'After using a Special Attack, EX Special Attack, Chain Attack, or Ultimate, Nicole can reload, and her Basic Attack and Dash Attack are enhanced, increasing the power of her bullets.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 88.1,
        dmg_growth: 8.1,
        stun_base: 40.2,
        stun_growth: 2.1
      },
      {
        name: '2nd-Hit',
        dmg_base: 100.9,
        dmg_growth: 9.3,
        stun_base: 56.6,
        stun_growth: 2.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 452.3,
        dmg_growth: 41.3,
        stun_base: 242.3,
        stun_growth: 10.8
      }
    ]
  },
  Nicole_Skill_1: {
    name: 'Special Attack: Sugarcoated Bullet',
    description:
      'Press <IconMap:Icon_Special> to activate.\nUnleashes a ranged strike against enemies in front, dealing Ether DMG.\nAnti-Interrupt level is increased while using this skill.\nAutomatically reloads after the attack to enhance Basic Attack and Dash Attack up to 8 times.',
    multipliers: [
      {
        name: '',
        dmg_base: 52.6,
        dmg_growth: 4.8,
        stun_base: 50,
        stun_growth: 2.4
      }
    ]
  },
  Nicole_Skill_2: {
    name: 'EX Special Attack: Stuffed Sugarcoated Bullet',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nA powerful ranged strike, generating an energy field that pulls enemies towards its center to deal Ether DMG.\nHold <IconMap:Icon_SpecialReady> down to charge up this attack, during which a small energy field is generated at the muzzle as Energy is consumed, dealing bonus DMG to enemies nearby.\nCharacter is invulnerable while using this skill.\nA Quick Assist is triggered when this skill hits an enemy.\nAutomatically reloads after the attack to enhance Basic Attack and Dash Attack up to 8 times.',
    multipliers: [
      {
        name: 'Charged Attack',
        dmg_base: 215.2,
        dmg_growth: 19.6,
        stun_base: 179.8,
        stun_growth: 8.2
      },
      {
        name: 'Bombard',
        dmg_base: 215.1,
        dmg_growth: 19.6,
        stun_base: 179.8,
        stun_growth: 8.2
      },
      {
        name: 'Energy Field',
        dmg_base: 387,
        dmg_growth: 35.2,
        stun_base: 323.6,
        stun_growth: 14.8
      },
      {
        name: 'Charger Attack Energy Cost (per sec)',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Bombard Energy Cost',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Nicole_Dodge_1: {
    name: 'Dodge: Speed Demon',
    description:
      'Press <IconMap:Icon_Evade> to activate.\nA rapid dodge.\nCharacter is invulnerable while using this skill.\nDrag the <IconMap:Icon_JoyStick> to dodge, then hold <IconMap:Icon_Evade> to reload while dodging, enhancing Basic Attacks and Dash Attacks up to 8 times.',
    multipliers: []
  },
  Nicole_Dodge_2: {
    name: 'Dash Attack: Jack in the Box',
    description:
      'When dragging the <IconMap:Icon_JoyStick> during a dodge, press <IconMap:Icon_Normal>:\nDashes in the target direction and strikes nearby enemies, dealing Physical DMG.\nWhen not dragging the <IconMap:Icon_JoyStick> during a dodge, press <IconMap:Icon_Normal>:\nVaults backward and launches a ranged strike against enemies in front, dealing Physical DMG.\nAutomatically reloads after the attack to enhance her Basic Attack and Dash Attack, up to 8 times.',
    multipliers: [
      {
        name: 'Forward Dash',
        dmg_base: 158.2,
        dmg_growth: 14.2,
        stun_base: 73.2,
        stun_growth: 4
      },
      {
        name: 'Backward Vault',
        dmg_base: 60,
        dmg_growth: 5.5,
        stun_base: 60,
        stun_growth: 2.8
      }
    ]
  },
  Nicole_Dodge_3: {
    name: 'Dodge Counter: Diverted Bombard',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nDodge backwards and send a ranged strike at enemies in front, dealing Ether DMG.\nCharacter is invulnerable while using this skill.\nAutomatically reloads after the attack to enhance Basic Attack and Dash Attack up to 8 times.',
    multipliers: [
      {
        name: '',
        dmg_base: 182.4,
        dmg_growth: 17.6,
        stun_base: 163.4,
        stun_growth: 7.6
      }
    ]
  },
  Nicole_Chain_1: {
    name: 'Chain Attack: Ether Shellacking',
    description:
      'When a Chain Attack is triggered, select a character to activate:\nUnleashes a powerful ranged strike at enemies in a small area, generating an energy field that pulls enemies towards its center to deal Ether DMG.\nCharacter is invulnerable while using this skill.\nA Quick Assist is triggered when this skill hits an enemy.\nAutomatically reloads after the attack to enhance Basic Attack and Dash Attack up to 8 times.',
    multipliers: [
      {
        name: 'Bombard',
        dmg_base: 209.6,
        dmg_growth: 19.2,
        stun_base: 50,
        stun_growth: 2.4
      },
      {
        name: 'Energy Field',
        dmg_base: 283,
        dmg_growth: 25.8,
        stun_base: 67.5,
        stun_growth: 3.1
      }
    ]
  },
  Nicole_Ultimate_1: {
    name: 'Ultimate: Ether Grenade',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate:\nUnleashes a powerful ranged strike at enemies in a small area, generating an energy field that pulls enemies towards its center to deal Ether DMG.\nWhen using this skill, other squad members recover 10 Energy. \nThe next character to switch in gains an additional 20 energy.\nCharacter is invulnerable while using this skill.\nA Quick Assist is triggered when this skill hits an enemy.\nAutomatically reloads after the attack to enhance Basic Attack and Dash Attack up to 8 times.',
    multipliers: [
      {
        name: 'Bombard',
        dmg_base: 646.8,
        dmg_growth: 58.8,
        stun_base: 36,
        stun_growth: 1.7
      },
      {
        name: 'Energy Field',
        dmg_base: 873.2,
        dmg_growth: 79.4,
        stun_base: 48.6,
        stun_growth: 2.3
      }
    ]
  },
  Nicole_Recover_1: {
    name: 'Quick Assist: Emergency Bombard',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nDodge backwards and send ranged strikes at enemies in front, dealing Ether DMG.\nCharacter is invulnerable while using this skill.\nAutomatically reloads after the attack to enhance Basic Attack and Dash Attack up to 8 times.',
    multipliers: [
      {
        name: '',
        dmg_base: 63.4,
        dmg_growth: 5.8,
        stun_base: 63.4,
        stun_growth: 3
      }
    ]
  },
  Nicole_Parry_1: {
    name: 'Defensive Assist: The Hare Strikes Back',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 246.7,
        stun_growth: 11.7
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 311.7,
        stun_growth: 14.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 151.7,
        stun_growth: 6.9
      }
    ]
  },
  Nicole_Assist_1: {
    name: 'Assist Follow-Up: Window of Opportunity',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCharge forward and bombard enemies, dealing Ether DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 377.1,
        dmg_growth: 34.3,
        stun_base: 330.3,
        stun_growth: 15.1
      }
    ]
  },
  Soldier_Basic_1: {
    name: 'Basic Attack: Warmup Sparks',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 4 slashes, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 34.4,
        dmg_growth: 3.2,
        stun_base: 17.2,
        stun_growth: 0.8
      },
      {
        name: '2nd-Hit',
        dmg_base: 41.2,
        dmg_growth: 3.8,
        stun_base: 34.4,
        stun_growth: 1.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 102.8,
        dmg_growth: 9.4,
        stun_base: 82.3,
        stun_growth: 3.8
      },
      {
        name: '4th-Hit',
        dmg_base: 213.4,
        dmg_growth: 19.4,
        stun_base: 167.6,
        stun_growth: 7.7
      }
    ]
  },
  Soldier_Basic_2: {
    name: 'Basic Attack: Fire Suppression',
    description:
      'Press <IconMap:Icon_Normal> at the right moment to activate. Unleashes a set of more powerful slashes, dealing Fire DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 55.1,
        dmg_growth: 5.1,
        stun_base: 18,
        stun_growth: 0.9
      },
      {
        name: '2nd-Hit',
        dmg_base: 57.2,
        dmg_growth: 5.2,
        stun_base: 33.6,
        stun_growth: 1.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 132,
        dmg_growth: 12,
        stun_base: 75.2,
        stun_growth: 3.5
      },
      {
        name: '4th-Hit',
        dmg_base: 340.7,
        dmg_growth: 31,
        stun_base: 192,
        stun_growth: 8.7
      }
    ]
  },
  Soldier_Skill_1: {
    name: 'Special Attack: Raging Fire',
    description:
      'Press <IconMap:Icon_Special> to activate.\nSlashes enemies nearby, dealing Fire DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 52.6,
        dmg_growth: 4.8,
        stun_base: 52.6,
        stun_growth: 2.4
      }
    ]
  },
  Soldier_Skill_2: {
    name: 'EX Special Attack: Fervent Fire',
    description:
      "With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nSlashes enemies nearby, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.\nAfter using this skill, Soldier 11's Basic Attacks and Dash Attacks will trigger Fire Suppression for up to 15s or 8 times.",
    multipliers: [
      {
        name: '',
        dmg_base: 675,
        dmg_growth: 61.4,
        stun_base: 543.5,
        stun_growth: 24.8
      },
      {
        name: 'Energy Cost',
        dmg_base: 80,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Soldier_Dodge_1: {
    name: 'Dodge: Tempered Fire',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Soldier_Dodge_2: {
    name: 'Dash Attack: Blazing Fire',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nSlashes enemies in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 68.3,
        dmg_growth: 6.3,
        stun_base: 34.2,
        stun_growth: 1.6
      }
    ]
  },
  Soldier_Dodge_3: {
    name: 'Dodge Counter: Backdraft',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nExecutes a powerful slash against enemies in front, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 262,
        dmg_growth: 23.9,
        stun_base: 225.8,
        stun_growth: 10.3
      }
    ]
  },
  Soldier_Dodge_4: {
    name: 'Dash Attack: Fire Suppression',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nSlashes enemies in front, dealing Fire DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 78.8,
        dmg_growth: 7.2,
        stun_base: 78.8,
        stun_growth: 3.6
      }
    ]
  },
  Soldier_Chain_1: {
    name: 'Chain Attack: Uplifting Flame',
    description:
      "When a Chain Attack is triggered, select the character to activate.\nUnleashes a powerful upward slash at enemies in a small area, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.\nAfter using this skill, Soldier 11's Basic Attacks and Dash Attacks trigger Fire Suppression for the next 15s, up to 8 times.",
    multipliers: [
      {
        name: '',
        dmg_base: 632.5,
        dmg_growth: 57.5,
        stun_base: 213.6,
        stun_growth: 9.8
      }
    ]
  },
  Soldier_Ultimate_1: {
    name: 'Ultimate: Bellowing Flame',
    description:
      "When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nUnleashes a powerful upward slash at enemies in a small area, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.\nAfter using this skill, Soldier 11's Basic Attacks and Dash Attacks trigger Fire Suppression for the next 15s, up to 8 times.",
    multipliers: [
      {
        name: '',
        dmg_base: 2103,
        dmg_growth: 191.2,
        stun_base: 285,
        stun_growth: 13
      }
    ]
  },
  Soldier_Recover_1: {
    name: 'Quick Assist: Covering Fire',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nSlashes enemies in front, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 120.8,
        dmg_growth: 11,
        stun_base: 120.8,
        stun_growth: 5.5
      }
    ]
  },
  Soldier_Parry_1: {
    name: 'Defensive Assist: Hold the Line',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 259,
        stun_growth: 11.8
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 327.3,
        stun_growth: 14.9
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 159.3,
        stun_growth: 7.3
      }
    ]
  },
  Soldier_Assist_1: {
    name: 'Assist Follow-Up: Reignition',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCharge and cross slash enemies in front, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 383.7,
        dmg_growth: 34.9,
        stun_base: 335.5,
        stun_growth: 15.3
      }
    ]
  },
  Corin_Basic_1: {
    name: 'Basic Attack: Wipeout',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nUnleashes up to 5 slashes, dealing Physical DMG.\nRepeatedly press or hold the button during the 3rd and 5th hits to extend the chainsaw slash attack.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 82,
        dmg_growth: 7.5,
        stun_base: 41,
        stun_growth: 1.9
      },
      {
        name: '2nd-Hit',
        dmg_base: 76.6,
        dmg_growth: 7,
        stun_base: 70.2,
        stun_growth: 3.2
      },
      {
        name: '3rd-Hit',
        dmg_base: 179.2,
        dmg_growth: 16.3,
        stun_base: 123.8,
        stun_growth: 5.7
      },
      {
        name: '4th-Hit',
        dmg_base: 233.4,
        dmg_growth: 21.3,
        stun_base: 186.4,
        stun_growth: 8.5
      },
      {
        name: '5th-Hit',
        dmg_base: 421.2,
        dmg_growth: 38.3,
        stun_base: 342,
        stun_growth: 15.6
      }
    ]
  },
  Corin_Skill_1: {
    name: 'Special Attack: Clean Sweep',
    description:
      'Press <IconMap:Icon_Special> to activate:\nUnleashes a series of slashes upon enemies in front, before detonating the chainsaw, dealing Physical DMG.\nRepeatedly press or hold the button to extend the duration of the chainsaw slash attack.\nAnti-Interrupt level is increased while using this skill, and DMG taken is reduced by 40%.',
    multipliers: [
      {
        name: 'Spinning Slash',
        dmg_base: 66.7,
        dmg_growth: 6.1,
        stun_base: 66.7,
        stun_growth: 3.1
      },
      {
        name: 'Extended Slash Maximum',
        dmg_base: 37.5,
        dmg_growth: 3.5,
        stun_base: 37.5,
        stun_growth: 1.8
      },
      {
        name: 'Explosion',
        dmg_base: 25,
        dmg_growth: 2.3,
        stun_base: 25,
        stun_growth: 1.2
      }
    ]
  },
  Corin_Skill_2: {
    name: 'EX Special Attack: Beware the Skirt',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate:\nUnleashes a series of powerful slashes upon enemies in front, before detonating the chainsaw, dealing massive Physical DMG.\nRepeatedly press or hold the button to extend the duration of the chainsaw slash attack at the cost of Energy if the attack connects.\nCharacter is invulnerable during the spinning Slash.\nAnti-Interrupt level is increased during the extended slash, and DMG taken is reduced by 40%.',
    multipliers: [
      {
        name: 'Spinning Slash',
        dmg_base: 345.1,
        dmg_growth: 31.4,
        stun_base: 206.4,
        stun_growth: 9.4
      },
      {
        name: 'Extended Slash Maximum',
        dmg_base: 1035.1,
        dmg_growth: 94.2,
        stun_base: 619,
        stun_growth: 28.2
      },
      {
        name: 'Explosion',
        dmg_base: 345.1,
        dmg_growth: 31.4,
        stun_base: 206.4,
        stun_growth: 9.4
      },
      {
        name: 'Spinning Slash Energy Cost',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Extended Slash Maximum Energy Cost',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Explosion Energy Cost',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Corin_Dodge_1: {
    name: 'Dodge: Shoo!',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Corin_Dodge_2: {
    name: 'Dash Attack: Oopsy-Daisy!',
    description:
      "Press <IconMap:Icon_Normal> during a dodge to activate.\nSlashes enemies in front, dealing Physical DMG.\nRepeatedly press or hold the button to extend the chainsaw attack's duration.",
    multipliers: [
      {
        name: '',
        dmg_base: 96.7,
        dmg_growth: 8.8,
        stun_base: 48.4,
        stun_growth: 2.1
      }
    ]
  },
  Corin_Dodge_3: {
    name: 'Dodge Counter: Nope!',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate:\nSlashes enemies in front before detonating her chainsaw, dealing Physical DMG.\nRepeatedly press or hold the button to extend the chainsaw slash attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 271.2,
        dmg_growth: 24.8,
        stun_base: 131.8,
        stun_growth: 6
      }
    ]
  },
  Corin_Chain_1: {
    name: 'Chain Attack: Sorry...',
    description:
      'When a Chain Attack is triggered, select the character to activate:\nUnleashes a powerful slash at enemies in a small area, dealing massive Physical DMG.\nRepeatedly press or hold the button to extend the chainsaw slash attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 687.3,
        dmg_growth: 62.5,
        stun_base: 288.3,
        stun_growth: 13.2
      }
    ]
  },
  Corin_Ultimate_1: {
    name: 'Ultimate: Very, Very Sorry!',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate:\nUnleashes a powerful slash at enemies in a small area, dealing massive Physical DMG.\nRepeatedly press or hold the button to extend the chainsaw slash attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 2028.8,
        dmg_growth: 184.5,
        stun_base: 406.7,
        stun_growth: 18.6
      }
    ]
  },
  Corin_Recover_1: {
    name: 'Quick Assist: Emergency Measures',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nSlashes enemies in front before detonating her chainsaw, dealing Physical DMG.\nRepeatedly press or hold the button to extend the chainsaw slash attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 215,
        dmg_growth: 19.6,
        stun_base: 215,
        stun_growth: 9.8
      }
    ]
  },
  Corin_Parry_1: {
    name: 'Defensive Assist: P—Please allow me!',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nThis skill excels at parrying enemy attacks and reduces the consumption of Assist Points when the character is under intense attacks.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 246.7,
        stun_growth: 11.3
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 311.7,
        stun_growth: 14.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 151.7,
        stun_growth: 6.9
      }
    ]
  },
  Corin_Assist_1: {
    name: 'Assist Follow-Up: Quick Sweep',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate:\nUnleash a series of slashes against enemies in front followed by an explosive chainsaw, dealing Physical DMG.\nRepeatedly press or hold to extend the chainsaw slash attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 547.5,
        dmg_growth: 49.5,
        stun_base: 488.6,
        stun_growth: 22.3
      }
    ]
  },
  Billy_Basic_1: {
    name: 'Basic Attack: Full Firepower',
    description:
      "Press <IconMap:Icon_Normal> to activate:\nUnleashes a piercing attack, dealing Physical DMG.\nRepeatedly press or hold to enter Crouching Shot to extend the attack duration.\nDrag the joystick during Crouching Shot to launch Rolling Shot and adjust the character's position, dealing Physical DMG.\nStop pressing or holding during Crouching Shot to launch Finishing Shot at enemies in front, dealing Physical DMG.",
    multipliers: [
      {
        name: 'Standing Fire',
        dmg_base: 68,
        dmg_growth: 6.2,
        stun_base: 54.4,
        stun_growth: 2.5
      },
      {
        name: 'Standing Bullet',
        dmg_base: 7.6,
        dmg_growth: 0.7,
        stun_base: 6.1,
        stun_growth: 0.3
      },
      {
        name: 'Crouching Fire',
        dmg_base: 61.8,
        dmg_growth: 5.7,
        stun_base: 54.7,
        stun_growth: 2.5
      },
      {
        name: 'Crouching Bullet',
        dmg_base: 12.7,
        dmg_growth: 1.2,
        stun_base: 8.9,
        stun_growth: 0.5
      },
      {
        name: 'Rolling Shot',
        dmg_base: 61.8,
        dmg_growth: 5.7,
        stun_base: 54.7,
        stun_growth: 2.5
      },
      {
        name: 'Finishing Shot',
        dmg_base: 49.5,
        dmg_growth: 4.5,
        stun_base: 55.2,
        stun_growth: 2.6
      }
    ]
  },
  Billy_Skill_1: {
    name: 'Special Attack: Stand Still',
    description:
      'Press <IconMap:Icon_Special> to activate.\nPerforms up to 3 piercing attacks in a line, dealing Physical DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 24.2,
        dmg_growth: 2.2,
        stun_base: 24.2,
        stun_growth: 1
      },
      {
        name: '2nd-Hit',
        dmg_base: 51.7,
        dmg_growth: 4.7,
        stun_base: 51.7,
        stun_growth: 2.4
      },
      {
        name: '3rd-Hit',
        dmg_base: 50.1,
        dmg_growth: 4.6,
        stun_base: 50.1,
        stun_growth: 2.3
      }
    ]
  },
  Billy_Skill_2: {
    name: 'EX Special Attack: Clearance Time',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nLaunches a powerful piercing attack in a straight line, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 543.8,
        dmg_growth: 49.5,
        stun_base: 439.5,
        stun_growth: 20
      },
      {
        name: 'Energy Cost',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Billy_Dodge_1: {
    name: 'Dodge: Risky Business',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Billy_Dodge_2: {
    name: 'Dash Attack: Starlight Sanction',
    description:
      'When dragging the <IconMap:Icon_JoyStick> during a dodge, press <IconMap:Icon_Normal>:\nLaunches a piercing attack at nearby enemies within a large area, dealing Physical DMG.\nWhen not dragging the <IconMap:Icon_JoyStick> during a dodge, press <IconMap:Icon_Normal>:\nLaunches a piercing attack at enemies in a line, dealing Physical DMG.',
    multipliers: [
      {
        name: '360-degree Shot',
        dmg_base: 63.1,
        dmg_growth: 5.8,
        stun_base: 63,
        stun_growth: 2.9
      },
      {
        name: 'Straight Shot ',
        dmg_base: 39,
        dmg_growth: 3.6,
        stun_base: 19.5,
        stun_growth: 0.9
      }
    ]
  },
  Billy_Dodge_3: {
    name: 'Dodge Counter: Fair Fight',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nPierces enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 221.4,
        dmg_growth: 20.2,
        stun_base: 193.4,
        stun_growth: 8.6
      }
    ]
  },
  Billy_Chain_1: {
    name: 'Chain Attack: Starlight Mirage',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nUnleashes a powerful piercing attack at surrounding enemies in a large area, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 735.2,
        dmg_growth: 66.9,
        stun_base: 196.6,
        stun_growth: 9
      }
    ]
  },
  Billy_Ultimate_1: {
    name: 'Ultimate: Starlight Sparkle',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nUnleashes a powerful piercing attack at surrounding enemies in a large area, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1597.7,
        dmg_growth: 145.3,
        stun_base: 190.6,
        stun_growth: 8.7
      }
    ]
  },
  Billy_Recover_1: {
    name: 'Quick Assist: Power of Teamwork',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nLaunches a piercing attack at enemies in front, dealing Physical DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 93.4,
        dmg_growth: 8.5,
        stun_base: 93.4,
        stun_growth: 4.3
      }
    ]
  },
  Billy_Parry_1: {
    name: 'Evasive Assist: Flash Spin',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nDodges the enemy's attack and activates Vital View.\nCharacter is invulnerable while using this skill.",
    multipliers: []
  },
  Billy_Assist_1: {
    name: 'Assist Follow-Up: Fatal Shot',
    description:
      'Press <IconMap:Icon_Special> after an Evasive Assist to activate.\nPierce enemies in a large area in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 388.8,
        dmg_growth: 35.4,
        stun_base: 341.2,
        stun_growth: 15.6
      }
    ]
  },
  Koleda_Basic_1: {
    name: "Basic Attack: Smash 'n' Bash",
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 4 strikes, dealing Physical DMG.\nWhen Koleda has Furnace Fire, she can launch an enhanced Basic Attack after the 2nd hit, consuming the Furnace Fire effect and dealing massive Fire DMG.\nKoleda and Ben can team up when fighting together to launch a coordinated attack, further increasing the power of the attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 63.6,
        dmg_growth: 5.8,
        stun_base: 31.8,
        stun_growth: 1.5
      },
      {
        name: '2nd-Hit',
        dmg_base: 79.2,
        dmg_growth: 7.2,
        stun_base: 65.5,
        stun_growth: 3
      },
      {
        name: '3rd-Hit',
        dmg_base: 126.1,
        dmg_growth: 11.5,
        stun_base: 104.3,
        stun_growth: 4.8
      },
      {
        name: '4th-Hit',
        dmg_base: 317.4,
        dmg_growth: 28.9,
        stun_base: 249.3,
        stun_growth: 11.4
      },
      {
        name: '1st-Hit (Enhanced)',
        dmg_base: 160.8,
        dmg_growth: 14.7,
        stun_base: 61.4,
        stun_growth: 2.8
      },
      {
        name: '2nd-Hit (Enhanced)',
        dmg_base: 404.9,
        dmg_growth: 36.9,
        stun_base: 156.6,
        stun_growth: 7.2
      },
      {
        name: '2nd-Hit (Enhanced - Teamwork)',
        dmg_base: 501.3,
        dmg_growth: 45.6,
        stun_base: 213.3,
        stun_growth: 9.7
      }
    ]
  },
  Koleda_Skill_1: {
    name: 'Special Attack: Hammer Time',
    description:
      "Press <IconMap:Icon_Special> to activate:\nStrikes enemies in front and triggers an explosion, dealing Fire DMG.\nThis skill launches faster and triggers an explosion when used after 2nd or 4th hit of Koelda's Basic Attack or enhanced Basic Attack.\nWhen Koleda and Ben are both in your squad, and this skill is activated after an enhanced Basic Attack, they team up to launch a coordinated attack, further increasing the power of the skill.\nAnti-Interrupt level is increased while using this skill.",
    multipliers: [
      {
        name: 'Strike',
        dmg_base: 51.9,
        dmg_growth: 4.8,
        stun_base: 51.9,
        stun_growth: 2.4
      },
      {
        name: 'Explosion',
        dmg_base: 77.8,
        dmg_growth: 7.1,
        stun_base: 77.8,
        stun_growth: 3.6
      },
      {
        name: 'Explosion (Teamwork)',
        dmg_base: 85.5,
        dmg_growth: 7.8,
        stun_base: 77.8,
        stun_growth: 3.6
      }
    ]
  },
  Koleda_Skill_2: {
    name: 'EX Special Attack: Boiling Furnace',
    description:
      "With enough energy, press <IconMap:Icon_SpecialReady> to activate:\nStrikes enemies in front and triggers a powerful explosion, dealing massive Fire DMG. Koleda also gains the Furnace Fire effect.\nThis skill activates faster and triggers an explosion when used after the 2nd or 4th hit of Koleda's Basic Attack or enhanced Basic Attack.\nWhen Koleda and Ben are both in your squad and this skill is activated after an enhanced Basic Attack, they team up to launch a coordinated attack, further increasing the power of the skill.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Strike',
        dmg_base: 152.3,
        dmg_growth: 13.9,
        stun_base: 152.3,
        stun_growth: 7
      },
      {
        name: 'Explosion',
        dmg_base: 606,
        dmg_growth: 55.1,
        stun_base: 494,
        stun_growth: 22.5
      },
      {
        name: 'Explosion (Teamwork)',
        dmg_base: 666.6,
        dmg_growth: 60.6,
        stun_base: 494,
        stun_growth: 22.5
      },
      {
        name: 'Energy Cost',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Koleda_Dodge_1: {
    name: "Dodge: Wait 'n' See",
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Koleda_Dodge_2: {
    name: 'Dash Attack: Tremble!',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nStrikes enemies in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 56.1,
        dmg_growth: 5.1,
        stun_base: 28.1,
        stun_growth: 1.3
      }
    ]
  },
  Koleda_Dodge_3: {
    name: "Dodge Counter: Don't Look Down on Me",
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nStrikes enemies in front and creates an explosion, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 343.2,
        dmg_growth: 31.3,
        stun_base: 288.8,
        stun_growth: 13.2
      }
    ]
  },
  Koleda_Chain_1: {
    name: 'Chain Attack: Natural Disaster',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nUnleashes a powerful explosion on enemies in a large area, dealing massive Fire DMG while also obtaining the Furnace Fire effect.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 636,
        dmg_growth: 57.9,
        stun_base: 217,
        stun_growth: 9.9
      }
    ]
  },
  Koleda_Ultimate_1: {
    name: 'Ultimate: Hammerquake',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nUnleashes a powerful explosion on enemies in a large area in front, dealing massive Fire DMG while also obtaining the Furnace Fire effect.\nKoleda and Ben can team up when fighting together to launch a coordinated attack, further increasing the power of the attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: 'Normal',
        dmg_base: 1548.8,
        dmg_growth: 140.8,
        stun_base: 1004.9,
        stun_growth: 45.7
      },
      {
        name: 'Teamwork',
        dmg_base: 1694,
        dmg_growth: 154,
        stun_base: 1004.9,
        stun_growth: 49.9
      }
    ]
  },
  Koleda_Recover_1: {
    name: 'Quick Assist: Coming Thru!',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nStrikes enemies in front, dealing Fire DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 183.8,
        dmg_growth: 16.8,
        stun_base: 183.8,
        stun_growth: 8.4
      }
    ]
  },
  Koleda_Parry_1: {
    name: 'Defensive Assist: Protective Hammer',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nThis skill excels at parrying enemy attacks and reduces the consumption of Assist Points when the character is under intense attacks.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 259,
        stun_growth: 11.8
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 327.3,
        stun_growth: 14.9
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 159.3,
        stun_growth: 7
      }
    ]
  },
  Koleda_Assist_1: {
    name: 'Assist Follow-Up: Hammer Bell',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nKoleda charges and strikes enemies in front, dealing Fire DMG and obtaining Furnace Fire effect.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 359.2,
        dmg_growth: 32.7,
        stun_base: 312.7,
        stun_growth: 14.3
      }
    ]
  },
  Anton_Basic_1: {
    name: 'Basic Attack: Enthusiastic Drills',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 4 mixed attacks in front, dealing Physical DMG.\nThe 4th-hit is a Piledriver Attack.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 67.8,
        dmg_growth: 6.2,
        stun_base: 33.9,
        stun_growth: 1.6
      },
      {
        name: '2nd-Hit',
        dmg_base: 92.3,
        dmg_growth: 8.4,
        stun_base: 75.3,
        stun_growth: 3.5
      },
      {
        name: '3rd-Hit',
        dmg_base: 109.3,
        dmg_growth: 9.9,
        stun_base: 93.3,
        stun_growth: 4.3
      },
      {
        name: '4th-Hit',
        dmg_base: 229.1,
        dmg_growth: 20.9,
        stun_base: 181.4,
        stun_growth: 8.3
      }
    ]
  },
  Anton_Basic_2: {
    name: 'Basic Attack: Enthusiastic Drills (Burst Mode)',
    description:
      'Press <IconMap:Icon_Normal> while Anton is in Burst Mode to activate:\nUnleashes up to 3 mixed attacks in front, dealing massive Electric DMG.\nDuring the 2nd Drill Attack and 3rd Pile Driver Attack, press repeatedly or hold <IconMap:Icon_Normal> to extend the skill duration. ',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 240.9,
        dmg_growth: 21.9,
        stun_base: 166,
        stun_growth: 7.6
      },
      {
        name: '2nd-Hit',
        dmg_base: 469.2,
        dmg_growth: 42.7,
        stun_base: 323.3,
        stun_growth: 14.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 456.9,
        dmg_growth: 41.7,
        stun_base: 314.8,
        stun_growth: 14.4
      }
    ]
  },
  Anton_Skill_1: {
    name: 'Special Attack: Spin, Bro!',
    description:
      'Press <IconMap:Icon_Special> to activate.\nUnleashes a Pile Driver Attack against enemies in a line in front, dealing Electric DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 44.2,
        dmg_growth: 4.1,
        stun_base: 44.2,
        stun_growth: 2.1
      }
    ]
  },
  Anton_Skill_2: {
    name: 'EX Special Attack: Smash the Horizon, Bro!',
    description:
      "With enough energy, press <IconMap:Icon_SpecialReady> to activate:\nLaunches several Pile Driver Attacks at enemies in a line in front of him, dealing massive Electric DMG.\nCharacter is invulnerable while using this skill.\nAnton enters Burst Mode after using this skill. When in Burst Mode, Anton's attacks become more powerful, dealing massive Electric DMG while continually consuming Energy upon hitting an enemy. Burst Mode remains active until his Energy is depleted.",
    multipliers: [
      {
        name: '',
        dmg_base: 195.1,
        dmg_growth: 17.8,
        stun_base: 195.1,
        stun_growth: 8.9
      },
      {
        name: 'Energy Cost',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Anton_Skill_3: {
    name: 'Special Attack: Explosive Drill (Burst Mode)',
    description:
      'Press <IconMap:Icon_Special> while in Burst Mode to activate.\nLaunches a Pile Driver Attack at enemies in front, dealing Electric DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 231.4,
        dmg_growth: 21.1,
        stun_base: 118.4,
        stun_growth: 5.4
      }
    ]
  },
  Anton_Dodge_1: {
    name: "Dodge: Let's Move",
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Anton_Dodge_2: {
    name: 'Dash Attack: Fire With Fire',
    description:
      'Press <IconMap:Icon_Normal> during a Dodge to activate:\nRams into enemies in front of him, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 68.4,
        dmg_growth: 6.3,
        stun_base: 34.2,
        stun_growth: 1.6
      }
    ]
  },
  Anton_Dodge_3: {
    name: 'Dodge Counter: Retaliation',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nContinuously strikes enemies in front with heavy punches, dealing Physical DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 271.2,
        dmg_growth: 24.7,
        stun_base: 231.7,
        stun_growth: 10.6
      }
    ]
  },
  Anton_Dodge_4: {
    name: 'Dodge Counter: Overload Drill (Burst Mode)',
    description:
      'While in Burst Mode, press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nLaunch a Drill Attack at enemies in front of him, dealing massive Electric DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 465.4,
        dmg_growth: 42.4,
        stun_base: 351.8,
        stun_growth: 16
      }
    ]
  },
  Anton_Chain_1: {
    name: 'Chain Attack: Go Go Go!',
    description:
      'When a Chain Attack is triggered, select the character to activate:\nUnleashes a Pile Driver Attack onto the ground in front of him over a large area, dealing massive Electric DMG to enemies within range.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 640.7,
        dmg_growth: 58.3,
        stun_base: 241.7,
        stun_growth: 11
      }
    ]
  },
  Anton_Ultimate_1: {
    name: 'Ultimate: Go Go Go Go Go!',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_Ultimate> to activate.\nUnleashes a Pile Driver Attack onto the ground in front of him over a large area, dealing massive Electric DMG to enemies within range.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1816.4,
        dmg_growth: 165.2,
        stun_base: 243.4,
        stun_growth: 11.1
      }
    ]
  },
  Anton_Recover_1: {
    name: 'Quick Assist: Shoulder-To-Shoulder',
    description:
      'When the character in combat is launched, press <IconMap:Icon_Switch> to activate.\nContinuously strikes enemies in front of him with heavy punches, dealing Physical DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 263.4,
        dmg_growth: 24,
        stun_base: 131.7,
        stun_growth: 6
      }
    ]
  },
  Anton_Recover_2: {
    name: 'Quick Assist: Protective Drill (Burst Mode)',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nAttacks enemies in front with a Drill Attack, dealing massive Electric DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 365.4,
        dmg_growth: 33.3,
        stun_base: 251.8,
        stun_growth: 11.5
      }
    ]
  },
  Anton_Parry_1: {
    name: 'Defensive Assist: Iron Wrist',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nThis skill excels at parrying enemy attacks and reduces the consumption of Assist Points when the character is under intense attacks.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 246.7,
        stun_growth: 11.7
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 311.7,
        stun_growth: 14.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 151.7,
        stun_growth: 6.9
      }
    ]
  },
  Anton_Assist_1: {
    name: 'Assist Follow-Up: Limit Burst',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nLaunches a Drill Attack that ends in a Pile Driver Attack, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 325.8,
        dmg_growth: 29.7,
        stun_base: 282.7,
        stun_growth: 12.9
      }
    ]
  },
  Ben_Basic_1: {
    name: 'Basic Attack: Debt Reconciliation',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 3 strikes, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 65.9,
        dmg_growth: 6,
        stun_base: 47.1,
        stun_growth: 2.2
      },
      {
        name: '2nd-Hit',
        dmg_base: 189,
        dmg_growth: 17.2,
        stun_base: 156.9,
        stun_growth: 7.2
      },
      {
        name: '3rd-Hit',
        dmg_base: 348.3,
        dmg_growth: 31.7,
        stun_base: 260.1,
        stun_growth: 11.9
      }
    ]
  },
  Ben_Skill_1: {
    name: 'Special Attack: Fiscal Fist',
    description:
      "Press <IconMap:Icon_Special> to activate.\nBen lifts his weapon, blocking the enemy's attack before unleashing a forward strike, dealing Physical DMG. Successfully blocking an attack enhances the power of this skill and triggers Block Counter, dealing massive Physical DMG.\nHold <IconMap:Icon_Special> to remain in the Blocking stance.\nWhile in the Blocking stance, Ben gains a Shield that weakens over time.\nAfter leaving the blocking stance, the Shield is removed and recovers over time.\nThe Shield cannot exceed 16% of Ben's Max HP.\nBen is invulnerable when activating Block.\nAnti-Interrupt level is increased while launching strikes.",
    multipliers: [
      {
        name: 'Active Attack',
        dmg_base: 41.7,
        dmg_growth: 3.8,
        stun_base: 41.7,
        stun_growth: 1.9
      },
      {
        name: 'Block Counter',
        dmg_base: 233.4,
        dmg_growth: 21.3,
        stun_base: 233.4,
        stun_growth: 10.2
      }
    ]
  },
  Ben_Skill_2: {
    name: 'EX Special Attack: Cashflow Counter',
    description:
      "With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nBen lifts his weapon, blocking the enemy's attacks before unleashing a forward strike, dealing Fire DMG. Successfully blocking an attack enhances the power of this skill and triggers Block Counter, dealing massive Fire DMG.\nHold <IconMap:Icon_SpecialReady> to remain in the Blocking stance.\nWhile in the Blocking stance, Ben gains a Shield that weakens over time.\nAfter leaving the blocking stance, the Shield is removed and recovers over time.\nThe Shield cannot exceed <b>16%</b> of Ben's Max HP.\nWhen this skill is activated, the Shield is restored to its maximum value.\nAfter activating the skill, press <IconMap:Icon_SpecialReady> again to follow up with a powerful forward strike, dealing massive Fire DMG.\nBen is invulnerable when activating Block.\nBen is invulnerable while launching strikes.",
    multipliers: [
      {
        name: 'Active Attack',
        dmg_base: 438.5,
        dmg_growth: 39.9,
        stun_base: 247.1,
        stun_growth: 11.3
      },
      {
        name: 'Follow-Up',
        dmg_base: 438.5,
        dmg_growth: 39.9,
        stun_base: 247.1,
        stun_growth: 11.3
      },
      {
        name: 'Block Counter',
        dmg_base: 500.5,
        dmg_growth: 45.5,
        stun_base: 367.6,
        stun_growth: 16.8
      },
      {
        name: 'Block Follow-Up',
        dmg_base: 551.2,
        dmg_growth: 55.2,
        stun_base: 367.6,
        stun_growth: 16.8
      }
    ]
  },
  Ben_Dodge_1: {
    name: 'Dodge: Missing Invoice',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Ben_Dodge_2: {
    name: 'Dash Attack: Incoming Expense',
    description:
      'Press <IconMap:Icon_Normal> during a Dodge to activate.\nStrikes enemies in front of him, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 138.4,
        dmg_growth: 12.6,
        stun_base: 69.2,
        stun_growth: 3.2
      }
    ]
  },
  Ben_Dodge_3: {
    name: 'Dodge Counter: Accounts Settled',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nStrike enemies in front, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 225.7,
        dmg_growth: 20.6,
        stun_base: 196.7,
        stun_growth: 9
      }
    ]
  },
  Ben_Chain_1: {
    name: 'Chain Attack: Signed and Sealed',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nUnleashes a powerful slash at enemies in front of him in a large area, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 627.3,
        dmg_growth: 57.1,
        stun_base: 328.3,
        stun_growth: 15
      }
    ]
  },
  Ben_Ultimate_1: {
    name: 'Ultimate: Complete Payback',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_Ultimate> to activate.\nUnleashes a powerful strike at enemies in front in a large area, dealing massive Fire DMG.\nWhen activating the skill, additionally recover 3 Assist Points.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1643,
        dmg_growth: 149.4,
        stun_base: 110,
        stun_growth: 5
      }
    ]
  },
  Ben_Recover_1: {
    name: 'Quick Assist: Joint Account',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nStrikes enemies in front, dealing Fire DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 96.7,
        dmg_growth: 8.8,
        stun_base: 96.7,
        stun_growth: 4.4
      }
    ]
  },
  Ben_Parry_1: {
    name: 'Defensive Assist: Risk Allocation',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nThis skill excels at parrying enemy attacks and reduces the consumption of Assist Points when the character is under intense attacks.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 225.1,
        stun_growth: 10.3
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 268.4,
        stun_growth: 12.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 108.4,
        stun_growth: 5
      }
    ]
  },
  Ben_Assist_1: {
    name: "Assist Follow-Up: Don't Break Contract",
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCharge and strike enemies in front, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 325.9,
        dmg_growth: 29.7,
        stun_base: 282.8,
        stun_growth: 19.9
      }
    ]
  },
  Soukaku_Basic_1: {
    name: 'Basic Attack: Making Rice Cakes',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 3 attacks, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 66.2,
        dmg_growth: 6.1,
        stun_base: 33.1,
        stun_growth: 1.6
      },
      {
        name: '2nd-Hit',
        dmg_base: 216.3,
        dmg_growth: 19.9,
        stun_base: 188,
        stun_growth: 8.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 293.1,
        dmg_growth: 26.7,
        stun_base: 221.7,
        stun_growth: 10.1
      }
    ]
  },
  Soukaku_Basic_2: {
    name: 'Basic Attack: Making Rice Cakes (Frosted Banner)',
    description:
      'When Frosted Banner is active, press <IconMap:Icon_Normal> to activate.\nUnleashes up to 3 faster attacks in front, dealing Ice DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 76.6,
        dmg_growth: 7,
        stun_base: 46.6,
        stun_growth: 2.2
      },
      {
        name: '2nd-Hit',
        dmg_base: 228.5,
        dmg_growth: 20.8,
        stun_base: 127.5,
        stun_growth: 5.8
      },
      {
        name: '3rd-Hit',
        dmg_base: 511.4,
        dmg_growth: 46.5,
        stun_base: 263.3,
        stun_growth: 12
      }
    ]
  },
  Soukaku_Skill_1: {
    name: 'Special Attack: Cooling Bento',
    description:
      'Press <IconMap:Icon_Special> to activate.\nSoukaku releases a wind current in front of her and unleashes her finishing move, dealing Ice DMG.\nAnti-Interrupt level is increased while using this skill, and DMG taken is reduced by 40%.\nHolding <IconMap:Icon_Special> while using this skill allows for a quick transition to Fly the Flag.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 28.4,
        dmg_growth: 2.6,
        stun_base: 28.4,
        stun_growth: 1.3
      },
      {
        name: 'Finishing Move',
        dmg_base: 100.1,
        dmg_growth: 9.1,
        stun_base: 100.1,
        stun_growth: 4.6
      }
    ]
  },
  Soukaku_Skill_2: {
    name: 'EX Special Attack: Fanning Mosquitoes',
    description:
      "With enough energyy, press <IconMap:Icon_Special> to activate.\nReleases wind currents of extended range in front of her, dealing massive Ice DMG.\nThis skill can be used in rapid succession by pressing <IconMap:Icon_Special> repeatedly. Each use consumes a specified amount of Energy. When Soukaku's Energy is depleted, or you stop pressing the button, she unleashes a powerful finishing move that deals massive Ice DMG. \nAnti-Interrupt level is increased while using this skill, and her DMG taken is reduced by 40%.\nHolding <IconMap:Icon_Special> while using this skill allows for a quick transition to Fly the Flag.",
    multipliers: [
      {
        name: 'Chain Attack',
        dmg_base: 131.2,
        dmg_growth: 19.9,
        stun_base: 112.9,
        stun_growth: 5.1
      },
      {
        name: 'Wind Current',
        dmg_base: 102.1,
        dmg_growth: 9.3,
        stun_base: 87.9,
        stun_growth: 3
      },
      {
        name: 'Energy Cost to Use',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Energy Cost of Each Attack',
        dmg_base: 30,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Soukaku_Skill_3: {
    name: 'Special Attack: Rally!',
    description:
      "Hold <IconMap:Icon_Special> to activate.\nSoukaku plunges her weapon into the ground, morphs it into a flag, and launches Fly the Flag, dealing Ice DMG. Hitting an enemy with Fly the Flag triggers a Quick Assist.\nPressing <IconMap:Icon_Normal> or <IconMap:Icon_Special> after Fly the Flag activates a Special Attack as she retracts the flag, dealing Ice DMG.\nIf Soukaku owns 3 stacks of Vortex on using Fly the Flag, she consumes all 3 stacks and enters the Frosted Banner state. In the Frosted Banner state, she can unleash an enhanced Basic Attack or an enhanced Dash Attack, dealing massive Ice DMG This effect can last up to 45s or stack up to 6 times.\nSoukaku's weapon gains Vortexes in the following circumstances:\nGains 1 stack of Vortex when launching an EX Special Attack,\nGains 1 stack of Vortex when launching a Chain Attack,\nGains 3 stacks of Vortex when launching an Ultimate.\nSoukaku can accumulate a maximum of 3 stacks of Vortex.",
    multipliers: [
      {
        name: 'Fly the Flag',
        dmg_base: 250.1,
        dmg_growth: 22.8,
        stun_base: 275.1,
        stun_growth: 12.6
      },
      {
        name: 'Quick Fly the Flag',
        dmg_base: 140.1,
        dmg_growth: 12.8,
        stun_base: 140.1,
        stun_growth: 6.4
      },
      {
        name: 'Lowering the Flag',
        dmg_base: 245,
        dmg_growth: 22.3,
        stun_base: 245,
        stun_growth: 11.2
      }
    ]
  },
  Soukaku_Dodge_1: {
    name: 'Dodge: Grab a Bite',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Soukaku_Dodge_2: {
    name: 'Dash Attack: 50/50',
    description:
      'Press <IconMap:Icon_Normal> during a Dodge to activate.\nSlashes enemies in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 76.7,
        dmg_growth: 7,
        stun_base: 38.4,
        stun_growth: 1.8
      }
    ]
  },
  Soukaku_Dodge_3: {
    name: 'Dodge Counter: Away From My Snacks',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nSlashes enemies in front, dealing Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 247.3,
        dmg_growth: 22.5,
        stun_base: 213.4,
        stun_growth: 9.7
      }
    ]
  },
  Soukaku_Dodge_4: {
    name: 'Dash Attack: 50/50 (Frosted Banner)',
    description:
      'When Frosted Banner is active, press <IconMap:Icon_Normal> during a Dodge to activate.\nSlashes enemies in front, dealing Ice DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 131.5,
        dmg_growth: 12,
        stun_base: 80.1,
        stun_growth: 3.7
      }
    ]
  },
  Soukaku_Chain_1: {
    name: 'Chain Attack: Pudding Slash',
    description:
      'When a Chain Attack is triggered, select the character to activate:\nSoukaku quickly unleashes a series of strikes at enemies in front of her and follows up with Fly the Flag, dealing massive Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 748.8,
        dmg_growth: 67.7,
        stun_base: 346.8,
        stun_growth: 15.8
      }
    ]
  },
  Soukaku_Ultimate_1: {
    name: 'Ultimate: Jumbo Pudding Slash',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nSoukaku quickly unleashes an extended series of strikes at enemies in front of her and follows up with Fly the Flag, dealing massive Ice DMG. Upon activating this skill, Soukaku enters the Masked state, increasing her CRIT Rate by 15% for 15s.\nUpon activating this skill, other squad members recover 10 Energy. The next character to switch in gains an additional 20 Energy.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1989.8,
        dmg_growth: 180.9,
        stun_base: 376.8,
        stun_growth: 17.2
      }
    ]
  },
  Soukaku_Recover_1: {
    name: 'Quick Assist: A Set for Two',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nSlashes enemies in front, dealing Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 113.4,
        dmg_growth: 10.4,
        stun_base: 113.4,
        stun_growth: 4.2
      }
    ]
  },
  Soukaku_Parry_1: {
    name: 'Defensive Assist: Guarding Tactics',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nThis skill excels at parrying enemy attacks and reduces the consumption of Assist Points when the character is under intense attacks.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 246.7,
        stun_growth: 11.3
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 311.7,
        stun_growth: 14.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 151.7,
        stun_growth: 6.9
      }
    ]
  },
  Soukaku_Assist_1: {
    name: 'Assist Follow-Up: Sweeping Strike',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCharges and strikes enemies in front, dealing Ice DMG.\nCharacter is invulnerable while using this skill.\nHolding <IconMap:Icon_Special> while using this skill allows for a quick transition to Fly the Flag.',
    multipliers: [
      {
        name: '',
        dmg_base: 264,
        dmg_growth: 24,
        stun_base: 231.3,
        stun_growth: 10.6
      }
    ]
  },
  Lycaon_Basic_1: {
    name: 'Basic Attack: Moon Hunter',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 5 strikes in front, dealing Physical DMG.\nHold down <IconMap:Icon_Normal> to charge up, increasing the power of the attack and dealing Ice DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 29.2,
        dmg_growth: 2.7,
        stun_base: 14.6,
        stun_growth: 0.7
      },
      {
        name: '1st-Hit Charged',
        dmg_base: 37.1,
        dmg_growth: 3.4,
        stun_base: 12.1,
        stun_growth: 0.6
      },
      {
        name: '2nd-Hit',
        dmg_base: 34.9,
        dmg_growth: 3.2,
        stun_base: 30.3,
        stun_growth: 1.4
      },
      {
        name: '2nd-Hit Charged',
        dmg_base: 56.4,
        dmg_growth: 5.2,
        stun_base: 32.9,
        stun_growth: 1.5
      },
      {
        name: '3rd-Hit',
        dmg_base: 58.4,
        dmg_growth: 5.4,
        stun_base: 45.6,
        stun_growth: 2.1
      },
      {
        name: '3rd-Hit Charged',
        dmg_base: 99.5,
        dmg_growth: 9.1,
        stun_base: 53.7,
        stun_growth: 2.5
      },
      {
        name: '4th-Hit',
        dmg_base: 152,
        dmg_growth: 13.9,
        stun_base: 112,
        stun_growth: 5.1
      },
      {
        name: '4th-Hit Charged',
        dmg_base: 210.9,
        dmg_growth: 19.2,
        stun_base: 107.1,
        stun_growth: 4.9
      },
      {
        name: '5th-Hit',
        dmg_base: 180.7,
        dmg_growth: 16.5,
        stun_base: 147.7,
        stun_growth: 6.8
      },
      {
        name: '5th-Hit Level-1 Charged',
        dmg_base: 277.6,
        dmg_growth: 25.3,
        stun_base: 163.1,
        stun_growth: 7.5
      },
      {
        name: '5th-Hit Level-2 Charged',
        dmg_base: 355.7,
        dmg_growth: 32.4,
        stun_base: 205.6,
        stun_growth: 9.4
      }
    ]
  },
  Lycaon_Skill_1: {
    name: 'Special Attack: Time to Hunt',
    description:
      'Press <IconMap:Icon_Special> to activate.\nUnleashes a series of strikes in front, dealing Ice DMG.\nHold down <IconMap:Icon_Special> to charge up, increasing its power.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 77.1,
        dmg_growth: 7.1,
        stun_base: 77.1,
        stun_growth: 3.6
      },
      {
        name: 'Charged',
        dmg_base: 133.1,
        dmg_growth: 12.2,
        stun_base: 133.1,
        stun_growth: 6.2
      }
    ]
  },
  Lycaon_Skill_2: {
    name: 'EX Special Attack: Thrill of the Hunt',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nUnleashes a series of powerful strikes in front, dealing massive Ice DMG.\nHold down <IconMap:Icon_Special> to charge up, consuming Energy and enhancing the power of the attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: 'Base',
        dmg_base: 534.3,
        dmg_growth: 48.7,
        stun_base: 450.4,
        stun_growth: 20.6
      },
      {
        name: 'Charged',
        dmg_base: 789.5,
        dmg_growth: 71.9,
        stun_base: 664.4,
        stun_growth: 30.3
      },
      {
        name: 'Activation Energy Cost',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Charged Attack Energy Cost',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Lycaon_Dodge_1: {
    name: 'Dodge: Suitable Positioning',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Lycaon_Dodge_2: {
    name: 'Dash Attack: Keep it Clean',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nSlide forwards, launching a series of strikes in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 47.3,
        dmg_growth: 4.3,
        stun_base: 23.7,
        stun_growth: 1.1
      }
    ]
  },
  Lycaon_Dodge_3: {
    name: 'Dodge Counter: Etiquette Manual',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nStrikes enemies in front, dealing Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 181,
        dmg_growth: 17,
        stun_base: 168.1,
        stun_growth: 7.7
      }
    ]
  },
  Lycaon_Chain_1: {
    name: 'Chain Attack: As You Wish',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nUnleashes a series of powerful strikes at enemies in a small area in front of him, dealing massive Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 637.8,
        dmg_growth: 58,
        stun_base: 218.8,
        stun_growth: 10
      }
    ]
  },
  Lycaon_Ultimate_1: {
    name: 'Ultimate: Mission Complete',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nUnleashes multiple powerful strikes at enemies in a large area, dealing massive Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1694.1,
        dmg_growth: 154.1,
        stun_base: 1096.6,
        stun_growth: 49.9
      }
    ]
  },
  Lycaon_Recover_1: {
    name: 'Quick Assist: Wolf Pack',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nStrikes enemies in front, dealing Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 63.1,
        dmg_growth: 5.8,
        stun_base: 63.1,
        stun_growth: 2.9
      }
    ]
  },
  Lycaon_Parry_1: {
    name: 'Defensive Assist: Disrupted Hunt',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 259,
        stun_growth: 11.8
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 327.3,
        stun_growth: 14.9
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 159.3,
        stun_growth: 7.2
      }
    ]
  },
  Lycaon_Assist_1: {
    name: 'Assist Follow-Up: Vengeful Counterattack',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCreates an icicle in front, which strikes all enemies within range, dealing Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 288.3,
        dmg_growth: 26.3,
        stun_base: 246.8,
        stun_growth: 11.3
      }
    ]
  },
  Ellen_Basic_1: {
    name: 'Basic Attack: Saw Teeth Trimming',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 3 slashes, dealing Physical DMG.\nPress repeatedly or hold <IconMap:Icon_Normal> during the 3rd attack to extend the skill duration.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 48.8,
        dmg_growth: 4.5,
        stun_base: 24.4,
        stun_growth: 1.2
      },
      {
        name: '2nd-Hit',
        dmg_base: 111.1,
        dmg_growth: 10.1,
        stun_base: 86.8,
        stun_growth: 4
      },
      {
        name: '3rd-Hit',
        dmg_base: 297.3,
        dmg_growth: 27.1,
        stun_base: 240.4,
        stun_growth: 11
      }
    ]
  },
  Ellen_Basic_2: {
    name: 'Basic Attack: Flash Freeze Trimming',
    description:
      'While Flash Freeze is in effect, press <IconMap:Icon_Normal> to activate.\nLaunch up to 3 attacks at enemies in front, dealing Ice DMG.\nEach attack consumes 1 Flash Freeze Charge.\nPress repeatedly or hold <IconMap:Icon_Normal> during the 3rd attack to extend the skill duration.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 99.6,
        dmg_growth: 9.1,
        stun_base: 48.8,
        stun_growth: 2.3
      },
      {
        name: '2nd-Hit',
        dmg_base: 184,
        dmg_growth: 16.8,
        stun_base: 90.2,
        stun_growth: 4.1
      },
      {
        name: '3rd-Hit',
        dmg_base: 496.2,
        dmg_growth: 45.2,
        stun_base: 245.5,
        stun_growth: 11.2
      }
    ]
  },
  Ellen_Skill_1: {
    name: 'Special Attack: Drift',
    description:
      'Press <IconMap:Icon_Special> to activate.\nUse a quick tail sweep attack in front, dealing Ice DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 50.5,
        dmg_growth: 4.6,
        stun_base: 50.8,
        stun_growth: 2
      }
    ]
  },
  Ellen_Skill_2: {
    name: 'EX Special Attack: Tail Swipe',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate.\nPerform a forceful tail whip attack that deals massive Ice DMG.\nGain 1 Flash Freeze Charge upon striking an enemy.\nAfter executing the move, you can directly follow up with the 3rd-hit of a Basic Attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 377.2,
        dmg_growth: 34.3,
        stun_base: 405.1,
        stun_growth: 18.5
      },
      {
        name: 'Energy Cost',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Ellen_Skill_3: {
    name: 'EX Special Attack: Sharknami',
    description:
      'After activating EX Special Attack: Tail Swipe, with enough Energy, press <IconMap:Icon_SpecialReady> to activate:\nUnleash a series of powerful slashes forward, dealing massive Ice DMG.\nGain 1 Flash Freeze Charge upon hitting an enemy.\nAfter executing the move, you can directly follow up with the 3rd-hit of a Basic Attack.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 553.3,
        dmg_growth: 50.3,
        stun_base: 371.7,
        stun_growth: 16.9
      }
    ]
  },
  Ellen_Dodge_1: {
    name: 'Dodge: Vortex',
    description:
      'Press <IconMap:Icon_Evade> to activate.\nA rapid dash dodge.\nCharacter is invulnerable while using this skill.\nWhen Perfect Dodge is triggered, Ellen gains the Quick Charge effect, reducing the charge time of Dash Attack: Arctic Ambush, lasting up to 5 seconds or until it triggers once.',
    multipliers: []
  },
  Ellen_Dodge_1_1: {
    name: 'Dash: Roaming Hunt',
    description:
      "Hold or double-tap <IconMap:Icon_Evade> to activate.\nEnter the Roaming state, allowing dashing movement.\nIn the Roaming state, drag the joystick to control Ellen's direction, and release to stop.\nEllen is invulnerable for a brief time after activation.",
    multipliers: []
  },
  Ellen_Dodge_1_2: {
    name: 'Flash Freeze',
    description:
      'When Ellen has Flash Freeze Charges, the Flash Freeze effect is activated. Once Flash Freeze activates, Basic Attacks and Dash Attacks expend Flash Freeze Charges, dealing massive Ice DMG. Ellen can have up to a maximum of 6 Flash Freeze Charges.',
    multipliers: []
  },
  Ellen_Dodge_2: {
    name: 'Dash Attack: Arctic Ambush',
    description:
      'While in the Roaming state, press <IconMap:Icon_Normal> to activate.\nDelivers a spinning slash to enemies before opening her scissors for a Swift Scissor strike, dealing Ice DMG.\nGain 1 point of Flash Freeze Charge when the Swift Scissor strike hits.\n\nWhile in the Roaming state, hold <IconMap:Icon_Normal> to activate.\nPerform a spinning slash against the enemy, then pull apart the scissors to unleash a Charged Scissor strike, dealing massive Ice DMG.\nGain 3 points of Flash Freeze Charge when the Charged Scissor attack hits. If the enemy is Frozen, it always triggers Shatter.\nAnti-Interrupt level is increased while charging the skill, and DMG taken is reduced by 40%.\nEllen becomes briefly invulnerable during the skill.',
    multipliers: [
      {
        name: 'Spinning Slash',
        dmg_base: 62.3,
        dmg_growth: 5.7,
        stun_base: 62.3,
        stun_growth: 2.9
      },
      {
        name: 'Swift Scissors',
        dmg_base: 127.6,
        dmg_growth: 11.6,
        stun_base: 98.2,
        stun_growth: 4.5
      },
      {
        name: 'Charged Scissors',
        dmg_base: 158.2,
        dmg_growth: 14.4,
        stun_base: 121.7,
        stun_growth: 5.6
      }
    ]
  },
  Ellen_Dodge_3: {
    name: 'Dash Attack: Monstrous Wave',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nLaunch a dashing slash attack at enemies in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 77,
        dmg_growth: 7,
        stun_base: 38.5,
        stun_growth: 1.8
      }
    ]
  },
  Ellen_Dodge_4: {
    name: 'Dash Attack: Cold Snap',
    description:
      'While Flash Freeze is active, press <IconMap:Icon_Normal> during a dodge to activate:\nLaunches a dashing slash attack at enemies in front, dealing Ice DMG.\nConsumes 1 Flash Freeze Charge.',
    multipliers: [
      {
        name: '',
        dmg_base: 145.7,
        dmg_growth: 13.3,
        stun_base: 78.8,
        stun_growth: 3.6
      }
    ]
  },
  Ellen_Dodge_5: {
    name: 'Dodge Counter: Reef Rock',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nRapidly move through enemies with open scissors for a cutting strike, dealing Ice DMG.\nCharacter is invulnerable during the attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 152.6,
        dmg_growth: 13.9,
        stun_base: 227.4,
        stun_growth: 10.4
      }
    ]
  },
  Ellen_Chain_1: {
    name: 'Chain Attack: Avalanche',
    description:
      'When a Chain Attack is triggered, press the character icon to activate.\nCrash down from above and unleash an ice storm, dealing massive Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 794.6,
        dmg_growth: 72.3,
        stun_base: 355.7,
        stun_growth: 16.2
      }
    ]
  },
  Ellen_Ultimate_1: {
    name: 'Ultimate: Endless Winter',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nCrash down from above and unleash an ice storm before a powerful scissor strike, dealing massive Ice DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1890.8,
        dmg_growth: 171.9,
        stun_base: 185.2,
        stun_growth: 8.5
      }
    ]
  },
  Ellen_Recover_1: {
    name: 'Quick Assist: Shark Sentinel',
    description:
      'When the character on the field is knocked back, press <IconMap:Icon_Switch> to activate.\nSpeedily move through the enemy with open scissors for a cutting strike, dealing Ice damage.\nCharacter is invulnerable while using this skill.\nWhen the character on the field is launched, drag <IconMap:Icon_JoyStick> and press <IconMap:Icon_Switch> to activate.\nEnter the Roaming state, enabling dashing movement.\nWhile in the Roaming state, use the joystick to change directions and release to stop.\nCharacter is invulnerable briefly when triggering the skill.\nUpon triggering a Quick Assist, Ellen gains the Quick Charge effect, shortening the charging time of Dash Attack: Arctic Ambush, lasting up to 5s or until it triggers once.',
    multipliers: [
      {
        name: '',
        dmg_base: 121.1,
        dmg_growth: 11.1,
        stun_base: 121.1,
        stun_growth: 5.5
      }
    ]
  },
  Ellen_Parry_1: {
    name: 'Defensive Assist: Wavefront Impact',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 271.3,
        stun_growth: 12.4
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 342.8,
        stun_growth: 15.6
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 166.8,
        stun_growth: 7.6
      }
    ]
  },
  Ellen_Assist_1: {
    name: 'Assist Follow-Up: Shark Cruiser',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCharge and slash enemies in front, dealing Ice DMG.\nPress repeatedly or hold <IconMap:Icon_Normal> during the attack to extend the skill duration.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 437.9,
        dmg_growth: 39.9,
        stun_base: 384.8,
        stun_growth: 17.4
      }
    ]
  },
  Grace_Basic_1: {
    name: 'Basic Attack: High-Pressure Spike',
    description:
      'Press <IconMap:Icon_Normal> to activate.\nUnleashes up to 4 attacks, dealing Physical DMG and Electric DMG.\nWhile launching Basic Attacks, drag the joystick to attack while moving. This allows Grace to reposition herself and deals Physical DMG.\nWhile Grace is launching Basic Attacks, her Basic Attack combo will not be interrupted by her Special Attacks, EX Special Attacks, Dodge, Dash Attacks, or Dodge Counter.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 55.1,
        dmg_growth: 5.1,
        stun_base: 18,
        stun_growth: 0.9
      },
      {
        name: '2nd-Hit',
        dmg_base: 59.7,
        dmg_growth: 5.5,
        stun_base: 34.7,
        stun_growth: 1.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 124.8,
        dmg_growth: 11.4,
        stun_base: 71.6,
        stun_growth: 3.3
      },
      {
        name: '4th-Hit',
        dmg_base: 186.3,
        dmg_growth: 17,
        stun_base: 107.2,
        stun_growth: 4.9
      },
      {
        name: 'Moving Shot',
        dmg_base: 40.3,
        dmg_growth: 3.7,
        stun_base: 40.3,
        stun_growth: 1.9
      }
    ]
  },
  Grace_Skill_1: {
    name: 'Special Attack: Obstruction Removal',
    description:
      'Press <IconMap:Icon_Special> to activate.\nThrows a grenade to the front, dealing Electric DMG.\nUpon use, drag the joystick to change directions.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 42.1,
        dmg_growth: 3.9,
        stun_base: 42.1,
        stun_growth: 2
      }
    ]
  },
  Grace_Skill_2: {
    name: 'EX Special Attack: Supercharged Obstruction Removal',
    description:
      'With enough Energy, press <IconMap:Icon_Special> to activate: \nLeap in the air and throw two grenades to the front, dealing massive Electric DMG.\nUpon use, drag the joystick to change directions.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 166.9,
        dmg_growth: 15.2,
        stun_base: 134.2,
        stun_growth: 6.1,
        static_multi: 2
      },
      {
        name: 'Energy Cost',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Grace_Dodge_1: {
    name: 'Dodge: Safety Regulation',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Grace_Dodge_2: {
    name: 'Dash Attack: Quick Inspection',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nSlide forward while shooting, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 33.3,
        dmg_growth: 3.1,
        stun_base: 16.7,
        stun_growth: 0.8
      }
    ]
  },
  Grace_Dodge_3: {
    name: 'Dodge Counter: Violation Penalty',
    description:
      'Press <IconMap:Icon_Normal> during a perfect dodge to activate.\nThrows a grenade at enemies, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 164.2,
        dmg_growth: 15,
        stun_base: 150.5,
        stun_growth: 6.9
      }
    ]
  },
  Grace_Chain_1: {
    name: 'Chain Attack: Collaborative Construction',
    description:
      'When a Chain Attack is triggered, select the corresponding character to activate.\nThrows three grenades into the air then strafes to detonate them, dealing massive Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 571.3,
        dmg_growth: 52,
        stun_base: 152.3,
        stun_growth: 7
      }
    ]
  },
  Grace_Ultimate_1: {
    name: 'Ultimate: Demolition Blast - Beware',
    description:
      'When Decibel Rating is at Maximum press <IconMap:Icon_UltimateReady> to activate.\nThrows a custom grenade which explodes in the air and splits into four smaller grenades, dealing massive Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1478.8,
        dmg_growth: 134.4,
        stun_base: 133.1,
        stun_growth: 6.1
      }
    ]
  },
  Grace_Recover_1: {
    name: 'Quick Assist: Incident Management',
    description:
      'When the on-field character is launched in the air, press <IconMap:Icon_Switch> to activate.\nThrows a grenade at the enemy, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 45.5,
        dmg_growth: 4.2,
        stun_base: 45.5,
        stun_growth: 2.1
      }
    ]
  },
  Grace_Parry_1: {
    name: 'Evasive Assist: Rapid Risk Response',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nDodges the enemy's attack and activates the Vital View.\nCharacter is invulnerable while using this skill.",
    multipliers: []
  },
  Grace_Assist_1: {
    name: 'Assist Follow-Up: Counter Volt Needle',
    description:
      'Press <IconMap:Icon_Special> after an Evasive Assist to activate.\nUnleashes a spinning hail of bullets against enemies in front and throws a grenade, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 359.2,
        dmg_growth: 32.7,
        stun_base: 312.8,
        stun_growth: 14.3
      }
    ]
  },
  Rina_Basic_1: {
    name: 'Basic Attack: Whack the Dimwit',
    description:
      "Press <IconMap:Icon_Normal> to activate.\nOrder Drusilla and Anastella to attack up to 4 times, dealing Physical DMG and Electric DMG.\nPerforming other actions during Drusilla and Anastella's attacks will not interrupt Rina's Basic Attack combo count.",
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 44,
        dmg_growth: 4,
        stun_base: 24.5,
        stun_growth: 1.2
      },
      {
        name: '2nd-Hit',
        dmg_base: 111.4,
        dmg_growth: 10.2,
        stun_base: 88.4,
        stun_growth: 4.1
      },
      {
        name: '3rd-Hit',
        dmg_base: 117.1,
        dmg_growth: 10.7,
        stun_base: 96.6,
        stun_growth: 4.4
      },
      {
        name: '4th-Hit',
        dmg_base: 183.9,
        dmg_growth: 16.8,
        stun_base: 146.8,
        stun_growth: 6.7
      }
    ]
  },
  Rina_Basic_2: {
    name: 'Basic Attack: Shoo the Fool',
    description:
      'Hold <IconMap:Icon_Normal> or <IconMap:Icon_Special> to activate.\nCalls back Drusilla and Anastella to swing them around for a ranged attack, dealing Electric DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 315.1,
        dmg_growth: 28.7,
        stun_base: 315.1,
        stun_growth: 14.4
      }
    ]
  },
  Rina_Skill_1: {
    name: 'Special Attack: Beat the Blockhead',
    description:
      'Press <IconMap:Icon_Special> to activate.\nOrder Anastella to execute a downward smash, dealing Electric DMG. \nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 61.3,
        dmg_growth: 5.6,
        stun_base: 61.3,
        stun_growth: 2.8
      }
    ]
  },
  Rina_Skill_2: {
    name: 'EX Special Attack: Dimwit Disappearing Trick',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate:\nOrder Anastella to execute multiple downward smashes, dealing massive Electric DMG.\nCharacter is invulnerable while using this skill.\nA Quick Assist is triggered when this skill hits an enemy.',
    multipliers: [
      {
        name: '',
        dmg_base: 546,
        dmg_growth: 49.7,
        stun_base: 444.5,
        stun_growth: 20.3
      },
      {
        name: 'Energy Cost',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Rina_Dodge_1: {
    name: 'Dodge: Dress Adjustment',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Rina_Dodge_2: {
    name: 'Dash Attack: Sudden Surprise',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nOrder Drusilla and Anastella to execute a piercing attack, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 105,
        dmg_growth: 9.6,
        stun_base: 52.5,
        stun_growth: 2.4
      }
    ]
  },
  Rina_Dodge_3: {
    name: 'Dodge Counter: Bangboo Callback',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nOrder Drusilla and Anastella to spin forward and trigger an explosion, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 227.6,
        dmg_growth: 20.7,
        stun_base: 227.6,
        stun_growth: 10.4
      }
    ]
  },
  Rina_Chain_1: {
    name: 'Chain Attack: Code of Conduct',
    description:
      "When Chain Attack is triggered, select the character to activate.\nOrder Drusilla and Anastella push forward and generate a ball-shaped electrical field that deals massive Electric DMG.\nDuring this skill, Drusilla and Anastella are unable to perform Basic Attacks, Dash Attacks, Special Attacks, EX Special Attacks, but can be recalled to Rina's side through Basic Attack: Shoo the Fool.\nCharacter is invulnerable while using this skill.\nA Quick Assist is triggered when this skill hits an enemy.",
    multipliers: [
      {
        name: '',
        dmg_base: 1013,
        dmg_growth: 92.1,
        stun_base: 175.1,
        stun_growth: 8
      }
    ]
  },
  Rina_Ultimate_1: {
    name: "Ultimate: The Queen's Attendant",
    description:
      "When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate:\nOrder Drusilla and Anastella to spin forward,dealing massive Electric DMG.\nDuring this skill, Drusilla and Anastella are unable to respond to Basic Attack, Dash Attack, Special Attack, EX Special Attack, but can be recalled to Rina's side through Basic Attack: Shoo the Fool.\nUpon activating this skill, the other squad members recover 10 Energy. The next character to switch in recovers an additional 20 energy.\nCharacter is invulnerable while using this skill.\nA Quick Assist is triggered when this skill hits an enemy.",
    multipliers: [
      {
        name: '',
        dmg_base: 2116.7,
        dmg_growth: 192.5,
        stun_base: 113.8,
        stun_growth: 5.2
      }
    ]
  },
  Rina_Recover_1: {
    name: 'Quick Assist: Duple Meter Allemande',
    description:
      'When the character on field is launched, press <IconMap:Icon_Switch> to activate.\nOrder Drusilla and Anastella to spin forward and trigger an explosion, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 122.6,
        dmg_growth: 11.2,
        stun_base: 122.6,
        stun_growth: 5.6
      }
    ]
  },
  Rina_Parry_1: {
    name: 'Evasive Assist: Tripple Meter Courante',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nDodges the enemy's attack and activates the Vital View.\nCharacter is invulnerable while using this skill.",
    multipliers: []
  },
  Rina_Assist_1: {
    name: 'Assist Follow-Up: Quadruple Meter Gavotte',
    description:
      'Press <IconMap:Icon_Special> after an Evasive Assist to trigger.\nOrder Drusilla and Anastella to push forward and generate an electrical vortex, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 349.4,
        dmg_growth: 31.8,
        stun_base: 303.6,
        stun_growth: 13.8
      }
    ]
  },
  Zhu_Basic_1: {
    name: "Basic Attack: Don't Move!",
    description:
      '[Assault Mode]\nPress <IconMap:Icon_Normal> to activate:\nUnleash a flurry of martial arts, bullets, and Ether Buckshots in a series of up to 5 attacks, dealing Physical DMG and Ether DMG. \nWhen the 4th or 5th hits strike an enemy, obtain 1 Enhanced Shotshell. A max of 1 can be obtained per skill use.\nWhile using this skill, drag the stick/joystick and press <IconMap:Icon_Normal> to activate Dash Attack: Firepower Offensive and readjust to face the selected direction.\nWhile under Assault Mode, hold <IconMap:Icon_Normal> to switch to Suppressive Mode.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 43.1,
        dmg_growth: 4,
        stun_base: 21.6,
        stun_growth: 1
      },
      {
        name: '2nd-Hit',
        dmg_base: 126.4,
        dmg_growth: 11.5,
        stun_base: 97.3,
        stun_growth: 4.5
      },
      {
        name: '3rd-Hit',
        dmg_base: 137.3,
        dmg_growth: 12.5,
        stun_base: 94.7,
        stun_growth: 4.4
      },
      {
        name: '4th-Hit',
        dmg_base: 151,
        dmg_growth: 13.8,
        stun_base: 124.3,
        stun_growth: 5.7
      },
      {
        name: '5th-Hit',
        dmg_base: 162.2,
        dmg_growth: 14.8,
        stun_base: 139.2,
        stun_growth: 6.4
      }
    ]
  },
  Zhu_Basic_2: {
    name: 'Basic Attack: Please Do Not Resist',
    description:
      '[Suppressive Mode]\nHold  <IconMap:Icon_Normal> to activate.\nUnleash up to 3 Shotshell piercing attacks, dealing Physical DMG. If Zhu Yuan has Enhanced Shotshells upon firing, 1 Enhanced Shotshell will be consumed, dealing massive Ether DMG.\nWhile using this skill, drag the stick/joystick to activate her Dash Attack: Overwhelming Firepower and readjust to face the selected direction.\nWhile in Suppressive Mode, release <IconMap:Icon_Normal> to return to Assault Mode.',
    multipliers: [
      {
        name: '1st-Hit (Physical)',
        dmg_base: 53.7,
        dmg_growth: 4.9,
        stun_base: 59.6,
        stun_growth: 2.8
      },
      {
        name: '2nd-Hit (Physical)',
        dmg_base: 53.7,
        dmg_growth: 4.9,
        stun_base: 59.6,
        stun_growth: 2.8
      },
      {
        name: '3rd-Hit (Physical)',
        dmg_base: 160.9,
        dmg_growth: 14.7,
        stun_base: 178.8,
        stun_growth: 8.2
      },
      {
        name: '1st-Hit (Ether)',
        dmg_base: 135.9,
        dmg_growth: 12.4,
        stun_base: 59.6,
        stun_growth: 2.8
      },
      {
        name: '2nd-Hit (Ether)',
        dmg_base: 135.9,
        dmg_growth: 12.4,
        stun_base: 59.6,
        stun_growth: 2.8
      },
      {
        name: '3rd-Hit (Ether)',
        dmg_base: 407.7,
        dmg_growth: 37.7,
        stun_base: 178.7,
        stun_growth: 8.2
      }
    ]
  },
  Zhu_Skill_1: {
    name: 'Special Attack: Buckshot Blast',
    description:
      'Press <IconMap:Icon_Special> to activate:\nShoots Ether Buckshots that deal Ether DMG. This can be repeated 3 times by repeatedly pressing <IconMap:Icon_Special>.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 18.4,
        dmg_growth: 1.7,
        stun_base: 18.4,
        stun_growth: 0.9
      }
    ]
  },
  Zhu_Skill_2: {
    name: 'EX Special Attack: Full Barrage',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate:\nShoots a series of Ether Buckshots, that explode and deal massive Ether DMG.\nUpon activation, gain 3 Enhanced Shotshells.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 587.4,
        dmg_growth: 53.4,
        stun_base: 480,
        stun_growth: 21.9
      },
      {
        name: 'Energy Cost',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Zhu_Dodge_1: {
    name: 'Dodge: Tactical Detour',
    description:
      'Press <IconMap:Icon_Evade> to activate:.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Zhu_Dodge_2: {
    name: 'Dash Attack: Firepower Offensive',
    description:
      '[Assault Mode]\nPress <IconMap:Icon_Normal> during a dodge to activate:\nUses handgun to attack, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 55.1,
        dmg_growth: 5.1,
        stun_base: 27.6,
        stun_growth: 1.3
      }
    ]
  },
  Zhu_Dodge_3: {
    name: 'Dodge Counter: Fire Blast ',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nShoots enemies in front with an Ether Buckshot, dealing Ether DMG.\nGain 1 Enhanced Shotshell on skill use.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 176.8,
        dmg_growth: 16.1,
        stun_base: 161.4,
        stun_growth: 7.4
      }
    ]
  },
  Zhu_Dodge_4: {
    name: 'Dash Attack: Overwhelming Firepower',
    description:
      'Suppressive Mode\nDrag the joystick/joystickduring Basic Attack: Please Do Not Resist to activate:\nUnleashes a Shotshell piercing attack, dealing Physical DMG. If Zhu Yuan has Enhanced Shotshells when firing, 1 Enhanced Shotshell will be consumed, dealing massive Ether DMG.',
    multipliers: [
      {
        name: 'Physical',
        dmg_base: 53.7,
        dmg_growth: 4.9,
        stun_base: 59.6,
        stun_growth: 2.8
      },
      {
        name: 'Ether',
        dmg_base: 135.9,
        dmg_growth: 12.4,
        stun_base: 59.6,
        stun_growth: 2.8
      }
    ]
  },
  Zhu_Chain_1: {
    name: 'Chain Attack: Eradication Mode',
    description:
      'When a Chain Attack is triggered, select the character to activate:\nUses Modified Master Firearm to execute a saturated attack, firing lasers and seeking missiles to deal massive Ether DMG.\nUpon activation, gain 3 Enhanced Shotshells.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 587.5,
        dmg_growth: 53.5,
        stun_base: 148.6,
        stun_growth: 6.8
      }
    ]
  },
  Zhu_Ultimate_1: {
    name: 'Ultimate: Max Eradication Mode',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nUses Modified Master Firearm to execute a saturated attack, firing lasers and seeking missiles to deal massive Ether DMG.\nUpon activation, gain 3 Enhanced Shotshells.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1977.6,
        dmg_growth: 179.8,
        stun_base: 125.1,
        stun_growth: 6.27
      }
    ]
  },
  Zhu_Recover_1: {
    name: 'Quick Assist: Covering Shot',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nShoots enemies in front with an Ether Buckshot, dealing Ether DMG.\nGain 1 Enhanced Shotshell on skill use.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 51.4,
        dmg_growth: 4.7,
        stun_base: 51.4,
        stun_growth: 2.4
      }
    ]
  },
  Zhu_Parry_1: {
    name: 'Evasive Assist: Guarded Backup',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nDodges the enemy's attack and activates the Vital View.\nCharacter is invulnerable while using this skill.",
    multipliers: []
  },
  Zhu_Assist_1: {
    name: 'Assist Follow-Up: Defensive Counter',
    description:
      'Press <IconMap:Icon_Special> after an Evasive Assist to activate.\nAttack with a combination of martial arts and shotshells, dealing Physical DMG and Ether DMG.\nUpon activation, gain 3 Enhanced Shotshells.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 355.8,
        dmg_growth: 71.2,
        stun_base: 308.6,
        stun_growth: 30.9
      }
    ]
  },
  Lucy_Basic_1: {
    name: "Basic Attack: Lady's Bat",
    description:
      'Press <IconMap:Icon_Normal> to activate.\nStrike forward up to 4 times, dealing Physical DMG and Fire DMG. When Lucy uses her fourth Basic Attack, the currently on-field guard boars will launch Guard Boars: Spinning Swing! together.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 56.6,
        dmg_growth: 5.2,
        stun_base: 28.3,
        stun_growth: 1.6
      },
      {
        name: '2nd-Hit',
        dmg_base: 77.8,
        dmg_growth: 7.1,
        stun_base: 65,
        stun_growth: 3
      },
      {
        name: '3rd-Hit',
        dmg_base: 188.9,
        dmg_growth: 17.2,
        stun_base: 157.4,
        stun_growth: 7.2
      },
      {
        name: '4th-Hit',
        dmg_base: 272.6,
        dmg_growth: 24.8,
        stun_base: 208,
        stun_growth: 9.5
      }
    ]
  },
  Lucy_Basic_2: {
    name: 'Guard Boars: To Arms!',
    description:
      'In combat, the guard boars will randomly use a baseball bat, boxing gloves, or a slingshot to automatically attack enemies, dealing Physical DMG.',
    multipliers: [
      {
        name: 'Baseball Bat',
        dmg_base: 92.5,
        dmg_growth: 8.5,
        stun_base: 15.5,
        stun_growth: 0.8
      },
      {
        name: 'Boxing Gloves',
        dmg_base: 127.5,
        dmg_growth: 11.6,
        stun_base: 21.3,
        stun_growth: 1
      },
      {
        name: 'Slingshot',
        dmg_base: 175,
        dmg_growth: 16,
        stun_base: 29.2,
        stun_growth: 1.4
      }
    ]
  },
  Lucy_Basic_3: {
    name: 'Guard Boars: Spinning Swing!',
    description:
      'When Lucy uses the fourth Basic Attack, or when she gains the Cheer On! status, the guard boars will whirl their baseball bats in a continuous motion, dealing Physical DMG to nearby enemies. If the attack cannot be executed immediately, the guard boars will remember the required number of times to execute it.',
    multipliers: [
      {
        name: 'Spinning Swing',
        dmg_base: 250,
        dmg_growth: 22.8,
        stun_base: 20,
        stun_growth: 1
      }
    ]
  },
  Lucy_Skill_1: {
    name: 'Special Attack: Solid Hit!',
    description:
      'Press <IconMap:Icon_Special> to activate:\nRecall a guard boar and launch it as a Line Drive. The guard boar will cause an explosion upon hitting enemies, or walls, dealing Fire DMG.\nHold <IconMap:Icon_Special> to activate:\nRecall a guard boar and launch it as a Fly Ball. The guard boar will cause an explosion upon landing, dealing Fire DMG.',
    multipliers: [
      {
        name: 'Line Drive',
        dmg_base: 61.7,
        dmg_growth: 5.7,
        stun_base: 61.7,
        stun_growth: 3.9
      },
      {
        name: 'Fly Ball',
        dmg_base: 69.2,
        dmg_growth: 6.3,
        stun_base: 69.2,
        stun_growth: 3.2
      }
    ]
  },
  Lucy_Skill_2: {
    name: 'EX Special Attack: Home Run!',
    description:
      'When enough energy, press <IconMap:Icon_SpecialReady> to activate.\nRecall a guard boar and launch it as a Line Drive. The guard boar will cause an explosion upon hitting enemies, or walls, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.\nWhen enough energy, hold <IconMap:Icon_SpecialReady> to activate:\nRecall a guard boar and launch it as a Fly Ball. The guard boar will cause an explosion upon landing, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.\nWhen the skill hits an enemy, it triggers a Quick Assist.',
    multipliers: [
      {
        name: 'Line Drive',
        dmg_base: 508.4,
        dmg_growth: 46.3,
        stun_base: 364.6,
        stun_growth: 16.6
      },
      {
        name: 'Fly Ball',
        dmg_base: 536.4,
        dmg_growth: 48.8,
        stun_base: 389.6,
        stun_growth: 17.8
      },
      {
        name: 'Energy Cost',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Lucy_Dodge_1: {
    name: 'Dodge: Foul Ball!',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Lucy_Dodge_2: {
    name: 'Dash Attack: Fearless Boar!',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nRapidly strikes enemies in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 78.4,
        dmg_growth: 7.2,
        stun_base: 39.2,
        stun_growth: 1.8
      }
    ]
  },
  Lucy_Dodge_3: {
    name: 'Dodge Counter: Returning Tusk!',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nStrike enemies in front, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 308,
        dmg_growth: 28,
        stun_base: 560,
        stun_growth: 11.9
      }
    ]
  },
  Lucy_Chain_1: {
    name: 'Chain Attack: Grand Slam!',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nLucy jumps up and sends a guard boar at enemies in front in a large area, dealing massive Fire DMG.\nCharacter is invulnerable while using this skill.\nWhen the skill hits an enemy, it triggers a Quick Assist.',
    multipliers: [
      {
        name: '',
        dmg_base: 161.1,
        dmg_growth: 14.9,
        stun_base: 31.1,
        stun_growth: 1.4,
        static_multi: 3
      }
    ]
  },
  Lucy_Ultimate_1: {
    name: 'Ultimate: Walk-Off Home Run!',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nLaunch all guard boards sequentially, dealing massive Fire DMG in a large area to enemies ahead.\nWhile using this skill, other squad members recover 10 energy, and the next character to switch in gains an additional 20 energy.\nCharacter is invulnerable while using this skill.\nWhen the skill hits an enemy, it triggers a Quick Assist.',
    multipliers: [
      {
        name: '',
        dmg_base: 572.8,
        dmg_growth: 52.1,
        stun_base: 94.5,
        stun_growth: 4.3,
        static_multi: 3
      }
    ]
  },
  Lucy_Recover_1: {
    name: 'Quick Assist: Hit By Pitch!',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nStrikes enemies in front, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 160,
        dmg_growth: 14.6,
        stun_base: 160,
        stun_growth: 7.3
      }
    ]
  },
  Lucy_Parry_1: {
    name: 'Defensive Assist: Safe on Base!',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, dealing massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 207.7,
        stun_growth: 9.5
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 229.4,
        stun_growth: 10.5
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 69.4,
        stun_growth: 3.2
      }
    ]
  },
  Lucy_Assist_1: {
    name: 'Assist Follow-Up: Scored a Run!',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nStrikes enemies in front, dealing Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 349.1,
        dmg_growth: 31.8,
        stun_base: 304.3,
        stun_growth: 13.9
      }
    ]
  },
  Piper_Basic_1: {
    name: 'Basic Attack: Load Up and Roll Out',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nUnleashes up to 4 slashes, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 59,
        dmg_growth: 5.4,
        stun_base: 29.5,
        stun_growth: 1.4
      },
      {
        name: '2nd-Hit',
        dmg_base: 78.8,
        dmg_growth: 7.2,
        stun_base: 64.5,
        stun_growth: 3
      },
      {
        name: '3rd-Hit',
        dmg_base: 150.1,
        dmg_growth: 13.7,
        stun_base: 121.9,
        stun_growth: 5.6
      },
      {
        name: '4th-Hit',
        dmg_base: 320.6,
        dmg_growth: 29.2,
        stun_base: 252.2,
        stun_growth: 11.5
      }
    ]
  },
  Piper_Skill_1: {
    name: 'Special Attack: Tire Spin',
    description:
      'Press <IconMap:Icon_Special> to activate.\nPiper spins and launches a sweeping slash around her, dealing Physical DMG.\nHold <IconMap:Icon_Special> to extend the skill duration, unleashing increasingly powerful spinning slashes and increasing the charge level of the skill, up to 2 times.\nIf the skill is used after the first 3 hits of a Basic Attack, Dash Attack, or Dodge Counter, you can quickly activate Special Attack: Tire Spin of an increased charged level.\nDuring the skill activation, Anti-Interrupt level increases, and DMG taken is reduced by 40%.',
    multipliers: [
      {
        name: '',
        dmg_base: 271.7,
        dmg_growth: 23.7,
        stun_base: 271.7,
        stun_growth: 12.4
      }
    ]
  },
  Piper_Skill_2: {
    name: 'EX Special Attack: Engine Spin',
    description:
      'With enough energy, continue holding <IconMap:Icon_SpecialReady> after Special Attack: Tire Spin reaches maximum charge level to activate:\nRapidly spins, unleashing powerful spinning slashes around her, dealing massive Physical DMG.\nThis skill consumes Energy continuously for up to 3s.\nDuring the skill activation, Anti-Interrupt level increases, and DMG taken is reduced by 40%.',
    multipliers: [
      {
        name: '',
        dmg_base: 93.5,
        dmg_growth: 8.6,
        stun_base: 54.8,
        stun_growth: 2.5
      },
      {
        name: 'Energy Cost per second',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Piper_Skill_3: {
    name: 'Special Attack: One Trillion Tons',
    description:
      'While Special Attack: Tire Spin is active, release <IconMap:Icon_Special> to trigger a downward smash attack based on the charge level of Special Attack: Tire Spin, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: 'Level-1 Charged Attack',
        dmg_base: 93.4,
        dmg_growth: 8.5,
        stun_base: 93.4,
        stun_growth: 4.4
      },
      {
        name: 'Level-2 Charged Attack',
        dmg_base: 103.4,
        dmg_growth: 9.4,
        stun_base: 103.4,
        stun_growth: 4.7
      },
      {
        name: 'Level-3 Charged Attack',
        dmg_base: 235,
        dmg_growth: 21.4,
        stun_base: 235,
        stun_growth: 10.7
      }
    ]
  },
  Piper_Skill_4: {
    name: 'EX Special Attack: Really Heavy',
    description:
      'During EX Special Attack: Engine Spin, release <IconMap:Icon_SpecialReady> to activate:\nPiper unleashes a downward smash attack, dealing massive Physical DMG.\nIf the duration of EX Special Attack: Engine Spin reaches its limit or Energy drops to 20, EX Special Attack: Really Heavy will automatically activate.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 612.9,
        dmg_growth: 55.8,
        stun_base: 396.8,
        stun_growth: 18.1
      },
      {
        name: 'Energy Cost',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Piper_Dodge_1: {
    name: 'Dodge: Handbrake Drift',
    description:
      'Press <IconMap:Icon_Evade> to dodge.\nA rapid dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Piper_Dodge_2: {
    name: 'Dash Attack: Pedal to the Metal',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nSlashes enemies in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 90.1,
        dmg_growth: 8.2,
        stun_base: 45.1,
        stun_growth: 2.1
      }
    ]
  },
  Piper_Dodge_3: {
    name: 'Dodge Counter: Power Drift',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nUnleash a downward smash attack in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 269,
        dmg_growth: 24.5,
        stun_base: 230,
        stun_growth: 10.5
      }
    ]
  },
  Piper_Chain_1: {
    name: 'Chain Attack: Buckle Up',
    description:
      'When a Chain Attack is triggered, select the character to activate.\nUnleashes a powerful downward smash at enemies ahead in a large area, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 652.3,
        dmg_growth: 59.3,
        stun_base: 253.3,
        stun_growth: 11.6
      }
    ]
  },
  Piper_Ultimate_1: {
    name: 'Ultimate: Hold On Tight',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nRapidly spins, unleashing powerful spinning slashes at enemies in a large area ahead, followed by a downward smash, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1660.4,
        dmg_growth: 151,
        stun_base: 328.3,
        stun_growth: 15
      }
    ]
  },
  Piper_Recover_1: {
    name: 'Quick Assist: Brake Tap',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nLaunches a downward smash attack at enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 130,
        dmg_growth: 11.9,
        stun_base: 130,
        stun_growth: 6
      }
    ]
  },
  Piper_Parry_1: {
    name: 'Defensive Assist: Emergency Brake',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\nThis skill excels at parrying enemy attacks and reduces the consumption of Assist Points when the character is under intense attacks.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 246.7,
        stun_growth: 11.3
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 311.7,
        stun_growth: 14.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 151.7,
        stun_growth: 6.9
      }
    ]
  },
  Piper_Assist_1: {
    name: 'Assist Follow-Up: Overtaking Maneuver',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nCharge and slash enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 400.5,
        dmg_growth: 36.5,
        stun_base: 352,
        stun_growth: 14
      }
    ]
  },
  Qingyi_Basic_1: {
    name: 'Basic Attack: Penultimate',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nLaunch up to four attacks forward, dealing Physical and Electric DMG.\nDuring the 3rd-hit, repeatedly press <IconMap:Icon_Normal> to continue the attack, continuously striking the enemy. Using Dodge during this period will not interrupt Qingyis Basic Attack combo count.\nDuring the 3rd hit, stop pressing <IconMap:Icon_Normal> to interrupt the continuous attack, transitioning automatically to the 4th hit. If the continuous attack lasts for a certain time, the 4th hit will be enhanced.\nAnti-Interrupt Level is increased and DMG received is reduced by 40% during the 3rd and 4th hit.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 47.2,
        dmg_growth: 4.3,
        stun_base: 23.6,
        stun_growth: 1.1
      },
      {
        name: '1st-Hit (Alt)',
        dmg_base: 110.3,
        dmg_growth: 10.1,
        stun_base: 55.2,
        stun_growth: 2.6
      },
      {
        name: '2nd-Hit',
        dmg_base: 122.1,
        dmg_growth: 11.1,
        stun_base: 82.2,
        stun_growth: 3.8
      },
      {
        name: '3rd-Hit',
        dmg_base: 17.6,
        dmg_growth: 1.6,
        stun_base: 19,
        stun_growth: 0.9
      },
      {
        name: '4th-Hit',
        dmg_base: 106.4,
        dmg_growth: 9.7,
        stun_base: 106.3,
        stun_growth: 4.9
      },
      {
        name: '4th-Hit (Enhanced)',
        dmg_base: 234.4,
        dmg_growth: 21.4,
        stun_base: 204.6,
        stun_growth: 9.4
      }
    ]
  },
  Qingyi_Basic_2: {
    name: 'Basic Attack: Enchanted Blossoms',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nLaunches a forward rush attack, dealing Electric DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 85.6,
        dmg_growth: 7.8,
        stun_base: 85.6,
        stun_growth: 3.9
      }
    ]
  },
  Qingyi_Basic_3: {
    name: 'Basic Attack: Flash Connect',
    description:
      'When Qingyi deals Electric DMG with a skill, she accumulates Flash Connect Voltage. When Flash Connect Voltage reaches 75%, she will enter the Flash Connect state. When using her Basic Attack: Enchanted Moonlit Blossoms, Qingyi will consume all Flash Connect Voltage and exit the Flash Connect state. For every 1% of Flash Connect Voltage consumed over 75%, her Damage and Daze inflicted by this skill will be increased by 1%/0.5%. Basic Attack: Enchanted Moonlit Blossoms itself does not accumulate Flash Connect Voltage.',
    multipliers: []
  },
  Qingyi_Basic_4: {
    name: 'Basic Attack: Enchanted Moonlit Blossoms',
    description:
      'During the Flash Connect state, hold <IconMap:Icon_Normal> to activate:\nLaunch five consecutive forward rush attacks, then execute a Finishing Move that inflicts massive Electric DMG. Release <IconMap:Icon_Normal> during the move to trigger the Finishing Move early.\nActivate the Finishing Move just before the character is attacked to trigger Perfect Dodge.\nAnti-Interrupt level is increased during the rush attack and DMG received is reduced by 40%. Qingyi is invulnerable during the Finishing Move.',
    multipliers: [
      {
        name: 'Rush Attack',
        dmg_base: 448.7,
        dmg_growth: 40.8,
        stun_base: 271.9,
        stun_growth: 12.4
      },
      {
        name: 'Finishing Strike',
        dmg_base: 394.4,
        dmg_growth: 35.9,
        stun_base: 217.6,
        stun_growth: 9.9
      }
    ]
  },
  Qingyi_Skill_1: {
    name: 'Special Attack: Sunlit Glory',
    description:
      'Press <IconMap:Icon_Special> to activate.\nQuickly strikes upward, followed by a downward smash, dealing Electric DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 62.4,
        dmg_growth: 5.7,
        stun_base: 62.4,
        stun_growth: 2.9
      }
    ]
  },
  Qingyi_Skill_2: {
    name: 'EX Special Attack: Moonlit Begonia',
    description:
      'When Qingyi has enough energy, press <IconMap:Icon_SpecialReady> to activate: \nQuickly strikes upward and leaps into the air, turning around to launch a series of strikes, then follows up with a powerful downward smash, dealing massive Electric DMG.\nHold <IconMap:Icon_SpecialReady> to consume additional energy to increase the combo hits of the series of strikes.\nCharacter is invulnerable while using this skill.\nAfter executing the move, you can directly follow up with the 3rd-hit of a Basic Attack.',
    multipliers: [
      {
        name: '',
        dmg_base: 602.8,
        dmg_growth: 54.9,
        stun_base: 444.3,
        stun_growth: 20.3
      },
      {
        name: 'Energy Cost per second',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Additional Energy Cost per second',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Qingyi_Dodge_1: {
    name: 'Dodge: Swan Song',
    description:
      'Press <IconMap:Icon_Evade> to activate.\nA quick dash dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Qingyi_Dodge_2: {
    name: 'Dash Attack: Breach',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nDash forward and then launch an upward slash, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 49.5,
        dmg_growth: 4.5,
        stun_base: 24.8,
        stun_growth: 1.2
      }
    ]
  },
  Qingyi_Dodge_3: {
    name: 'Dodge Counter: Lingering Sentiments',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nLeap into the air and then perform a downward strike, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 284,
        dmg_growth: 25.9,
        stun_base: 190.4,
        stun_growth: 8.7
      }
    ]
  },
  Qingyi_Chain_1: {
    name: 'Chain Attack: Tranquil Serenade',
    description:
      'When a Chain Attack is triggered, select Qingyi to activate:\nLaunch a series of powerful strikes over a large area in front, dealing massive Electric DMG. \nWhen this attack hits an enemy, the skills DMG increases by 3% for every stack of Subjugation in Core Passive: Eternal Seasons the target has.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 647.9,
        dmg_growth: 58.9,
        stun_base: 209,
        stun_growth: 9.5
      }
    ]
  },
  Qingyi_Ultimate_1: {
    name: 'Ultimate: Eight Sounds of Ganzhou',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nLaunch a series of powerful strikes over a large area in front, followed by a finishing move, dealing massive Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1670.7,
        dmg_growth: 151.9,
        stun_base: 1097.1,
        stun_growth: 49.9
      }
    ]
  },
  Qingyi_Recover_1: {
    name: 'Quick Assist: Wind Through the Pines',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nLeap into the air and perform a downward strike, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 133.9,
        dmg_growth: 12.2,
        stun_base: 133.9,
        stun_growth: 6.1
      }
    ]
  },
  Qingyi_Parry_1: {
    name: 'Defensive Assist: Graceful Embellishment',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 249.3,
        stun_growth: 11.4
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 304.3,
        stun_growth: 13.9
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 128.3,
        stun_growth: 5.9
      }
    ]
  },
  Qingyi_Assist_1: {
    name: 'Assist Follow-Up: Song of the Clear River',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nDash forward quickly and inject electrical energy into the ground, then pull back to trigger an explosion, dealing Electric DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 376.4,
        dmg_growth: 34.3,
        stun_base: 279,
        stun_growth: 12.7
      }
    ]
  },
  Jane_Basic_1: {
    name: 'Basic Attack: Dancing Blades',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nUnleashes up to 6 attacks in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 36.1,
        dmg_growth: 3.3,
        stun_base: 15.3,
        stun_growth: 0.7
      },
      {
        name: '2nd-Hit',
        dmg_base: 62.3,
        dmg_growth: 5.7,
        stun_base: 44.3,
        stun_growth: 3.1
      },
      {
        name: '3rd-Hit',
        dmg_base: 83.5,
        dmg_growth: 7.6,
        stun_base: 59.5,
        stun_growth: 2.8
      },
      {
        name: '4th-Hit',
        dmg_base: 163.4,
        dmg_growth: 14.9,
        stun_base: 109.7,
        stun_growth: 5
      },
      {
        name: '5th-Hit',
        dmg_base: 98.8,
        dmg_growth: 9,
        stun_base: 68.7,
        stun_growth: 3.2
      },
      {
        name: '6th-Hit',
        dmg_base: 291.3,
        dmg_growth: 26.5,
        stun_base: 200,
        stun_growth: 9.1
      }
    ]
  },
  Jane_Basic_2: {
    name: 'Passion',
    description:
      'When Jane triggers Perfect Dodge, Defensive Assist, or performs a move that deals damage to the enemy, she accumulates Passion Stream. Upon reaching the maximum Passion Stream, Jane enters the Passion state.\nWhile in the Passion state, Jane Physical Anomaly Buildup Rate is increased by 25%. Additionally, if Jane Anomaly Proficiency exceeds 120 points, each extra point raises her ATK by 2, up to a maximum of 600.\n In the Passion state, Jane skills that deal damage expend Passion Stream. Activating a Perfect Dodge or Defensive Assist regenerates Passion Stream. Jane exits the Passion state when all of her Passion Stream is expended.',
    multipliers: []
  },
  Jane_Basic_3: {
    name: 'Basic Attack: Salchow Jump',
    description:
      'Entering the Passion state grants Jane one use of Basic Attack: Salchow Jump. When available, hold <IconMap:Icon_Normal> to activate:\nLaunch rapid consecutive attacks forward, followed by a finishing strike, dealing Physical DMG.\nHold the button during the continuous attacks to extend the skill duration. Release <IconMap:Icon_Normal> to trigger the finishing strike early.\nAnti-Interrupt level is increased during the continuous attacks, and Jane takes 40% reduced DMG. Jane is invulnerable during the finishing strike.\nUsing Basic Attack: Salchow Jump while in the Passion state generates Passion Stream.',
    multipliers: [
      {
        name: 'Combo',
        dmg_base: 300.8,
        dmg_growth: 27.4,
        stun_base: 229.2,
        stun_growth: 10.5
      },
      {
        name: 'Finishing Strike',
        dmg_base: 161.3,
        dmg_growth: 14.7,
        stun_base: 122.9,
        stun_growth: 5.6
      }
    ]
  },
  Jane_Skill_1: {
    name: 'Special Attack: Aerial Sweep',
    description:
      'Press <IconMap:Icon_Special> to activate.\nLeaps into the air to launch a series of kicks forward, then sweeps across, dealing Physical DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 57.8,
        dmg_growth: 5.3,
        stun_base: 57.8,
        stun_growth: 2.7
      }
    ]
  },
  Jane_Skill_2: {
    name: 'EX Special Attack: Aerial Sweep - Tail Swipe',
    description:
      'When Jane has enough energy, press <IconMap:Icon_SpecialReady> to activate: \nLeaps into the air to launch a series of kicks forward, then sweeps across, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 574.7,
        dmg_growth: 52.3,
        stun_base: 468.1,
        stun_growth: 21.3
      },
      {
        name: 'Energy Cost per second',
        dmg_base: 60,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Jane_Dodge_1: {
    name: 'Dodge: Phantom',
    description:
      'Press <IconMap:Icon_Evade> to activate.\nA quick dash dodge.\nCharacter is invulnerable while using this skill.\nJane has an extra dodge, alternating between two dodges before entering the Passion state. After entering the Passion state, Jane can pass through enemies in front when dodging.',
    multipliers: []
  },
  Jane_Dodge_2: {
    name: 'Dash Attack: Edge Jump',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nIf after a single dodge, launch an upward slash in front, dealing Physical DMG.\nIf after a second dodge, perform a series of slashes in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 71.5,
        dmg_growth: 6.5,
        stun_base: 35.8,
        stun_growth: 1.7
      },
      {
        name: '2nd-Hit',
        dmg_base: 71.5,
        dmg_growth: 6.5,
        stun_base: 35.8,
        stun_growth: 1.7
      }
    ]
  },
  Jane_Dodge_3: {
    name: 'Dodge Counter: Phantom Thrust',
    description:
      'While in the Passion state, press <IconMap:Icon_Normal> during a dodge to activate:\nLaunch three quick slashes in front, dealing Physical DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 104.5,
        dmg_growth: 9.5,
        stun_base: 52.3,
        stun_growth: 2.4
      }
    ]
  },
  Jane_Dodge_4: {
    name: 'Dodge Counter: Swift Shadow',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nIf after a single dodge, unleash multiple slashes at enemies in front, followed by a downward thrust, dealing Physical DMG.\nIf after a second dodge, leap up and deliver three consecutive attack combos to enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 341.2,
        dmg_growth: 31.1,
        stun_base: 229.2,
        stun_growth: 10.5
      },
      {
        name: '2nd-Hit',
        dmg_base: 341.2,
        dmg_growth: 31.1,
        stun_base: 229.2,
        stun_growth: 10.5
      }
    ]
  },
  Jane_Dodge_5: {
    name: 'Dodge Counter: Swift Shadow Dance',
    description:
      'While in the Passion state, press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nSlash enemies in front quickly multiple times, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 387,
        dmg_growth: 35.2,
        stun_base: 247.5,
        stun_growth: 11.3
      }
    ]
  },
  Jane_Chain_1: {
    name: 'Chain Attack: Flowers of Sin',
    description:
      'When a Chain Attack is triggered, select Jane to activate:\nSlash enemies in a large area in front, dealing massive Physical DMG.\nJane enters the Passion state when using the skill, and gains max Passion Stream\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 632.6,
        dmg_growth: 57.6,
        stun_base: 237.6,
        stun_growth: 10.8
      }
    ]
  },
  Jane_Ultimate_1: {
    name: 'Ultimate: Final Curtain',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nSlash enemies back and forth in a large area, followed by a finishing strike, dealing massive Physical DMG.\nJane enters the Passion state when using the skill, and gains max Passion Stream.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 1470.6,
        dmg_growth: 133.7,
        stun_base: 186.5,
        stun_growth: 8.5
      }
    ]
  },
  Jane_Recover_1: {
    name: 'Quick Assist: Dark Thorn',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nUnleash multiple slashes at enemies in front, followed by a downward thrust, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 119.2,
        dmg_growth: 10.9,
        stun_base: 119.2,
        stun_growth: 5.5
      }
    ]
  },
  Jane_Recover_2: {
    name: 'Quick Assist: Lutz Jump',
    description:
      'While in the Passion state, when the on-field character is launched, press  <IconMap:Icon_Switch> to activate:\nSlash enemies in front quickly multiple times, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 137.5,
        dmg_growth: 12.5,
        stun_base: 137.5,
        stun_growth: 6.3
      }
    ]
  },
  Jane_Parry_1: {
    name: 'Defensive Assist: Last Defense',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 271.3,
        stun_growth: 12.4
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 342.8,
        stun_growth: 15.6
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 166.8,
        stun_growth: 7.6
      }
    ]
  },
  Jane_Assist_1: {
    name: 'Assist Follow-Up: Gale Sweep',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nLeap up and swiftly slash the enemy, then execute a wide sweeping slash across a large area in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 345.5,
        dmg_growth: 31.5,
        stun_base: 299,
        stun_growth: 13.6
      }
    ]
  },
  Seth_Basic_1: {
    name: 'Basic Attack: Lightning Strike',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nUnleashes up to 4 attacks in front, dealing Physical DMG and Electric DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 36.2,
        dmg_growth: 3.3,
        stun_base: 18.1,
        stun_growth: 0.9
      },
      {
        name: '2nd-Hit',
        dmg_base: 56.5,
        dmg_growth: 5.2,
        stun_base: 45.1,
        stun_growth: 2.1
      },
      {
        name: '3rd-Hit',
        dmg_base: 193.3,
        dmg_growth: 17.6,
        stun_base: 151.3,
        stun_growth: 6.9
      },
      {
        name: '4th-Hit',
        dmg_base: 97.4,
        dmg_growth: 8.9,
        stun_base: 80.5,
        stun_growth: 3.7
      }
    ]
  },
  Seth_Basic_2: {
    name: 'Basic Attack: Lightning Strike - Electrified',
    description:
      'When Resolve is above 75%, hold <IconMap:Icon_Normal> to activate:\nConsume 75% Resolve to launch continuous attacks in front, followed by a finishing strike that deals Electric DMG.\nWhen the finishing strike hits an enemy, it triggers the Quick Assist of the squad member positioned before Seth.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: 'Combo',
        dmg_base: 382.8,
        dmg_growth: 34.8,
        stun_base: 161.7,
        stun_growth: 7.4
      },
      {
        name: 'Finishing Strike',
        dmg_base: 424.2,
        dmg_growth: 38.6,
        stun_base: 145.5,
        stun_growth: 6.7
      }
    ]
  },
  Seth_Skill_1: {
    name: 'Special Attack: Thunder Shield Rush',
    description:
      'Hold <IconMap:Icon_Special> to activate.\nLaunch two shield strikes forward, dealing Electric DMG.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 69.2,
        dmg_growth: 6.3,
        stun_base: 69.2,
        stun_growth: 3.2
      }
    ]
  },
  Seth_Skill_2: {
    name: 'EX Special Attack: Thunder Shield Rush - High Voltage',
    description:
      'When Seth has enough energy, press <IconMap:Icon_SpecialReady> to activate: \nUnleash a series of slashes in front, dealing massive Electric DMG.\nHold <IconMap:Icon_SpecialReady> to charge the move, and Seth will dash forward, unleashing a series of powerful slashes, dealing massive Electric DMG.\nActivating the move accumulates 75% Resolve.\nAnti-Interrupt level is increased while charging this skill, and damage taken is reduced by 40%.\nSeth is invulnerable during the continuous slashes.',
    multipliers: [
      {
        name: '',
        dmg_base: 646,
        dmg_growth: 58.8,
        stun_base: 540.4,
        stun_growth: 24.6
      },
      {
        name: 'Charged',
        dmg_base: 999.8,
        dmg_growth: 90.9,
        stun_base: 849,
        stun_growth: 38.6
      },
      {
        name: 'Energy Cost per second',
        dmg_base: 80,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Seth_Dodge_1: {
    name: 'Dodge: Evasion Maneuver',
    description:
      'Press <IconMap:Icon_Evade> to activate.\nA quick dash dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Seth_Dodge_2: {
    name: 'Dash Attack: Thunder Assault',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate.\nCharge forward with a shield attack, dealing Physical DMG.\nDamage received during the move is decreased by 40%.',
    multipliers: [
      {
        name: '',
        dmg_base: 110,
        dmg_growth: 10,
        stun_base: 55,
        stun_growth: 2.5
      }
    ]
  },
  Seth_Dodge_3: {
    name: 'Dodge Counter: Retreat to Advance',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nUnleash a quick downward strike on enemies in front, dealing Electric DMG.\nAfter the move, hold <IconMap:Icon_SpecialReady> to chain into EX Special Attack: Thunder Shield Rush - High Voltage, enabling faster charging.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 230,
        dmg_growth: 21,
        stun_base: 200,
        stun_growth: 9.1
      }
    ]
  },
  Seth_Chain_1: {
    name: 'Chain Attack: Final Judgement',
    description:
      'When a Chain Attack is triggered, select Seth to activate:\nLaunch a series of slashes in front, dealing massive Electric DMG. \nActivating the move accumulates 75% Resolve.\nAfter the move, hold <IconMap:Icon_SpecialReady> to chain into EX Special Attack: Thunder Shield Rush - High Voltage, enabling faster charging.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 704.1,
        dmg_growth: 64.1,
        stun_base: 305.1,
        stun_growth: 13.9
      }
    ]
  },
  Seth_Ultimate_1: {
    name: 'Ultimate: Justice Prevails',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nLaunch a series of upward slashes in front, followed by a finishing strike, dealing massive Electric DMG.\nActivating the move accumulates 75% Resolve, and grants 3 additional Assist Points.\nAfter the move, hold <IconMap:Icon_SpecialReady> to chain into EX Special Attack: Thunder Shield Rush - High Voltage, enabling faster charging.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 2024.3,
        dmg_growth: 184.1,
        stun_base: 403.3,
        stun_growth: 18.4
      }
    ]
  },
  Seth_Recover_1: {
    name: 'Quick Assist: Armed Support',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nUnleash a quick downward strike on enemies in front, dealing Electric DMG.\nAfter the move, hold <IconMap:Icon_SpecialReady> to chain into EX Special Attack: Thunder Shield Rush - High Voltage, enabling faster charging.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 100,
        dmg_growth: 9.1,
        stun_base: 100,
        stun_growth: 4.6
      }
    ]
  },
  Seth_Parry_1: {
    name: 'Defensive Assist: Thundershield',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\nSeth gains 25% Resolve upon activating the skill.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 246.7,
        stun_growth: 11.3
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 311.7,
        stun_growth: 14.2
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 151.7,
        stun_growth: 6.9
      }
    ]
  },
  Seth_Assist_1: {
    name: 'Assist Follow-Up: Public Security Ruling',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate.\nLaunch a forward thrust attack, followed by two horizontal slashes, dealing Electric DMG.\nWhen the finishing strike hits an enemy, it triggers the Quick Assist of the squad member positioned before Seth.\nAfter the move, hold <IconMap:Icon_SpecialReady> to chain into EX Special Attack: Thunder Shield Rush - High Voltage, enabling faster charging.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 428.5,
        dmg_growth: 39,
        stun_base: 378,
        stun_growth: 17.2
      }
    ]
  },
  Caesar_Basic_1: {
    name: 'Basic Attack: Rampaging Slash',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nUnleashes up to 6 slashes ahead, dealing Physical DMG.',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 47.2,
        dmg_growth: 4.3,
        stun_base: 21.3,
        stun_growth: 1
      },
      {
        name: '2nd-Hit',
        dmg_base: 40.9,
        dmg_growth: 3.8,
        stun_base: 33.8,
        stun_growth: 1.6
      },
      {
        name: '3rd-Hit',
        dmg_base: 148.3,
        dmg_growth: 13.5,
        stun_base: 110,
        stun_growth: 5
      },
      {
        name: '3rd-Hit (ALT)',
        dmg_base: 118.4,
        dmg_growth: 10.8,
        stun_base: 73.6,
        stun_growth: 3.4
      },
      {
        name: '4th-Hit',
        dmg_base: 78.8,
        dmg_growth: 7.2,
        stun_base: 67.5,
        stun_growth: 3.1
      },
      {
        name: '5th-Hit',
        dmg_base: 198.6,
        dmg_growth: 18.1,
        stun_base: 137.5,
        stun_growth: 6.3
      },
      {
        name: '6th-Hit',
        dmg_base: 399.9,
        dmg_growth: 36.4,
        stun_base: 262.6,
        stun_growth: 12
      }
    ]
  },
  Caesar_Basic_2: {
    name: 'Basic Attack: Dead End',
    description:
      'When there are sufficient Assist Points, hold <IconMap:Icon_Normal> to activate:\nConsume 1 Assist Point to toss the shield and attack nearby enemies in all directions, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 126.3,
        dmg_growth: 11.5,
        stun_base: 93.2,
        stun_growth: 4.3
      },
      {
        name: 'Assist Point Cost',
        dmg_base: 1,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Caesar_Skill_1: {
    name: 'Special Attack: Shockwave Shield Bash',
    description:
      'Press <IconMap:Icon_Special> to activate.\nStrike forward with the shield, dealing Physical DMG.\nIf Caesar is hit by an enemy at the beginning of the skill activation, it will trigger a Perfect Block, negating the damage once and automatically following up with a Special Attack: Roaring Thrust.\nAfter using the skill, Caesar enters a guard stance, reducing incoming damage by 40%. When attacked by an enemy Caesar blocks with the shield and gets slightly knocked back. Press <IconMap:Icon_Special> or <IconMap:Icon_Special> to trigger Retaliation, activating Special Attack: Roaring Thrust or EX Special Attack: Overpowered Shield Bash.\nWhen Retaliation is triggered, Caesar is invulnerable while using the skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 53.3,
        dmg_growth: 4.9,
        stun_base: 24,
        stun_growth: 1.1
      },
      {
        name: '[Perfect Block]',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 77,
        stun_growth: 3.5
      }
    ]
  },
  Caesar_Skill_2: {
    name: 'Special Attack: Roaring Thrust',
    description:
      'After activating Special Attack: Shockwave Shield Bash, press <IconMap:Icon_SpecialReady> to activate: \nThrusts forward, dealing Physical DMG.\nIf Perfect Block or Retaliation is not triggered, the Anti-Interrupt level is increased while using this skill.\nCharacter is invulnerable while using this skill when Perfect Block or Retaliationis triggered.',
    multipliers: [
      {
        name: '',
        dmg_base: 58.8,
        dmg_growth: 5.4,
        stun_base: 26.5,
        stun_growth: 1.3
      }
    ]
  },
  Caesar_Skill_3: {
    name: 'EX Special Attack: Parry Counter',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate: \nStrikes forward with the shield, followed by a powerful thrust, dealing massive Physical DMG.\nIf Caesar is hit by an enemy at the beginning of the skill activation or a Interrupt is triggered with the shield strike, it will trigger Perfect Block, negating incoming damage once and countering with a shield bash. After triggering Perfect Block, press <IconMap:Icon_SpecialReady> to activate EX Special Attack: Overpowered Shield Bash without consuming energy.\nAfter using the skill, Caesar enters a guard stance, reducing incoming damage by 40%. When attacked by an enemy, Caesar blocks the attack and is slightly knocked back. Press <IconMap:Icon_SpecialReady> or <IconMap:Icon_SpecialReady> to trigger Retaliation and activate Special Attack: Roaring Thrust or EX Special Attack: Overpowered Shield Bash.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 387.2,
        dmg_growth: 35.2,
        stun_base: 252.6,
        stun_growth: 11.5
      },
      {
        name: '[Perfect Block]',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 154,
        stun_growth: 7
      },
      {
        name: '[Perfect Block] Counter',
        dmg_base: 387.2,
        dmg_growth: 35.2,
        stun_base: 252.6,
        stun_growth: 11.5
      },
      {
        name: 'Energy Cost per second',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Caesar_Skill_4: {
    name: 'EX Special Attack: Overpowered Shield Bash',
    description:
      'After activating EX Special Attack: Parry Counter, with enough energy, press <IconMap:Icon_SpecialReady> to activate: \nStrikes forward with the shield, dealing massive Physical DMG.\nIf Caesar is hit by an enemy at the beginning of the skill activation or a Interrupt is triggered with a shield strike, it will trigger Perfect Block, negating incoming damage once and recovering the Energy used to activate the skill.\nWhen the skill is activated through Perfect Block or Retaliation, Perfect Block cannot be triggered again.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 425.7,
        dmg_growth: 38.7,
        stun_base: 288.4,
        stun_growth: 24.6
      },
      {
        name: '[Perfect Block]',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 154,
        stun_growth: 7
      },
      {
        name: 'Activation Energy Cost',
        dmg_base: 20,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Caesar_Skill_5: {
    name: 'Stance Switch',
    description:
      'When Caesar triggers a Perfect Block, Retaliation, or Defensive Assist, her impact is increased for 3s.',
    multipliers: [
      {
        name: 'Impact Increase',
        dmg_base: 9,
        dmg_growth: 1,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Caesar_Dodge_1: {
    name: 'Dodge: Adrift',
    description:
      'Press <IconMap:Icon_Evade> to activate.\nA quick dash dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Caesar_Dodge_2: {
    name: 'Dash Attack: Hog Rush',
    description:
      'While dodging, press <IconMap:Icon_Normal> to activate.\nStrikes forward with the shield, dealing Physical DMG.\nDuring skill activation, damage taken is reduced by 40%. When attacked by an enemy Caesar blocks with the shield and gets slightly knocked back. Press <IconMap:Icon_SpecialReady> or <IconMap:Icon_SpecialReady> to trigger Retaliation and activate Special Attack: Roaring Thrust or EX Special Attack: Overpowered Shield Bash.',
    multipliers: [
      {
        name: '',
        dmg_base: 62.3,
        dmg_growth: 5.7,
        stun_base: 31.2,
        stun_growth: 1.5
      }
    ]
  },
  Caesar_Dodge_3: {
    name: 'Dodge Counter: Eye For an Eye',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nSlashes enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 193.5,
        dmg_growth: 17.6,
        stun_base: 174.2,
        stun_growth: 8
      }
    ]
  },
  Caesar_Chain_1: {
    name: 'Chain Attack: Road Rage Slam',
    description:
      'When a Chain Attack is triggered, select the character to activate:\nUnleashes a powerful downward strike at enemies ahead, dealing massive Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 638.8,
        dmg_growth: 58.1,
        stun_base: 199.9,
        stun_growth: 9.1
      }
    ]
  },
  Caesar_Ultimate_1: {
    name: 'Ultimate: Savage Smash',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nTosses the shield forward, unleashing a powerful spinning attack on enemies in front, followed by a downward strike, dealing massive Physical DMG.\nWhen hitting shielded enemies, the Daze dealt is increased.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 2012.3,
        dmg_growth: 183,
        stun_base: 278.7,
        stun_growth: 12.7
      },
      {
        name: 'Daze Increase',
        dmg_base: 45,
        dmg_growth: 5,
        stun_base: 0,
        stun_growth: 0,
        exception: true
      }
    ]
  },
  Caesar_Recover_1: {
    name: 'Quick Assist: Lane Change',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nSlashes enemies in front, dealing Physical DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 64.2,
        dmg_growth: 5.9,
        stun_base: 64.2,
        stun_growth: 3
      }
    ]
  },
  Caesar_Parry_1: {
    name: 'Defensive Assist: Aegis Shield',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\nThis skill excels at parrying enemy attacks and reduces the consumption of Assist Points when the character is under intense attacks.\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 271.3,
        stun_growth: 12.4
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 345.3,
        stun_growth: 15.7
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 169.3,
        stun_growth: 7.7
      }
    ]
  },
  Caesar_Assist_1: {
    name: 'Assist Follow-Up: Aiding Blade',
    description:
      'After activating Defensive Assist, press <IconMap:Icon_Normal> to activate:\nTosses the shield forward, unleashing a powerful spinning attack on enemies in front, followed by a slash that deals Physical DMG.\nAfter activating the skill, press <IconMap:Icon_SpecialReady> or <IconMap:Icon_SpecialReady> or to trigger a Retaliation, activating Special Attack: Roaring Thrust or EX Special Attack: Overpowered Shield Bash.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 407.2,
        dmg_growth: 37.1,
        stun_base: 356.3,
        stun_growth: 16.2
      }
    ]
  },
  Burnice_Basic_1: {
    name: 'Basic Attack: Direct Flame Blend',
    description:
      'Press <IconMap:Icon_Normal> to activate:\nUnleashes up to 5 attacks in front, dealing Physical DMG and Fire DMG..',
    multipliers: [
      {
        name: '1st-Hit',
        dmg_base: 44.8,
        dmg_growth: 4.1,
        stun_base: 18.7,
        stun_growth: 0.9
      },
      {
        name: '2nd-Hit',
        dmg_base: 43.5,
        dmg_growth: 4,
        stun_base: 30.8,
        stun_growth: 1.4
      },
      {
        name: '3rd-Hit',
        dmg_base: 71.7,
        dmg_growth: 6.6,
        stun_base: 47.8,
        stun_growth: 2.2
      },
      {
        name: '4th-Hit',
        dmg_base: 66.6,
        dmg_growth: 6.1,
        stun_base: 30.4,
        stun_growth: 1.4
      },
      {
        name: '5th-Hit',
        dmg_base: 96.3,
        dmg_growth: 8.8,
        stun_base: 52,
        stun_growth: 2.4
      }
    ]
  },
  Burnice_Basic_2: {
    name: 'Basic Attack: Mixed Flame Blend',
    description:
      'In the Nitro-Fuel Cocktail state, hold <IconMap:Icon_Normal> to activate:\nSpin continuously while spraying flames around, followed by a Finishing Move that inflicts Fire DMG. Continuing to hold extends the flame-spraying duration.\nWhen the move is activated, it consumes 20 Heat;\nAnti-Interrupt level is increased while using this skill.\nAfter executing the Finishing Move, press <IconMap:Icon_SpecialReady> to immediately follow up with EX Special Attack: Intense Heat Stirring Method - Double Shot.',
    multipliers: [
      {
        name: 'Continuous Spray',
        dmg_base: 125.4,
        dmg_growth: 11.5,
        stun_base: 96.5,
        stun_growth: 4.3
      },
      {
        name: 'Finishing Move',
        dmg_base: 232.8,
        dmg_growth: 21.2,
        stun_base: 179.1,
        stun_growth: 8.2
      }
    ]
  },
  Burnice_Skill_1: {
    name: 'Special Attack: Intense Heat Aging Method',
    description:
      'Press <IconMap:Icon_Special> to activate.\nUnleash a fire blast forward, inflicting Fire DMG;\nPress and hold <IconMap:Icon_Special> to charge, boosting the moves power.\nAnti-Interrupt level is increased while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 57.8,
        dmg_growth: 5.3,
        stun_base: 57.8,
        stun_growth: 2.7
      },
      {
        name: 'Charged Attack',
        dmg_base: 62.4,
        dmg_growth: 5.7,
        stun_base: 62.4,
        stun_growth: 2.9
      }
    ]
  },
  Burnice_Skill_2: {
    name: 'EX Special Attack: Intense Heat Stirring Method',
    description:
      'With enough energy, press <IconMap:Icon_SpecialReady> to activate: \nContinuously spray flames with a one-handed flamethrower, then release a fire blast in a straight line ahead, dealing massive Fire DMG. Continue holding to steadily consume energy and extend the sprays duration.\nWhile continuously spraying, drag the stick/joystick to execute a sidestep dodge, adjusting your position in the corresponding direction.\nAnti-Interrupt Level is increased while continuously spraying, and DMG taken is reduced by 40%.\nCharacter is invulnerable during sidestep dodge and fire blast.',
    multipliers: [
      {
        name: 'Continuous Spray',
        dmg_base: 543.8,
        dmg_growth: 49.5,
        stun_base: 378.6,
        stun_growth: 17.3
      },
      {
        name: 'Fire Blast',
        dmg_base: 96.7,
        dmg_growth: 8.8,
        stun_base: 65.7,
        stun_growth: 3
      },
      {
        name: 'Energy Cost to Use',
        dmg_base: 40,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Continuous Spray Energy Cost per second',
        dmg_base: 12.5,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Fire Blast Energy Cost',
        dmg_base: 5,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Burnice_Skill_3: {
    name: 'EX Special Attack: Intense Heat Stirring Method - Double Shot',
    description:
      'While using EX Special Attack: Intense Heat Stirring Method, with enough Energy, press <IconMap:Icon_SpecialReady> to activate: \nUse both flamethrowers to continuously spray flames, followed by a fire blast in a front line, dealing massive Fire DMG. Keep holding to continue consuming Energy and extend the sprays duration.\nCharacter is invulnerable during the moment the spray is activated and during the activation of the fire blast.\nAnti-Interrupt Level is increased while spraying, and DMG received is reduced by 40%.',
    multipliers: [
      {
        name: 'Continuous Spray',
        dmg_base: 958.1,
        dmg_growth: 87.1,
        stun_base: 585.7,
        stun_growth: 26.7
      },
      {
        name: 'Fire Blast',
        dmg_base: 287.1,
        dmg_growth: 26.1,
        stun_base: 207.8,
        stun_growth: 9.5
      },
      {
        name: 'Energy Cost to Use',
        dmg_base: 10,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Continuous Spray Energy Cost per second',
        dmg_base: 25,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      },
      {
        name: 'Fire Blast Energy Cost',
        dmg_base: 10,
        dmg_growth: 0,
        stun_base: 0,
        stun_growth: 0
      }
    ]
  },
  Burnice_Dodge_1: {
    name: 'Dodge: Fiery Phantom Dash',
    description:
      'Press <IconMap:Icon_Evade> to activate.\nA quick dash dodge.\nCharacter is invulnerable while using this skill.',
    multipliers: []
  },
  Burnice_Dodge_2: {
    name: 'Dash Attack: Dangerous Fermentation',
    description:
      'Press <IconMap:Icon_Normal> during a dodge to activate:\nPerform a forward rush attack with the flamethrower, dealing Fire DMG.',
    multipliers: [
      {
        name: '',
        dmg_base: 67.9,
        dmg_growth: 6.2,
        stun_base: 34,
        stun_growth: 1.6
      }
    ]
  },
  Burnice_Dodge_3: {
    name: 'Dodge Counter: Fluttering Steps',
    description:
      'Press <IconMap:Icon_Normal> during a Perfect Dodge to activate.\nSLaunch a series of attacks on enemies in front while spraying flames, dealing Physical DMG and Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 219.7,
        dmg_growth: 20,
        stun_base: 194.4,
        stun_growth: 8.9
      }
    ]
  },
  Burnice_Chain_1: {
    name: 'Chain Attack: Fuel-Fed Flame',
    description:
      'When a Chain Attack is triggered, select the character to activate:\nQuickly dash forward while spraying flames in all directions, then launch a fire blast in a fan-shaped area ahead, dealing massive Fire DMG.\nAfter using the skill, press <IconMap:Icon_SpecialReady> to immediately follow up with EX Special Attack: Intense Heat Stirring Method - Double Shot.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 680.9,
        dmg_growth: 61.9,
        stun_base: 242,
        stun_growth: 11
      }
    ]
  },
  Burnice_Ultimate_1: {
    name: 'Ultimate: Glorious Inferno',
    description:
      'When Decibel Rating is at Maximum, press <IconMap:Icon_UltimateReady> to activate.\nDash forward quickly, then leap into the air and continuously spray flames over a wide area ahead, dealing massive Fire DMG.\nWhen leaping into the air, a Quick Assist will be triggered. If the Quick Assist is activated, Burnice will extend the duration of her flame spray.\nUpon activation, recover 50 Heat.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 2012.2,
        dmg_growth: 183,
        stun_base: 106.4,
        stun_growth: 4.9
      },
      {
        name: 'Daze Increase',
        dmg_base: 45,
        dmg_growth: 5,
        stun_base: 0,
        stun_growth: 0,
        exception: true
      }
    ]
  },
  Burnice_Recover_1: {
    name: 'Quick Assist: Energizing Specialty Drink',
    description:
      'When the on-field character is launched, press <IconMap:Icon_Switch> to activate.\nUnleash a flurry of attacks on enemies in front while spraying flames, dealing Physical DMG and Fire DMG.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 84.4,
        dmg_growth: 7.7,
        stun_base: 84.4,
        stun_growth: 3.9
      }
    ]
  },
  Burnice_Parry_1: {
    name: 'Defensive Assist: Smoky Cauldron',
    description:
      "When the character on field is about to be attacked, press <IconMap:Icon_Switch> to activate.\nParries the enemy's attack, inflicting massive Daze.\n\nCharacter is invulnerable while using this skill.",
    multipliers: [
      {
        name: 'Light Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 271.3,
        stun_growth: 12.4
      },
      {
        name: 'Heavy Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 342.8,
        stun_growth: 15.6
      },
      {
        name: 'Chain Defensive',
        dmg_base: 0,
        dmg_growth: 0,
        stun_base: 166.8,
        stun_growth: 7.6
      }
    ]
  },
  Burnice_Assist_1: {
    name: 'Assist Follow-Up: Scorching Dew',
    description:
      'Press <IconMap:Icon_Normal> after a Defensive Assist to activate:\nLeap into the air and quickly spin forward while spraying flames in all directions, dealing Fire DMG.\nAfter using the skill, press <IconMap:Icon_SpecialReady> to immediately follow up with EX Special Attack: Intense Heat Stirring Method - Double Shot.\nCharacter is invulnerable while using this skill.',
    multipliers: [
      {
        name: '',
        dmg_base: 327.6,
        dmg_growth: 29.8,
        stun_base: 282.4,
        stun_growth: 12.9
      }
    ]
  }
};
