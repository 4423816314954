export const ZZZBuilds = [
  {
    char: 'jane-doe',
    app_rate: 59.25,
    avg_round: 2.92,
    std_dev_round: 0.29,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 2.85,
    diff_rounds: 0.02,
    weapon_1: 'Sharpened Stinger',
    weapon_1_app: 59.2,
    weapon_1_round: 2.96,
    weapon_2: 'Rainforest Gourmet',
    weapon_2_app: 13.92,
    weapon_2_round: 2.85,
    weapon_3: 'Weeping Gemini',
    weapon_3_app: 11.49,
    weapon_3_round: 2.88,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 10.28,
    weapon_4_round: 2.92,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 4.53,
    weapon_5_round: 2.89,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.51,
    weapon_6_round: 2.88,
    weapon_7: 'Starlight Engine',
    weapon_7_app: 0.06,
    weapon_7_round: 2.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 89.85,
    artifact_1_round: 2.92,
    artifact_2: 'Fanged Metal, Hormone Punk',
    artifact_2_1: 'Fanged Metal',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 2.68,
    artifact_2_round: 2.92,
    artifact_3: 'Freedom Blues, Fanged Metal',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Fanged Metal',
    artifact_3_3: '',
    artifact_3_app: 2.36,
    artifact_3_round: 2.83,
    artifact_4: 'Fanged Metal, Chaos Jazz',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Chaos Jazz',
    artifact_4_3: '',
    artifact_4_app: 1.15,
    artifact_4_round: 3.0,
    artifact_5: 'Fanged Metal, Puffer Electro',
    artifact_5_1: 'Fanged Metal',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 0.89,
    artifact_5_round: 2.91,
    artifact_6: 'Fanged Metal, Flex',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 0.45,
    artifact_6_round: 3.0,
    artifact_7: 'Freedom Blues, Swing Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 0.45,
    artifact_7_round: 2.86,
    artifact_8: 'Fanged Metal, Swing Jazz',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 0.32,
    artifact_8_round: 3.0,
    artifact_9: 'Fanged Metal, AP +30, Hormone Punk',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'AP +30',
    artifact_9_3: 'Hormone Punk',
    artifact_9_app: 0.26,
    artifact_9_round: 2.75,
    artifact_10: 'Freedom Blues, Hormone Punk',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: '',
    artifact_10_app: 0.19,
    artifact_10_round: 3.0,
    app_0: 78.99,
    round_0: 2.92,
    app_1: 8.94,
    round_1: 2.94,
    app_2: 9.2,
    round_2: 2.99,
    app_3: 0.64,
    round_3: 3.0,
    app_4: 0.06,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 2.17,
    round_6: 3.0,
    cons_avg: 0.43,
    sample: 1627,
    sample_app_flat: 1274
  },
  {
    char: 'Seth',
    app_rate: 48.69,
    avg_round: 2.92,
    std_dev_round: 0.29,
    role: 'Support',
    rarity: 'A',
    diff: 4.58,
    diff_rounds: 0.01,
    weapon_1: 'Peacekeeper - Specialized',
    weapon_1_app: 78.18,
    weapon_1_round: 2.93,
    weapon_2: 'Bunny Band',
    weapon_2_app: 5.86,
    weapon_2_round: 2.88,
    weapon_3: 'Spring Embrace',
    weapon_3_app: 4.07,
    weapon_3_round: 2.89,
    weapon_4: 'Original Transmorpher',
    weapon_4_app: 3.42,
    weapon_4_round: 2.82,
    weapon_5: 'Big Cylinder',
    weapon_5_app: 1.71,
    weapon_5_round: 2.88,
    weapon_6: 'Starlight Engine',
    weapon_6_app: 1.06,
    weapon_6_round: 3.0,
    weapon_7: 'Gilded Blossom',
    weapon_7_app: 0.65,
    weapon_7_round: 3.0,
    weapon_8: 'Street Superstar',
    weapon_8_app: 0.57,
    weapon_8_round: 3.0,
    weapon_9: 'Tusks of Fury',
    weapon_9_app: 0.49,
    weapon_9_round: 3.0,
    weapon_10: 'Demara Battery Mark II',
    weapon_10_app: 0.49,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 31.19,
    artifact_1_round: 2.9,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 9.53,
    artifact_2_round: 2.93,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 6.11,
    artifact_3_round: 2.94,
    artifact_4: 'Proto Punk, Hormone Punk',
    artifact_4_1: 'Proto Punk',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 5.94,
    artifact_4_round: 2.95,
    artifact_5: 'Proto Punk, Swing Jazz',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 4.23,
    artifact_5_round: 2.95,
    artifact_6: 'Freedom Blues, Hormone Punk',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 3.58,
    artifact_6_round: 2.93,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 2.85,
    artifact_7_round: 2.92,
    artifact_8: 'Swing Jazz, Flex',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 2.61,
    artifact_8_round: 2.93,
    artifact_9: 'Proto Punk, Shockstar Disco',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 2.36,
    artifact_9_round: 3.0,
    artifact_10: 'Hormone Punk, Swing Jazz',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 2.04,
    artifact_10_round: 3.0,
    app_0: 6.84,
    round_0: 2.81,
    app_1: 12.79,
    round_1: 2.9,
    app_2: 14.09,
    round_2: 2.91,
    app_3: 13.27,
    round_3: 2.96,
    app_4: 12.3,
    round_4: 2.95,
    app_5: 7.17,
    round_5: 2.89,
    app_6: 33.55,
    round_6: 2.96,
    cons_avg: 3.67,
    sample: 1337,
    sample_app_flat: 1054
  },
  {
    char: 'Caesar',
    app_rate: 43.95,
    avg_round: 2.91,
    std_dev_round: 0.31,
    role: 'Stun',
    rarity: 'Limited S',
    diff: 0.0,
    diff_rounds: 1,
    weapon_1: 'Tusks of Fury',
    weapon_1_app: 53.15,
    weapon_1_round: 2.93,
    weapon_2: 'Original Transmorpher',
    weapon_2_app: 17.75,
    weapon_2_round: 2.9,
    weapon_3: 'Demara Battery Mark II',
    weapon_3_app: 10.38,
    weapon_3_round: 2.9,
    weapon_4: 'Precious Fossilized Core',
    weapon_4_app: 5.32,
    weapon_4_round: 2.92,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 3.82,
    weapon_5_round: 2.94,
    weapon_6: 'The Restrained',
    weapon_6_app: 3.73,
    weapon_6_round: 2.89,
    weapon_7: 'Spring Embrace',
    weapon_7_app: 1.42,
    weapon_7_round: 2.93,
    weapon_8: 'Peacekeeper - Specialized',
    weapon_8_app: 1.33,
    weapon_8_round: 2.82,
    weapon_9: 'Bunny Band',
    weapon_9_app: 0.53,
    weapon_9_round: 2.5,
    weapon_10: 'Big Cylinder',
    weapon_10_app: 0.53,
    weapon_10_round: 2.67,
    artifact_1: 'Proto Punk, Shockstar Disco',
    artifact_1_1: 'Proto Punk',
    artifact_1_2: 'Shockstar Disco',
    artifact_1_3: '',
    artifact_1_app: 78.88,
    artifact_1_round: 2.92,
    artifact_2: 'Proto Punk, Swing Jazz',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 7.19,
    artifact_2_round: 2.96,
    artifact_3: 'Freedom Blues, Shockstar Disco',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Shockstar Disco',
    artifact_3_3: '',
    artifact_3_app: 2.66,
    artifact_3_round: 2.96,
    artifact_4: 'Proto Punk, Flex',
    artifact_4_1: 'Proto Punk',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 1.33,
    artifact_4_round: 2.77,
    artifact_5: 'Shockstar Disco, Swing Jazz',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 1.24,
    artifact_5_round: 2.78,
    artifact_6: 'Freedom Blues, Swing Jazz',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 0.89,
    artifact_6_round: 2.86,
    artifact_7: 'Proto Punk, Shockstar Disco, Flex',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: 'Flex',
    artifact_7_app: 0.89,
    artifact_7_round: 2.86,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.62,
    artifact_8_round: 2.8,
    artifact_9: 'Proto Punk, Woodpecker Electro',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 0.53,
    artifact_9_round: 3.0,
    artifact_10: 'Proto Punk, Fanged Metal',
    artifact_10_1: 'Proto Punk',
    artifact_10_2: 'Fanged Metal',
    artifact_10_3: '',
    artifact_10_app: 0.44,
    artifact_10_round: 3.0,
    app_0: 88.64,
    round_0: 2.91,
    app_1: 6.12,
    round_1: 2.99,
    app_2: 3.46,
    round_2: 3.0,
    app_3: 0.53,
    round_3: 3.0,
    app_4: 0.09,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.15,
    round_6: 3.0,
    cons_avg: 0.22,
    sample: 1207,
    sample_app_flat: 931
  },
  {
    char: 'Rina',
    app_rate: 18.14,
    avg_round: 2.9,
    std_dev_round: 0.34,
    role: 'Support',
    rarity: 'Standard S',
    diff: -3.44,
    diff_rounds: 0.08,
    weapon_1: 'Slice of Time',
    weapon_1_app: 56.16,
    weapon_1_round: 2.88,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 35.91,
    weapon_2_round: 2.93,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 2.71,
    weapon_3_round: 2.85,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 2.3,
    weapon_4_round: 3.0,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 1.25,
    weapon_5_round: 2.6,
    weapon_6: 'The Vault',
    weapon_6_app: 0.42,
    weapon_6_round: 1.0,
    weapon_7: '[Reverb] Mark I',
    weapon_7_app: 0.21,
    weapon_7_round: 3.0,
    weapon_8: 'Street Superstar',
    weapon_8_app: 0.21,
    weapon_8_round: 3.0,
    weapon_9: 'Fusion Compiler',
    weapon_9_app: 0.21,
    weapon_9_round: 3.0,
    weapon_10: '[Reverb] Mark III',
    weapon_10_app: 0.21,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Puffer Electro',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Puffer Electro',
    artifact_1_3: '',
    artifact_1_app: 55.53,
    artifact_1_round: 2.92,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 7.52,
    artifact_2_round: 2.82,
    artifact_3: 'Freedom Blues, Puffer Electro',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 6.68,
    artifact_3_round: 2.96,
    artifact_4: 'Freedom Blues, Swing Jazz',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 4.18,
    artifact_4_round: 2.8,
    artifact_5: 'Swing Jazz, Thunder Metal',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Thunder Metal',
    artifact_5_3: '',
    artifact_5_app: 2.51,
    artifact_5_round: 3.0,
    artifact_6: 'Swing Jazz, Flex',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.09,
    artifact_6_round: 2.88,
    artifact_7: 'Puffer Electro, Swing Jazz, Flex',
    artifact_7_1: 'Puffer Electro',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: 'Flex',
    artifact_7_app: 1.46,
    artifact_7_round: 3.0,
    artifact_8: 'Freedom Blues, Thunder Metal',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Thunder Metal',
    artifact_8_3: '',
    artifact_8_app: 1.46,
    artifact_8_round: 2.67,
    artifact_9: 'Freedom Blues, Flex',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 1.04,
    artifact_9_round: 2.8,
    artifact_10: 'Puffer Electro, Flex',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 0.84,
    artifact_10_round: 3.0,
    app_0: 55.74,
    round_0: 2.91,
    app_1: 33.61,
    round_1: 2.85,
    app_2: 6.89,
    round_2: 2.9,
    app_3: 2.51,
    round_3: 3.0,
    app_4: 0.63,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.63,
    round_6: 1.0,
    cons_avg: 0.61,
    sample: 498,
    sample_app_flat: 375
  },
  {
    char: 'Nekomata',
    app_rate: 5.83,
    avg_round: 2.88,
    std_dev_round: 0.35,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -1.72,
    diff_rounds: 0.12,
    weapon_1: 'Steel Cushion',
    weapon_1_app: 46.54,
    weapon_1_round: 2.9,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 27.67,
    weapon_2_round: 2.84,
    weapon_3: 'The Brimstone',
    weapon_3_app: 9.43,
    weapon_3_round: 2.93,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 7.55,
    weapon_4_round: 2.8,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 5.03,
    weapon_5_round: 2.88,
    weapon_6: 'Housekeeper',
    weapon_6_app: 1.89,
    weapon_6_round: 3.0,
    weapon_7: 'Street Superstar',
    weapon_7_app: 1.26,
    weapon_7_round: 2.5,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 45.28,
    artifact_1_round: 2.84,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 23.27,
    artifact_2_round: 2.94,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 10.06,
    artifact_3_round: 2.86,
    artifact_4: 'Woodpecker Electro, Puffer Electro',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 3.14,
    artifact_4_round: 3.0,
    artifact_5: 'Woodpecker Electro, Hormone Punk',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 3.14,
    artifact_5_round: 3.0,
    artifact_6: 'Fanged Metal, Flex',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.89,
    artifact_6_round: 3.0,
    artifact_7: 'Puffer Electro, Woodpecker Electro',
    artifact_7_1: 'Puffer Electro',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 1.89,
    artifact_7_round: 2.33,
    artifact_8: 'Fanged Metal, Shockstar Disco, Flex',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: 'Flex',
    artifact_8_app: 1.26,
    artifact_8_round: 3.0,
    artifact_9: 'Hormone Punk, Woodpecker Electro',
    artifact_9_1: 'Hormone Punk',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 1.26,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Puffer Electro',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: '',
    artifact_10_app: 1.26,
    artifact_10_round: 3.0,
    app_0: 57.86,
    round_0: 2.86,
    app_1: 33.96,
    round_1: 2.89,
    app_2: 5.66,
    round_2: 3.0,
    app_3: 1.89,
    round_3: 2.5,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.63,
    round_6: 1.0,
    cons_avg: 0.55,
    sample: 160,
    sample_app_flat: 144
  },
  {
    char: 'zhu-yuan',
    app_rate: 52.26,
    avg_round: 2.88,
    std_dev_round: 0.36,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -8.67,
    diff_rounds: 0.03,
    weapon_1: 'Riot Suppressor Mark VI',
    weapon_1_app: 48.2,
    weapon_1_round: 2.93,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 32.28,
    weapon_2_round: 2.86,
    weapon_3: 'The Brimstone',
    weapon_3_app: 10.15,
    weapon_3_round: 2.95,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 4.23,
    weapon_4_round: 2.81,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 2.75,
    weapon_5_round: 2.55,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 1.41,
    weapon_6_round: 2.83,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.78,
    weapon_7_round: 2.64,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 0.07,
    weapon_8_round: 3.0,
    weapon_9: 'Housekeeper',
    weapon_9_app: 0.07,
    weapon_9_round: 3.0,
    weapon_10: 'Deep Sea Visitor',
    weapon_10_app: 0.07,
    weapon_10_round: 3.0,
    artifact_1: 'Chaotic Metal, Woodpecker Electro',
    artifact_1_1: 'Chaotic Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 77.17,
    artifact_1_round: 2.89,
    artifact_2: 'Chaotic Metal, Hormone Punk',
    artifact_2_1: 'Chaotic Metal',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 5.64,
    artifact_2_round: 2.93,
    artifact_3: 'Chaotic Metal, Swing Jazz',
    artifact_3_1: 'Chaotic Metal',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 5.0,
    artifact_3_round: 2.9,
    artifact_4: 'Woodpecker Electro, Chaotic Metal',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Chaotic Metal',
    artifact_4_3: '',
    artifact_4_app: 4.3,
    artifact_4_round: 2.81,
    artifact_5: 'Chaotic Metal, Flex',
    artifact_5_1: 'Chaotic Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 2.4,
    artifact_5_round: 2.73,
    artifact_6: 'Chaotic Metal, Puffer Electro',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 2.18,
    artifact_6_round: 2.67,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 0.56,
    artifact_7_round: 3.0,
    artifact_8: 'Hormone Punk, Woodpecker Electro',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.49,
    artifact_8_round: 3.0,
    artifact_9: 'Hormone Punk, Chaotic Metal',
    artifact_9_1: 'Hormone Punk',
    artifact_9_2: 'Chaotic Metal',
    artifact_9_3: '',
    artifact_9_app: 0.42,
    artifact_9_round: 3.0,
    artifact_10: 'Chaotic Metal, Freedom Blues',
    artifact_10_1: 'Chaotic Metal',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 0.42,
    artifact_10_round: 2.0,
    app_0: 77.24,
    round_0: 2.88,
    app_1: 16.49,
    round_1: 2.97,
    app_2: 3.52,
    round_2: 2.98,
    app_3: 0.14,
    round_3: 3.0,
    app_4: 0.56,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 2.04,
    round_6: 3.0,
    cons_avg: 0.38,
    sample: 1435,
    sample_app_flat: 1065
  },
  {
    char: 'Lycaon',
    app_rate: 34.81,
    avg_round: 2.88,
    std_dev_round: 0.35,
    role: 'Stun',
    rarity: 'Standard S',
    diff: 2.37,
    diff_rounds: 0.08,
    weapon_1: 'The Restrained',
    weapon_1_app: 31.61,
    weapon_1_round: 2.9,
    weapon_2: 'Steam Oven',
    weapon_2_app: 27.29,
    weapon_2_round: 2.89,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 18.77,
    weapon_3_round: 2.84,
    weapon_4: 'Hellfire Gears',
    weapon_4_app: 12.84,
    weapon_4_round: 2.9,
    weapon_5: 'Six Shooter',
    weapon_5_app: 5.83,
    weapon_5_round: 2.91,
    weapon_6: '[Vortex] Arrow',
    weapon_6_app: 1.94,
    weapon_6_round: 2.93,
    weapon_7: 'Demara Battery Mark II',
    weapon_7_app: 1.08,
    weapon_7_round: 3.0,
    weapon_8: '[Vortex] Revolver',
    weapon_8_app: 0.32,
    weapon_8_round: 3.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 42.93,
    artifact_1_round: 2.92,
    artifact_2: 'Shockstar Disco, Polar Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 30.1,
    artifact_2_round: 2.83,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 7.87,
    artifact_3_round: 2.93,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 5.39,
    artifact_4_round: 2.97,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 1.94,
    artifact_5_round: 2.88,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.73,
    artifact_6_round: 2.92,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.73,
    artifact_7_round: 3.0,
    artifact_8: 'Shockstar Disco, Puffer Electro',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Puffer Electro',
    artifact_8_3: '',
    artifact_8_app: 1.29,
    artifact_8_round: 2.8,
    artifact_9: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: 'Flex',
    artifact_9_app: 0.76,
    artifact_9_round: 3.0,
    artifact_10: 'Polar Metal, Shockstar Disco',
    artifact_10_1: 'Polar Metal',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.76,
    artifact_10_round: 2.4,
    app_0: 66.13,
    round_0: 2.88,
    app_1: 26.43,
    round_1: 2.9,
    app_2: 6.15,
    round_2: 2.94,
    app_3: 0.97,
    round_3: 3.0,
    app_4: 0.22,
    round_4: 3.0,
    app_5: 0.11,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.43,
    sample: 956,
    sample_app_flat: 696
  },
  {
    char: 'Qingyi',
    app_rate: 54.12,
    avg_round: 2.88,
    std_dev_round: 0.35,
    role: 'Stun',
    rarity: 'Limited S',
    diff: -3.83,
    diff_rounds: 0.05,
    weapon_1: 'Ice-Jade Teapot',
    weapon_1_app: 37.48,
    weapon_1_round: 2.91,
    weapon_2: 'Steam Oven',
    weapon_2_app: 21.84,
    weapon_2_round: 2.88,
    weapon_3: 'Demara Battery Mark II',
    weapon_3_app: 13.84,
    weapon_3_round: 2.93,
    weapon_4: 'The Restrained',
    weapon_4_app: 11.61,
    weapon_4_round: 2.86,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 7.93,
    weapon_5_round: 2.88,
    weapon_6: 'Precious Fossilized Core',
    weapon_6_app: 5.7,
    weapon_6_round: 2.78,
    weapon_7: 'Six Shooter',
    weapon_7_app: 0.97,
    weapon_7_round: 2.9,
    weapon_8: '[Vortex] Arrow',
    weapon_8_app: 0.42,
    weapon_8_round: 2.67,
    weapon_9: 'Big Cylinder',
    weapon_9_app: 0.07,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 40.33,
    artifact_1_round: 2.88,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 33.73,
    artifact_2_round: 2.88,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 15.23,
    artifact_3_round: 2.9,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 2.64,
    artifact_4_round: 2.88,
    artifact_5: 'Shockstar Disco, Hormone Punk',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 1.67,
    artifact_5_round: 2.94,
    artifact_6: 'Shockstar Disco, Puffer Electro',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 1.53,
    artifact_6_round: 2.86,
    artifact_7: 'Shockstar Disco, Freedom Blues',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Freedom Blues',
    artifact_7_3: '',
    artifact_7_app: 1.46,
    artifact_7_round: 3.0,
    artifact_8: 'Woodpecker Electro, Shockstar Disco',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.56,
    artifact_8_round: 3.0,
    artifact_9: 'Woodpecker Electro, Thunder Metal',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: '',
    artifact_9_app: 0.35,
    artifact_9_round: 3.0,
    artifact_10: 'Swing Jazz, Shockstar Disco',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.35,
    artifact_10_round: 2.75,
    app_0: 83.8,
    round_0: 2.88,
    app_1: 9.87,
    round_1: 2.99,
    app_2: 3.62,
    round_2: 3.0,
    app_3: 0.7,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 2.02,
    round_6: 2.97,
    cons_avg: 0.31,
    sample: 1486,
    sample_app_flat: 1061
  },
  {
    char: 'Soukaku',
    app_rate: 51.17,
    avg_round: 2.87,
    std_dev_round: 0.38,
    role: 'Support',
    rarity: 'A',
    diff: -0.09,
    diff_rounds: 0.07,
    weapon_1: 'Bashful Demon',
    weapon_1_app: 91.83,
    weapon_1_round: 2.88,
    weapon_2: 'Slice of Time',
    weapon_2_app: 2.52,
    weapon_2_round: 2.56,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 2.15,
    weapon_3_round: 2.93,
    weapon_4: 'Weeping Cradle',
    weapon_4_app: 0.97,
    weapon_4_round: 2.89,
    weapon_5: '[Reverb] Mark II',
    weapon_5_app: 0.82,
    weapon_5_round: 3.0,
    weapon_6: '[Reverb] Mark III',
    weapon_6_app: 0.59,
    weapon_6_round: 2.75,
    weapon_7: 'Unfettered Game Ball',
    weapon_7_app: 0.59,
    weapon_7_round: 2.75,
    weapon_8: '[Reverb] Mark I',
    weapon_8_app: 0.22,
    weapon_8_round: 2.67,
    weapon_9: 'The Brimstone',
    weapon_9_app: 0.07,
    weapon_9_round: 3.0,
    weapon_10: 'Drill Rig - Red Axis',
    weapon_10_app: 0.07,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 47.44,
    artifact_1_round: 2.86,
    artifact_2: 'Swing Jazz, Polar Metal',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 11.28,
    artifact_2_round: 2.94,
    artifact_3: 'Swing Jazz, Flex',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 5.05,
    artifact_3_round: 2.91,
    artifact_4: 'Swing Jazz, Woodpecker Electro',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 5.05,
    artifact_4_round: 2.96,
    artifact_5: 'Swing Jazz, Freedom Blues',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 4.08,
    artifact_5_round: 2.82,
    artifact_6: 'Polar Metal, Woodpecker Electro',
    artifact_6_1: 'Polar Metal',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: '',
    artifact_6_app: 2.67,
    artifact_6_round: 2.88,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.93,
    artifact_7_round: 2.82,
    artifact_8: 'Hormone Punk, Polar Metal',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Polar Metal',
    artifact_8_3: '',
    artifact_8_app: 1.63,
    artifact_8_round: 2.69,
    artifact_9: 'Swing Jazz, Puffer Electro',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.56,
    artifact_9_round: 2.89,
    artifact_10: 'Freedom Blues, Swing Jazz',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 1.56,
    artifact_10_round: 2.83,
    app_0: 0.45,
    round_0: 2.67,
    app_1: 1.19,
    round_1: 2.8,
    app_2: 1.78,
    round_2: 2.83,
    app_3: 3.04,
    round_3: 2.76,
    app_4: 4.38,
    round_4: 2.85,
    app_5: 8.39,
    round_5: 2.86,
    app_6: 80.77,
    round_6: 2.88,
    cons_avg: 5.58,
    sample: 1405,
    sample_app_flat: 1031
  },
  {
    char: 'nicole-demara',
    app_rate: 58.59,
    avg_round: 2.87,
    std_dev_round: 0.37,
    role: 'Support',
    rarity: 'A',
    diff: -10.49,
    diff_rounds: 0.04,
    weapon_1: 'The Vault',
    weapon_1_app: 89.5,
    weapon_1_round: 2.88,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 4.89,
    weapon_2_round: 2.77,
    weapon_3: 'Slice of Time',
    weapon_3_app: 2.15,
    weapon_3_round: 2.71,
    weapon_4: 'Kaboom the Cannon',
    weapon_4_app: 1.37,
    weapon_4_round: 2.8,
    weapon_5: '[Reverb] Mark II',
    weapon_5_app: 1.04,
    weapon_5_round: 2.53,
    weapon_6: 'Unfettered Game Ball',
    weapon_6_app: 0.52,
    weapon_6_round: 2.67,
    weapon_7: 'Bashful Demon',
    weapon_7_app: 0.2,
    weapon_7_round: 2.5,
    weapon_8: '[Reverb] Mark III',
    weapon_8_app: 0.13,
    weapon_8_round: 3.0,
    weapon_9: '[Reverb] Mark I',
    weapon_9_app: 0.07,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Chaotic Metal',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Chaotic Metal',
    artifact_1_3: '',
    artifact_1_app: 40.18,
    artifact_1_round: 2.87,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 28.38,
    artifact_2_round: 2.87,
    artifact_3: 'Swing Jazz, Flex',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 5.09,
    artifact_3_round: 2.91,
    artifact_4: 'Swing Jazz, Woodpecker Electro',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 5.09,
    artifact_4_round: 2.95,
    artifact_5: 'Freedom Blues, Swing Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 4.57,
    artifact_5_round: 2.89,
    artifact_6: 'Chaotic Metal, Swing Jazz',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 2.87,
    artifact_6_round: 2.79,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 2.61,
    artifact_7_round: 2.88,
    artifact_8: 'Swing Jazz, Hormone Punk',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 1.96,
    artifact_8_round: 2.96,
    artifact_9: 'Swing Jazz, Puffer Electro',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.63,
    artifact_9_round: 2.56,
    artifact_10: 'Freedom Blues, Chaotic Metal',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Chaotic Metal',
    artifact_10_3: '',
    artifact_10_app: 0.72,
    artifact_10_round: 3.0,
    app_0: 0.26,
    round_0: 2.5,
    app_1: 1.89,
    round_1: 2.64,
    app_2: 3.72,
    round_2: 2.83,
    app_3: 6.78,
    round_3: 2.84,
    app_4: 8.35,
    round_4: 2.76,
    app_5: 7.7,
    round_5: 2.82,
    app_6: 71.3,
    round_6: 2.9,
    cons_avg: 5.29,
    sample: 1609,
    sample_app_flat: 1224
  },
  {
    char: 'Ellen',
    app_rate: 56.48,
    avg_round: 2.87,
    std_dev_round: 0.37,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 5.74,
    diff_rounds: 0.08,
    weapon_1: 'Deep Sea Visitor',
    weapon_1_app: 73.48,
    weapon_1_round: 2.9,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 10.16,
    weapon_2_round: 2.81,
    weapon_3: 'The Brimstone',
    weapon_3_app: 4.98,
    weapon_3_round: 2.83,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 4.79,
    weapon_4_round: 2.85,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 2.98,
    weapon_5_round: 2.74,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 2.2,
    weapon_6_round: 2.9,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.91,
    weapon_7_round: 2.67,
    weapon_8: 'Riot Suppressor Mark VI',
    weapon_8_app: 0.26,
    weapon_8_round: 3.0,
    weapon_9: '[Lunar] Pleniluna',
    weapon_9_app: 0.13,
    weapon_9_round: 3.0,
    weapon_10: 'The Restrained',
    weapon_10_app: 0.06,
    weapon_10_round: 3.0,
    artifact_1: 'Polar Metal, Woodpecker Electro',
    artifact_1_1: 'Polar Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 54.72,
    artifact_1_round: 2.85,
    artifact_2: 'Woodpecker Electro, Polar Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 13.45,
    artifact_2_round: 2.92,
    artifact_3: 'Woodpecker Electro, Puffer Electro',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 10.93,
    artifact_3_round: 2.87,
    artifact_4: 'Polar Metal, Puffer Electro',
    artifact_4_1: 'Polar Metal',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 9.18,
    artifact_4_round: 2.97,
    artifact_5: 'Polar Metal, Hormone Punk',
    artifact_5_1: 'Polar Metal',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 3.49,
    artifact_5_round: 2.91,
    artifact_6: 'Polar Metal, Flex',
    artifact_6_1: 'Polar Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.07,
    artifact_6_round: 2.7,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.55,
    artifact_7_round: 2.95,
    artifact_8: 'Woodpecker Electro, Flex',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.1,
    artifact_8_round: 3.0,
    artifact_9: 'Puffer Electro, Woodpecker Electro',
    artifact_9_1: 'Puffer Electro',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 0.71,
    artifact_9_round: 2.5,
    artifact_10: 'Polar Metal, Swing Jazz',
    artifact_10_1: 'Polar Metal',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 0.65,
    artifact_10_round: 3.0,
    app_0: 71.28,
    round_0: 2.87,
    app_1: 15.85,
    round_1: 2.94,
    app_2: 7.96,
    round_2: 3.0,
    app_3: 1.03,
    round_3: 3.0,
    app_4: 0.13,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 3.75,
    round_6: 3.0,
    cons_avg: 0.58,
    sample: 1551,
    sample_app_flat: 1093
  },
  {
    char: 'Lucy',
    app_rate: 38.86,
    avg_round: 2.86,
    std_dev_round: 0.37,
    role: 'Support',
    rarity: 'A',
    diff: -0.7,
    diff_rounds: 0.01,
    weapon_1: 'Kaboom the Cannon',
    weapon_1_app: 74.53,
    weapon_1_round: 2.88,
    weapon_2: 'Slice of Time',
    weapon_2_app: 7.57,
    weapon_2_round: 2.88,
    weapon_3: '[Reverb] Mark II',
    weapon_3_app: 5.8,
    weapon_3_round: 2.82,
    weapon_4: 'Weeping Cradle',
    weapon_4_app: 4.52,
    weapon_4_round: 2.86,
    weapon_5: 'Bashful Demon',
    weapon_5_app: 2.16,
    weapon_5_round: 2.71,
    weapon_6: 'Unfettered Game Ball',
    weapon_6_app: 2.06,
    weapon_6_round: 2.82,
    weapon_7: '[Reverb] Mark III',
    weapon_7_app: 1.77,
    weapon_7_round: 2.71,
    weapon_8: 'The Vault',
    weapon_8_app: 0.39,
    weapon_8_round: 3.0,
    weapon_9: 'Starlight Engine',
    weapon_9_app: 0.29,
    weapon_9_round: 2.0,
    weapon_10: '[Reverb] Mark I',
    weapon_10_app: 0.2,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 57.33,
    artifact_1_round: 2.86,
    artifact_2: 'Swing Jazz, Woodpecker Electro',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Woodpecker Electro',
    artifact_2_3: '',
    artifact_2_app: 7.18,
    artifact_2_round: 2.96,
    artifact_3: 'Swing Jazz, Freedom Blues',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Freedom Blues',
    artifact_3_3: '',
    artifact_3_app: 5.21,
    artifact_3_round: 2.83,
    artifact_4: 'Swing Jazz, Flex',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 4.62,
    artifact_4_round: 2.83,
    artifact_5: 'Swing Jazz, Inferno Metal',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Inferno Metal',
    artifact_5_3: '',
    artifact_5_app: 3.54,
    artifact_5_round: 2.84,
    artifact_6: 'Hormone Punk, Swing Jazz',
    artifact_6_1: 'Hormone Punk',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 3.44,
    artifact_6_round: 2.82,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.47,
    artifact_7_round: 2.92,
    artifact_8: 'Freedom Blues, Swing Jazz',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.47,
    artifact_8_round: 3.0,
    artifact_9: 'Swing Jazz, Puffer Electro',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.08,
    artifact_9_round: 3.0,
    artifact_10: 'Hormone Punk, Swing Jazz, Flex',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.98,
    artifact_10_round: 2.89,
    app_0: 3.83,
    round_0: 2.83,
    app_1: 14.06,
    round_1: 2.85,
    app_2: 21.63,
    round_2: 2.86,
    app_3: 19.67,
    round_3: 2.87,
    app_4: 13.37,
    round_4: 2.81,
    app_5: 11.7,
    round_5: 2.87,
    app_6: 15.73,
    round_6: 2.95,
    cons_avg: 3.23,
    sample: 1067,
    sample_app_flat: 925
  },
  {
    char: 'hrace-howard',
    app_rate: 8.92,
    avg_round: 2.84,
    std_dev_round: 0.41,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -3.86,
    diff_rounds: 0.05,
    weapon_1: 'Weeping Gemini',
    weapon_1_app: 33.88,
    weapon_1_round: 2.73,
    weapon_2: 'Fusion Compiler',
    weapon_2_app: 29.75,
    weapon_2_round: 2.91,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 22.73,
    weapon_3_round: 2.88,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 10.74,
    weapon_4_round: 2.92,
    weapon_5: 'Roaring Ride',
    weapon_5_app: 2.07,
    weapon_5_round: 3.0,
    weapon_6: '',
    weapon_6_app: 0.0,
    weapon_6_round: 1.0,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Freedom Blues, Thunder Metal',
    artifact_1_1: 'Freedom Blues',
    artifact_1_2: 'Thunder Metal',
    artifact_1_3: '',
    artifact_1_app: 38.84,
    artifact_1_round: 2.86,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 28.51,
    artifact_2_round: 2.78,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 10.33,
    artifact_3_round: 2.87,
    artifact_4: 'Freedom Blues, Chaos Jazz',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Chaos Jazz',
    artifact_4_3: '',
    artifact_4_app: 3.31,
    artifact_4_round: 2.67,
    artifact_5: 'Freedom Blues, Hormone Punk',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 2.48,
    artifact_5_round: 3.0,
    artifact_6: 'Thunder Metal, Flex',
    artifact_6_1: 'Thunder Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.07,
    artifact_6_round: 2.8,
    artifact_7: 'Thunder Metal, Swing Jazz',
    artifact_7_1: 'Thunder Metal',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 1.65,
    artifact_7_round: 3.0,
    artifact_8: 'Freedom Blues, Woodpecker Electro',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 1.65,
    artifact_8_round: 3.0,
    artifact_9: 'Chaos Jazz, Thunder Metal',
    artifact_9_1: 'Chaos Jazz',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: '',
    artifact_9_app: 1.24,
    artifact_9_round: 3.0,
    artifact_10: 'Chaos Jazz, Freedom Blues',
    artifact_10_1: 'Chaos Jazz',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 1.24,
    artifact_10_round: 3.0,
    app_0: 69.83,
    round_0: 2.84,
    app_1: 23.14,
    round_1: 2.85,
    app_2: 5.79,
    round_2: 2.91,
    app_3: 0.41,
    round_3: 2.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.41,
    round_5: 1.0,
    app_6: 0.41,
    round_6: 1.0,
    cons_avg: 0.4,
    sample: 245,
    sample_app_flat: 211
  },
  {
    char: 'Piper',
    app_rate: 14.09,
    avg_round: 2.83,
    std_dev_round: 0.4,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: 0.04,
    diff_rounds: 0.03,
    weapon_1: 'Roaring Ride',
    weapon_1_app: 78.11,
    weapon_1_round: 2.85,
    weapon_2: 'Fusion Compiler',
    weapon_2_app: 6.49,
    weapon_2_round: 2.92,
    weapon_3: 'Weeping Gemini',
    weapon_3_app: 5.68,
    weapon_3_round: 2.71,
    weapon_4: 'Rainforest Gourmet',
    weapon_4_app: 5.41,
    weapon_4_round: 2.75,
    weapon_5: 'Sharpened Stinger',
    weapon_5_app: 2.16,
    weapon_5_round: 3.0,
    weapon_6: 'Electro-Lip Gloss',
    weapon_6_app: 0.81,
    weapon_6_round: 2.67,
    weapon_7: 'Gilded Blossom',
    weapon_7_app: 0.27,
    weapon_7_round: 2.0,
    weapon_8: '[Magnetic Storm] Bravo',
    weapon_8_app: 0.27,
    weapon_8_round: 3.0,
    weapon_9: '[Magnetic Storm] Charlie',
    weapon_9_app: 0.27,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 35.68,
    artifact_1_round: 2.87,
    artifact_2: 'Freedom Blues, Fanged Metal',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 23.51,
    artifact_2_round: 2.82,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 17.84,
    artifact_3_round: 2.85,
    artifact_4: 'Fanged Metal, Chaos Jazz',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Chaos Jazz',
    artifact_4_3: '',
    artifact_4_app: 2.16,
    artifact_4_round: 2.88,
    artifact_5: 'Freedom Blues, Hormone Punk',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 2.16,
    artifact_5_round: 2.88,
    artifact_6: 'Fanged Metal, Hormone Punk',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 2.16,
    artifact_6_round: 3.0,
    artifact_7: 'Fanged Metal, Flex',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 1.89,
    artifact_7_round: 2.14,
    artifact_8: 'Fanged Metal, Swing Jazz',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.35,
    artifact_8_round: 3.0,
    artifact_9: 'Freedom Blues, Flex',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 1.35,
    artifact_9_round: 3.0,
    artifact_10: 'Freedom Blues, Puffer Electro',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: '',
    artifact_10_app: 1.08,
    artifact_10_round: 2.67,
    app_0: 2.43,
    round_0: 2.71,
    app_1: 7.3,
    round_1: 2.7,
    app_2: 10.0,
    round_2: 2.77,
    app_3: 8.92,
    round_3: 2.81,
    app_4: 13.24,
    round_4: 2.78,
    app_5: 10.54,
    round_5: 2.81,
    app_6: 47.57,
    round_6: 2.92,
    cons_avg: 4.45,
    sample: 387,
    sample_app_flat: 347
  },
  {
    char: 'Corin',
    app_rate: 4.95,
    avg_round: 2.81,
    std_dev_round: 0.41,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: 0.73,
    diff_rounds: 0.11,
    weapon_1: 'Housekeeper',
    weapon_1_app: 63.43,
    weapon_1_round: 2.79,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 18.66,
    weapon_2_round: 2.86,
    weapon_3: 'Starlight Engine',
    weapon_3_app: 5.22,
    weapon_3_round: 2.86,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 4.48,
    weapon_4_round: 2.75,
    weapon_5: 'Drill Rig - Red Axis',
    weapon_5_app: 1.49,
    weapon_5_round: 3.0,
    weapon_6: 'Street Superstar',
    weapon_6_app: 1.49,
    weapon_6_round: 3.0,
    weapon_7: 'The Brimstone',
    weapon_7_app: 1.49,
    weapon_7_round: 3.0,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 0.75,
    weapon_8_round: 3.0,
    weapon_9: 'Deep Sea Visitor',
    weapon_9_app: 0.75,
    weapon_9_round: 3.0,
    weapon_10: 'Cannon Rotor',
    weapon_10_app: 0.75,
    weapon_10_round: 3.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 35.07,
    artifact_1_round: 2.76,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 9.7,
    artifact_2_round: 2.91,
    artifact_3: 'Hormone Punk, Woodpecker Electro',
    artifact_3_1: 'Hormone Punk',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 8.96,
    artifact_3_round: 3.0,
    artifact_4: 'Puffer Electro, Woodpecker Electro',
    artifact_4_1: 'Puffer Electro',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 5.22,
    artifact_4_round: 2.86,
    artifact_5: 'Fanged Metal, Hormone Punk',
    artifact_5_1: 'Fanged Metal',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 4.48,
    artifact_5_round: 2.67,
    artifact_6: 'Fanged Metal, Hormone Punk, Woodpecker Electro',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: 'Woodpecker Electro',
    artifact_6_app: 3.73,
    artifact_6_round: 2.5,
    artifact_7: 'Hormone Punk, Fanged Metal',
    artifact_7_1: 'Hormone Punk',
    artifact_7_2: 'Fanged Metal',
    artifact_7_3: '',
    artifact_7_app: 3.73,
    artifact_7_round: 2.8,
    artifact_8: 'Woodpecker Electro, Swing Jazz',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 2.99,
    artifact_8_round: 3.0,
    artifact_9: 'Fanged Metal, Woodpecker Electro, Hormone Punk',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: 'Hormone Punk',
    artifact_9_app: 2.99,
    artifact_9_round: 2.75,
    artifact_10: 'Puffer Electro, Fanged Metal',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Fanged Metal',
    artifact_10_3: '',
    artifact_10_app: 2.99,
    artifact_10_round: 3.0,
    app_0: 3.73,
    round_0: 2.5,
    app_1: 3.73,
    round_1: 2.8,
    app_2: 3.73,
    round_2: 2.5,
    app_3: 10.45,
    round_3: 2.77,
    app_4: 6.72,
    round_4: 2.78,
    app_5: 8.96,
    round_5: 2.91,
    app_6: 62.69,
    round_6: 2.87,
    cons_avg: 4.9,
    sample: 136,
    sample_app_flat: 117
  },
  {
    char: 'billy-kid',
    app_rate: 1.93,
    avg_round: 2.8,
    std_dev_round: 0.46,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.05,
    diff_rounds: 0.31,
    weapon_1: 'Starlight Engine Replica',
    weapon_1_app: 50.94,
    weapon_1_round: 2.75,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 15.09,
    weapon_2_round: 2.88,
    weapon_3: 'The Brimstone',
    weapon_3_app: 9.43,
    weapon_3_round: 3.0,
    weapon_4: 'Starlight Engine',
    weapon_4_app: 7.55,
    weapon_4_round: 2.5,
    weapon_5: 'Street Superstar',
    weapon_5_app: 7.55,
    weapon_5_round: 2.75,
    weapon_6: 'Riot Suppressor Mark VI',
    weapon_6_app: 3.77,
    weapon_6_round: 3.0,
    weapon_7: 'Housekeeper',
    weapon_7_app: 1.89,
    weapon_7_round: 3.0,
    weapon_8: 'Gilded Blossom',
    weapon_8_app: 1.89,
    weapon_8_round: 3.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Woodpecker Electro, Fanged Metal',
    artifact_1_1: 'Woodpecker Electro',
    artifact_1_2: 'Fanged Metal',
    artifact_1_3: '',
    artifact_1_app: 20.75,
    artifact_1_round: 2.73,
    artifact_2: 'Woodpecker Electro, Puffer Electro',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 16.98,
    artifact_2_round: 2.88,
    artifact_3: 'Puffer Electro, Woodpecker Electro',
    artifact_3_1: 'Puffer Electro',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 16.98,
    artifact_3_round: 2.88,
    artifact_4: 'Woodpecker Electro, Hormone Punk',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 9.43,
    artifact_4_round: 2.75,
    artifact_5: 'Fanged Metal, Woodpecker Electro',
    artifact_5_1: 'Fanged Metal',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 7.55,
    artifact_5_round: 2.5,
    artifact_6: 'Woodpecker Electro, Flex',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 3.77,
    artifact_6_round: 3.0,
    artifact_7: 'Fanged Metal, Woodpecker Electro, Flex',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: 'Flex',
    artifact_7_app: 3.77,
    artifact_7_round: 3.0,
    artifact_8: 'Puffer Electro, Fanged Metal',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Fanged Metal',
    artifact_8_3: '',
    artifact_8_app: 3.77,
    artifact_8_round: 2.0,
    artifact_9: 'Hormone Punk, Fanged Metal',
    artifact_9_1: 'Hormone Punk',
    artifact_9_2: 'Fanged Metal',
    artifact_9_3: '',
    artifact_9_app: 3.77,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Hormone Punk, Woodpecker Electro',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: 'Woodpecker Electro',
    artifact_10_app: 3.77,
    artifact_10_round: 2.5,
    app_0: 0.0,
    round_0: 1.0,
    app_1: 3.77,
    round_1: 3.0,
    app_2: 3.77,
    round_2: 2.5,
    app_3: 13.21,
    round_3: 2.83,
    app_4: 5.66,
    round_4: 2.67,
    app_5: 7.55,
    round_5: 3.0,
    app_6: 66.04,
    round_6: 2.78,
    cons_avg: 5.08,
    sample: 53,
    sample_app_flat: 49
  },
  {
    char: 'anby-demara',
    app_rate: 17.92,
    avg_round: 2.79,
    std_dev_round: 0.47,
    role: 'Stun',
    rarity: 'A',
    diff: -12.08,
    diff_rounds: 0.01,
    weapon_1: 'Demara Battery Mark II',
    weapon_1_app: 59.08,
    weapon_1_round: 2.78,
    weapon_2: 'Steam Oven',
    weapon_2_app: 15.03,
    weapon_2_round: 2.75,
    weapon_3: 'Hellfire Gears',
    weapon_3_app: 10.65,
    weapon_3_round: 2.84,
    weapon_4: 'The Restrained',
    weapon_4_app: 9.81,
    weapon_4_round: 2.89,
    weapon_5: 'Precious Fossilized Core',
    weapon_5_app: 2.71,
    weapon_5_round: 2.75,
    weapon_6: '[Vortex] Arrow',
    weapon_6_app: 0.63,
    weapon_6_round: 2.5,
    weapon_7: 'Ice-Jade Teapot',
    weapon_7_app: 0.42,
    weapon_7_round: 2.5,
    weapon_8: 'Six Shooter',
    weapon_8_app: 0.42,
    weapon_8_round: 2.5,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 54.91,
    artifact_1_round: 2.81,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 15.87,
    artifact_2_round: 2.77,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 10.02,
    artifact_3_round: 2.75,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 5.43,
    artifact_4_round: 2.55,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 3.76,
    artifact_5_round: 2.86,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.88,
    artifact_6_round: 2.86,
    artifact_7: 'Shockstar Disco, Puffer Electro',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.46,
    artifact_7_round: 2.33,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.84,
    artifact_8_round: 3.0,
    artifact_9: 'Shockstar Disco, Thunder Metal, Woodpecker Electro',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: 'Woodpecker Electro',
    artifact_9_app: 0.63,
    artifact_9_round: 3.0,
    artifact_10: 'Shockstar Disco, Polar Metal',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Polar Metal',
    artifact_10_3: '',
    artifact_10_app: 0.63,
    artifact_10_round: 3.0,
    app_0: 3.76,
    round_0: 2.24,
    app_1: 5.01,
    round_1: 2.71,
    app_2: 10.44,
    round_2: 2.8,
    app_3: 10.02,
    round_3: 2.78,
    app_4: 11.69,
    round_4: 2.78,
    app_5: 11.9,
    round_5: 2.86,
    app_6: 47.18,
    round_6: 2.83,
    cons_avg: 4.45,
    sample: 492,
    sample_app_flat: 413
  },
  {
    char: 'Koleda',
    app_rate: 9.72,
    avg_round: 2.79,
    std_dev_round: 0.45,
    role: 'Stun',
    rarity: 'Standard S',
    diff: -7.53,
    diff_rounds: 0.04,
    weapon_1: 'Steam Oven',
    weapon_1_app: 37.64,
    weapon_1_round: 2.78,
    weapon_2: 'Hellfire Gears',
    weapon_2_app: 29.28,
    weapon_2_round: 2.79,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 13.31,
    weapon_3_round: 2.81,
    weapon_4: 'Six Shooter',
    weapon_4_app: 6.08,
    weapon_4_round: 2.64,
    weapon_5: 'The Restrained',
    weapon_5_app: 5.7,
    weapon_5_round: 2.93,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 3.42,
    weapon_6_round: 2.78,
    weapon_7: '[Vortex] Arrow',
    weapon_7_app: 2.66,
    weapon_7_round: 3.0,
    weapon_8: 'Ice-Jade Teapot',
    weapon_8_app: 0.76,
    weapon_8_round: 3.0,
    weapon_9: '[Vortex] Revolver',
    weapon_9_app: 0.76,
    weapon_9_round: 2.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 65.78,
    artifact_1_round: 2.77,
    artifact_2: 'Shockstar Disco, Inferno Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 8.37,
    artifact_2_round: 2.84,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 7.22,
    artifact_3_round: 2.75,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 4.18,
    artifact_4_round: 2.78,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 4.18,
    artifact_5_round: 2.89,
    artifact_6: 'Shockstar Disco, Puffer Electro',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 1.9,
    artifact_6_round: 3.0,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.9,
    artifact_7_round: 3.0,
    artifact_8: 'Inferno Metal, Shockstar Disco',
    artifact_8_1: 'Inferno Metal',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 1.14,
    artifact_8_round: 2.67,
    artifact_9: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: 'Flex',
    artifact_9_app: 0.76,
    artifact_9_round: 3.0,
    artifact_10: 'Freedom Blues, Shockstar Disco',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.76,
    artifact_10_round: 3.0,
    app_0: 69.96,
    round_0: 2.77,
    app_1: 22.43,
    round_1: 2.8,
    app_2: 6.84,
    round_2: 2.93,
    app_3: 0.38,
    round_3: 3.0,
    app_4: 0.38,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.39,
    sample: 267,
    sample_app_flat: 240
  },
  {
    char: 'Ben',
    app_rate: 2.69,
    avg_round: 2.75,
    std_dev_round: 0.46,
    role: 'Stun',
    rarity: 'A',
    diff: -1.48,
    diff_rounds: 0.11,
    weapon_1: 'Big Cylinder',
    weapon_1_app: 71.83,
    weapon_1_round: 2.8,
    weapon_2: 'Original Transmorpher',
    weapon_2_app: 8.45,
    weapon_2_round: 2.17,
    weapon_3: 'Bunny Band',
    weapon_3_app: 7.04,
    weapon_3_round: 2.6,
    weapon_4: 'Tusks of Fury',
    weapon_4_app: 5.63,
    weapon_4_round: 2.75,
    weapon_5: 'Demara Battery Mark II',
    weapon_5_app: 1.41,
    weapon_5_round: 3.0,
    weapon_6: '[Identity] Inflection',
    weapon_6_app: 1.41,
    weapon_6_round: 3.0,
    weapon_7: 'Spring Embrace',
    weapon_7_app: 1.41,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 8.45,
    artifact_1_round: 2.67,
    artifact_2: 'Swing Jazz, Soul Rock',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Soul Rock',
    artifact_2_3: '',
    artifact_2_app: 8.45,
    artifact_2_round: 2.67,
    artifact_3: 'Shockstar Disco, Soul Rock',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Soul Rock',
    artifact_3_3: '',
    artifact_3_app: 7.04,
    artifact_3_round: 2.8,
    artifact_4: 'Shockstar Disco, Soul Rock, Swing Jazz',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Soul Rock',
    artifact_4_3: 'Swing Jazz',
    artifact_4_app: 7.04,
    artifact_4_round: 2.8,
    artifact_5: 'Proto Punk, Swing Jazz',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 4.23,
    artifact_5_round: 3.0,
    artifact_6: 'Soul Rock, Shockstar Disco',
    artifact_6_1: 'Soul Rock',
    artifact_6_2: 'Shockstar Disco',
    artifact_6_3: '',
    artifact_6_app: 4.23,
    artifact_6_round: 3.0,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 2.82,
    artifact_7_round: 3.0,
    artifact_8: 'Inferno Metal, Soul Rock',
    artifact_8_1: 'Inferno Metal',
    artifact_8_2: 'Soul Rock',
    artifact_8_3: '',
    artifact_8_app: 2.82,
    artifact_8_round: 2.0,
    artifact_9: 'Inferno Metal, Woodpecker Electro',
    artifact_9_1: 'Inferno Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 2.82,
    artifact_9_round: 2.5,
    artifact_10: 'Soul Rock, Inferno Metal',
    artifact_10_1: 'Soul Rock',
    artifact_10_2: 'Inferno Metal',
    artifact_10_3: '',
    artifact_10_app: 2.82,
    artifact_10_round: 3.0,
    app_0: 1.41,
    round_0: 2.0,
    app_1: 4.23,
    round_1: 2.33,
    app_2: 7.04,
    round_2: 3.0,
    app_3: 15.49,
    round_3: 2.91,
    app_4: 8.45,
    round_4: 2.5,
    app_5: 12.68,
    round_5: 2.78,
    app_6: 50.7,
    round_6: 2.74,
    cons_avg: 4.66,
    sample: 74,
    sample_app_flat: 73
  },
  {
    char: 'soldier-11',
    app_rate: 9.36,
    avg_round: 2.74,
    std_dev_round: 0.51,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -3.37,
    diff_rounds: 0.01,
    weapon_1: 'The Brimstone',
    weapon_1_app: 37.01,
    weapon_1_round: 2.82,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 36.22,
    weapon_2_round: 2.67,
    weapon_3: 'Cannon Rotor',
    weapon_3_app: 10.24,
    weapon_3_round: 2.83,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 5.91,
    weapon_4_round: 2.8,
    weapon_5: 'Steel Cushion',
    weapon_5_app: 4.33,
    weapon_5_round: 2.64,
    weapon_6: 'Street Superstar',
    weapon_6_app: 3.94,
    weapon_6_round: 2.3,
    weapon_7: '[Lunar] Pleniluna',
    weapon_7_app: 0.79,
    weapon_7_round: 2.5,
    weapon_8: 'Deep Sea Visitor',
    weapon_8_app: 0.79,
    weapon_8_round: 2.5,
    weapon_9: '[Lunar] Decrescent',
    weapon_9_app: 0.39,
    weapon_9_round: 3.0,
    weapon_10: '[Lunar] Noviluna',
    weapon_10_app: 0.39,
    weapon_10_round: 3.0,
    artifact_1: 'Inferno Metal, Woodpecker Electro',
    artifact_1_1: 'Inferno Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 70.47,
    artifact_1_round: 2.75,
    artifact_2: 'Inferno Metal, Puffer Electro',
    artifact_2_1: 'Inferno Metal',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 11.42,
    artifact_2_round: 2.66,
    artifact_3: 'Woodpecker Electro, Inferno Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Inferno Metal',
    artifact_3_3: '',
    artifact_3_app: 6.69,
    artifact_3_round: 2.75,
    artifact_4: 'Inferno Metal, Swing Jazz',
    artifact_4_1: 'Inferno Metal',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 3.15,
    artifact_4_round: 2.71,
    artifact_5: 'Inferno Metal, Flex',
    artifact_5_1: 'Inferno Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 2.36,
    artifact_5_round: 2.67,
    artifact_6: 'Inferno Metal, Hormone Punk',
    artifact_6_1: 'Inferno Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.97,
    artifact_6_round: 3.0,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 0.79,
    artifact_7_round: 2.5,
    artifact_8: 'Inferno Metal, Freedom Blues',
    artifact_8_1: 'Inferno Metal',
    artifact_8_2: 'Freedom Blues',
    artifact_8_3: '',
    artifact_8_app: 0.39,
    artifact_8_round: 3.0,
    artifact_9: 'Woodpecker Electro, Puffer Electro',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 0.39,
    artifact_9_round: 1.0,
    artifact_10: 'Puffer Electro, Woodpecker Electro',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 0.39,
    artifact_10_round: 2.0,
    app_0: 65.75,
    round_0: 2.69,
    app_1: 29.53,
    round_1: 2.83,
    app_2: 3.54,
    round_2: 2.67,
    app_3: 0.79,
    round_3: 3.0,
    app_4: 0.39,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.41,
    sample: 257,
    sample_app_flat: 243
  },
  {
    char: 'Anton',
    app_rate: 2.59,
    avg_round: 2.72,
    std_dev_round: 0.52,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.28,
    diff_rounds: 0.26,
    weapon_1: 'Drill Rig - Red Axis',
    weapon_1_app: 67.14,
    weapon_1_round: 2.64,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 11.43,
    weapon_2_round: 2.86,
    weapon_3: 'The Brimstone',
    weapon_3_app: 8.57,
    weapon_3_round: 3.0,
    weapon_4: 'Street Superstar',
    weapon_4_app: 5.71,
    weapon_4_round: 2.5,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 2.86,
    weapon_5_round: 3.0,
    weapon_6: 'Gilded Blossom',
    weapon_6_app: 1.43,
    weapon_6_round: 3.0,
    weapon_7: 'Steel Cushion',
    weapon_7_app: 1.43,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Thunder Metal, Woodpecker Electro',
    artifact_1_1: 'Thunder Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 74.29,
    artifact_1_round: 2.78,
    artifact_2: 'Thunder Metal, Swing Jazz',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 7.14,
    artifact_2_round: 2.25,
    artifact_3: 'Woodpecker Electro, Thunder Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Thunder Metal',
    artifact_3_3: '',
    artifact_3_app: 2.86,
    artifact_3_round: 3.0,
    artifact_4: 'Thunder Metal, Flex',
    artifact_4_1: 'Thunder Metal',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 1.43,
    artifact_4_round: 1.0,
    artifact_5: 'Woodpecker Electro, Flex',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 1.43,
    artifact_5_round: 2.0,
    artifact_6: 'Swing Jazz, Thunder Metal, Woodpecker Electro',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Thunder Metal',
    artifact_6_3: 'Woodpecker Electro',
    artifact_6_app: 1.43,
    artifact_6_round: 2.0,
    artifact_7: 'Shockstar Disco, Flex',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 1.43,
    artifact_7_round: 2.0,
    artifact_8: 'Hormone Punk, Thunder Metal, Puffer Electro',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Thunder Metal',
    artifact_8_3: 'Puffer Electro',
    artifact_8_app: 1.43,
    artifact_8_round: 1.0,
    artifact_9: 'Thunder Metal, Puffer Electro',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.43,
    artifact_9_round: 3.0,
    artifact_10: 'Puffer Electro, Woodpecker Electro',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 1.43,
    artifact_10_round: 2.0,
    app_0: 4.29,
    round_0: 2.0,
    app_1: 1.43,
    round_1: 3.0,
    app_2: 4.29,
    round_2: 3.0,
    app_3: 4.29,
    round_3: 2.0,
    app_4: 5.71,
    round_4: 3.0,
    app_5: 10.0,
    round_5: 2.71,
    app_6: 70.0,
    round_6: 2.74,
    cons_avg: 5.16,
    sample: 71,
    sample_app_flat: 64
  }
];
