export const ShiyuDuos = [
  {
    char: 'Anton',
    round: 2.72,
    char_1: 'Qingyi',
    app_rate_1: 46.48,
    avg_round_1: 2.73,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 40.85,
    avg_round_2: 2.78,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 32.39,
    avg_round_3: 2.7,
    app_flat_3: 0,
    char_4: 'grace-howard',
    app_rate_4: 22.54,
    avg_round_4: 2.5,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 21.13,
    avg_round_5: 2.77,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 16.9,
    avg_round_6: 2.73,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 7.04,
    avg_round_7: 2.8,
    app_flat_7: 0,
    char_8: 'Lucy',
    app_rate_8: 5.63,
    avg_round_8: 3,
    app_flat_8: 0,
    char_9: 'Ben',
    app_rate_9: 2.82,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'billy-kid',
    app_rate_10: 1.41,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 1.41,
    avg_round_11: 2,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    round: 2.74,
    char_1: 'Lucy',
    app_rate_1: 85.21,
    avg_round_1: 2.73,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 43.58,
    avg_round_2: 2.8,
    app_flat_2: 0,
    char_3: 'Koleda',
    app_rate_3: 26.85,
    avg_round_3: 2.76,
    app_flat_3: 0,
    char_4: 'Ben',
    app_rate_4: 14.4,
    avg_round_4: 2.78,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 13.23,
    avg_round_5: 2.65,
    app_flat_5: 0,
    char_6: 'Piper',
    app_rate_6: 5.84,
    avg_round_6: 2.53,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 3.5,
    avg_round_7: 2.78,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 2.33,
    avg_round_8: 2.67,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 1.95,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Rina',
    app_rate_10: 0.78,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 0.78,
    avg_round_11: 2,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 0.78,
    avg_round_12: 2,
    app_flat_12: 0,
    char_13: 'Ellen',
    app_rate_13: 0.39,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ben',
    round: 2.75,
    char_1: 'Lucy',
    app_rate_1: 55.41,
    avg_round_1: 2.76,
    app_flat_1: 0,
    char_2: 'soldier-11',
    app_rate_2: 50.0,
    avg_round_2: 2.78,
    app_flat_2: 0,
    char_3: 'Koleda',
    app_rate_3: 25.68,
    avg_round_3: 2.68,
    app_flat_3: 0,
    char_4: 'Piper',
    app_rate_4: 17.57,
    avg_round_4: 2.77,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 10.81,
    avg_round_5: 2.86,
    app_flat_5: 0,
    char_6: 'grace-howard',
    app_rate_6: 6.76,
    avg_round_6: 2.8,
    app_flat_6: 0,
    char_7: 'Ellen',
    app_rate_7: 5.41,
    avg_round_7: 3,
    app_flat_7: 0,
    char_8: 'Qingyi',
    app_rate_8: 5.41,
    avg_round_8: 3,
    app_flat_8: 0,
    char_9: 'Soukaku',
    app_rate_9: 5.41,
    avg_round_9: 2.75,
    app_flat_9: 0,
    char_10: 'Anton',
    app_rate_10: 2.7,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 2.7,
    avg_round_11: 2.5,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 2.7,
    avg_round_12: 2,
    app_flat_12: 0,
    char_13: 'Corin',
    app_rate_13: 1.35,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Lycaon',
    app_rate_14: 1.35,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Seth',
    app_rate_15: 1.35,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 1.35,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'Rina',
    app_rate_17: 1.35,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'anby-demara',
    app_rate_18: 1.35,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    round: 2.79,
    char_1: 'nicole-demara',
    app_rate_1: 58.13,
    avg_round_1: 2.77,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 49.59,
    avg_round_2: 2.81,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 26.83,
    avg_round_3: 2.81,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 26.02,
    avg_round_4: 2.8,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 8.54,
    avg_round_5: 2.85,
    app_flat_5: 0,
    char_6: 'Seth',
    app_rate_6: 7.11,
    avg_round_6: 2.86,
    app_flat_6: 0,
    char_7: 'Anton',
    app_rate_7: 4.67,
    avg_round_7: 2.7,
    app_flat_7: 0,
    char_8: 'Nekomata',
    app_rate_8: 3.25,
    avg_round_8: 2.62,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 3.25,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Lucy',
    app_rate_10: 2.64,
    avg_round_10: 2.83,
    app_flat_10: 0,
    char_11: 'billy-kid',
    app_rate_11: 2.44,
    avg_round_11: 2.75,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 2.24,
    avg_round_12: 2.36,
    app_flat_12: 0,
    char_13: 'soldier-11',
    app_rate_13: 1.83,
    avg_round_13: 2.78,
    app_flat_13: 0,
    char_14: 'Caesar',
    app_rate_14: 1.02,
    avg_round_14: 2.6,
    app_flat_14: 0,
    char_15: 'Corin',
    app_rate_15: 1.02,
    avg_round_15: 2.8,
    app_flat_15: 0,
    char_16: 'Piper',
    app_rate_16: 0.41,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Koleda',
    app_rate_17: 0.2,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'Qingyi',
    app_rate_18: 0.2,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: 'Ben',
    app_rate_19: 0.2,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Koleda',
    round: 2.79,
    char_1: 'Lucy',
    app_rate_1: 34.08,
    avg_round_1: 2.77,
    app_flat_1: 0,
    char_2: 'Ellen',
    app_rate_2: 29.96,
    avg_round_2: 2.9,
    app_flat_2: 0,
    char_3: 'Soukaku',
    app_rate_3: 28.84,
    avg_round_3: 2.9,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 25.84,
    avg_round_4: 2.76,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 16.85,
    avg_round_5: 2.66,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 15.73,
    avg_round_6: 2.66,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 11.61,
    avg_round_7: 2.68,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 10.11,
    avg_round_8: 2.89,
    app_flat_8: 0,
    char_9: 'Ben',
    app_rate_9: 7.12,
    avg_round_9: 2.68,
    app_flat_9: 0,
    char_10: 'Caesar',
    app_rate_10: 4.49,
    avg_round_10: 2.8,
    app_flat_10: 0,
    char_11: 'Seth',
    app_rate_11: 3.37,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Rina',
    app_rate_12: 3.37,
    avg_round_12: 2.83,
    app_flat_12: 0,
    char_13: 'Corin',
    app_rate_13: 2.62,
    avg_round_13: 2.83,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 2.25,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 1.87,
    avg_round_15: 2.8,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 0.75,
    avg_round_16: 2.5,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.75,
    avg_round_17: 2.5,
    app_flat_17: 0,
    char_18: 'anby-demara',
    app_rate_18: 0.37,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'billy-kid',
    round: 2.8,
    char_1: 'Caesar',
    app_rate_1: 45.28,
    avg_round_1: 2.86,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 43.4,
    avg_round_2: 2.91,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 39.62,
    avg_round_3: 2.65,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 22.64,
    avg_round_4: 2.75,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 16.98,
    avg_round_5: 2.83,
    app_flat_5: 0,
    char_6: 'Piper',
    app_rate_6: 9.43,
    avg_round_6: 2.5,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 3.77,
    avg_round_7: 2.5,
    app_flat_7: 0,
    char_8: 'Rina',
    app_rate_8: 3.77,
    avg_round_8: 3,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 3.77,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Corin',
    app_rate_10: 1.89,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Anton',
    app_rate_11: 1.89,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 1.89,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Ben',
    app_rate_13: 1.89,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Corin',
    round: 2.81,
    char_1: 'Lucy',
    app_rate_1: 32.35,
    avg_round_1: 2.8,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 30.15,
    avg_round_2: 2.83,
    app_flat_2: 0,
    char_3: 'Qingyi',
    app_rate_3: 27.94,
    avg_round_3: 2.96,
    app_flat_3: 0,
    char_4: 'Rina',
    app_rate_4: 25.74,
    avg_round_4: 2.96,
    app_flat_4: 0,
    char_5: 'Lycaon',
    app_rate_5: 22.06,
    avg_round_5: 2.7,
    app_flat_5: 0,
    char_6: 'Piper',
    app_rate_6: 18.38,
    avg_round_6: 2.84,
    app_flat_6: 0,
    char_7: 'Soukaku',
    app_rate_7: 15.44,
    avg_round_7: 2.7,
    app_flat_7: 0,
    char_8: 'Ellen',
    app_rate_8: 5.15,
    avg_round_8: 2.6,
    app_flat_8: 0,
    char_9: 'Koleda',
    app_rate_9: 5.15,
    avg_round_9: 2.83,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 3.68,
    avg_round_10: 2.5,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 3.68,
    avg_round_11: 2.8,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 3.68,
    avg_round_12: 2.33,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 2.94,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'Ben',
    app_rate_14: 0.74,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 0.74,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Nekomata',
    app_rate_16: 0.74,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Piper',
    round: 2.83,
    char_1: 'Lucy',
    app_rate_1: 69.51,
    avg_round_1: 2.84,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 48.06,
    avg_round_2: 2.87,
    app_flat_2: 0,
    char_3: 'jane-doe',
    app_rate_3: 25.32,
    avg_round_3: 2.87,
    app_flat_3: 0,
    char_4: 'Nekomata',
    app_rate_4: 11.37,
    avg_round_4: 2.83,
    app_flat_4: 0,
    char_5: 'Seth',
    app_rate_5: 8.53,
    avg_round_5: 2.83,
    app_flat_5: 0,
    char_6: 'Koleda',
    app_rate_6: 8.01,
    avg_round_6: 2.68,
    app_flat_6: 0,
    char_7: 'Corin',
    app_rate_7: 6.46,
    avg_round_7: 2.84,
    app_flat_7: 0,
    char_8: 'soldier-11',
    app_rate_8: 3.88,
    avg_round_8: 2.53,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 3.62,
    avg_round_9: 2.93,
    app_flat_9: 0,
    char_10: 'Ben',
    app_rate_10: 3.36,
    avg_round_10: 2.77,
    app_flat_10: 0,
    char_11: 'Qingyi',
    app_rate_11: 3.36,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Rina',
    app_rate_12: 2.58,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'nicole-demara',
    app_rate_13: 1.81,
    avg_round_13: 2.57,
    app_flat_13: 0,
    char_14: 'billy-kid',
    app_rate_14: 1.29,
    avg_round_14: 2.5,
    app_flat_14: 0,
    char_15: 'Soukaku',
    app_rate_15: 0.78,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'anby-demara',
    app_rate_16: 0.52,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Ellen',
    app_rate_17: 0.52,
    avg_round_17: 1.5,
    app_flat_17: 0,
    char_18: 'Lycaon',
    app_rate_18: 0.26,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    round: 2.84,
    char_1: 'jane-doe',
    app_rate_1: 73.06,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'Seth',
    app_rate_2: 49.8,
    avg_round_2: 2.9,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 20.0,
    avg_round_3: 2.79,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 8.16,
    avg_round_4: 2.81,
    app_flat_4: 0,
    char_5: 'Anton',
    app_rate_5: 6.53,
    avg_round_5: 2.5,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 6.53,
    avg_round_6: 2.5,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 6.12,
    avg_round_7: 2.93,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 6.12,
    avg_round_8: 2.69,
    app_flat_8: 0,
    char_9: 'Piper',
    app_rate_9: 5.71,
    avg_round_9: 2.93,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 4.49,
    avg_round_10: 2.36,
    app_flat_10: 0,
    char_11: 'Ellen',
    app_rate_11: 2.86,
    avg_round_11: 2.86,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 2.86,
    avg_round_12: 2.71,
    app_flat_12: 0,
    char_13: 'Koleda',
    app_rate_13: 2.45,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'Ben',
    app_rate_14: 2.04,
    avg_round_14: 2.8,
    app_flat_14: 0,
    char_15: 'zhu-yuan',
    app_rate_15: 1.22,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Lycaon',
    app_rate_16: 0.82,
    avg_round_16: 2.5,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.41,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.41,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lucy',
    round: 2.86,
    char_1: 'jane-doe',
    app_rate_1: 45.55,
    avg_round_1: 2.94,
    app_flat_1: 0,
    char_2: 'Seth',
    app_rate_2: 35.61,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 33.74,
    avg_round_3: 2.87,
    app_flat_3: 0,
    char_4: 'Piper',
    app_rate_4: 25.21,
    avg_round_4: 2.84,
    app_flat_4: 0,
    char_5: 'soldier-11',
    app_rate_5: 20.52,
    avg_round_5: 2.73,
    app_flat_5: 0,
    char_6: 'Koleda',
    app_rate_6: 8.53,
    avg_round_6: 2.77,
    app_flat_6: 0,
    char_7: 'Nekomata',
    app_rate_7: 6.84,
    avg_round_7: 2.89,
    app_flat_7: 0,
    char_8: 'Qingyi',
    app_rate_8: 5.06,
    avg_round_8: 2.72,
    app_flat_8: 0,
    char_9: 'Corin',
    app_rate_9: 4.12,
    avg_round_9: 2.8,
    app_flat_9: 0,
    char_10: 'Ben',
    app_rate_10: 3.84,
    avg_round_10: 2.76,
    app_flat_10: 0,
    char_11: 'billy-kid',
    app_rate_11: 2.16,
    avg_round_11: 2.91,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 1.41,
    avg_round_12: 2.93,
    app_flat_12: 0,
    char_13: 'Soukaku',
    app_rate_13: 1.31,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'anby-demara',
    app_rate_14: 1.22,
    avg_round_14: 2.83,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 1.22,
    avg_round_15: 2.89,
    app_flat_15: 0,
    char_16: 'nicole-demara',
    app_rate_16: 1.12,
    avg_round_16: 2.91,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 0.84,
    avg_round_17: 2.57,
    app_flat_17: 0,
    char_18: 'Lycaon',
    app_rate_18: 0.56,
    avg_round_18: 2.67,
    app_flat_18: 0,
    char_19: 'Anton',
    app_rate_19: 0.37,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Rina',
    app_rate_20: 0.19,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    round: 2.87,
    char_1: 'zhu-yuan',
    app_rate_1: 87.2,
    avg_round_1: 2.88,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 64.33,
    avg_round_2: 2.92,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 17.78,
    avg_round_3: 2.77,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 6.53,
    avg_round_4: 2.89,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 3.11,
    avg_round_5: 2.85,
    app_flat_5: 0,
    char_6: 'Nekomata',
    app_rate_6: 2.98,
    avg_round_6: 2.87,
    app_flat_6: 0,
    char_7: 'Ellen',
    app_rate_7: 2.86,
    avg_round_7: 2.75,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 2.8,
    avg_round_8: 2.66,
    app_flat_8: 0,
    char_9: 'Lycaon',
    app_rate_9: 2.67,
    avg_round_9: 2.85,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 2.11,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'Seth',
    app_rate_11: 1.93,
    avg_round_11: 2.79,
    app_flat_11: 0,
    char_12: 'billy-kid',
    app_rate_12: 1.31,
    avg_round_12: 2.65,
    app_flat_12: 0,
    char_13: 'Anton',
    app_rate_13: 0.93,
    avg_round_13: 2.77,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 0.93,
    avg_round_14: 2.69,
    app_flat_14: 0,
    char_15: 'Lucy',
    app_rate_15: 0.75,
    avg_round_15: 2.91,
    app_flat_15: 0,
    char_16: 'Piper',
    app_rate_16: 0.44,
    avg_round_16: 2.57,
    app_flat_16: 0,
    char_17: 'Rina',
    app_rate_17: 0.44,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 0.31,
    avg_round_18: 2.5,
    app_flat_18: 0,
    char_19: 'soldier-11',
    app_rate_19: 0.31,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Ben',
    app_rate_20: 0.12,
    avg_round_20: 2.5,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ellen',
    round: 2.87,
    char_1: 'Soukaku',
    app_rate_1: 87.11,
    avg_round_1: 2.87,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 57.19,
    avg_round_2: 2.9,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 16.18,
    avg_round_3: 2.95,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 11.86,
    avg_round_4: 2.79,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 8.51,
    avg_round_5: 2.81,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 6.19,
    avg_round_6: 2.82,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 5.16,
    avg_round_7: 2.9,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 2.97,
    avg_round_8: 2.75,
    app_flat_8: 0,
    char_9: 'Lucy',
    app_rate_9: 0.84,
    avg_round_9: 2.89,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 0.45,
    avg_round_10: 2.86,
    app_flat_10: 0,
    char_11: 'Corin',
    app_rate_11: 0.45,
    avg_round_11: 2.6,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 0.45,
    avg_round_12: 2.33,
    app_flat_12: 0,
    char_13: 'Ben',
    app_rate_13: 0.26,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'Piper',
    app_rate_14: 0.13,
    avg_round_14: 1.5,
    app_flat_14: 0,
    char_15: 'Seth',
    app_rate_15: 0.13,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'jane-doe',
    app_rate_16: 0.06,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 0.06,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Soukaku',
    round: 2.87,
    char_1: 'Ellen',
    app_rate_1: 96.16,
    avg_round_1: 2.87,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 55.23,
    avg_round_2: 2.89,
    app_flat_2: 0,
    char_3: 'Qingyi',
    app_rate_3: 12.53,
    avg_round_3: 2.79,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 9.82,
    avg_round_4: 2.95,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 9.11,
    avg_round_5: 2.8,
    app_flat_5: 0,
    char_6: 'Koleda',
    app_rate_6: 5.48,
    avg_round_6: 2.9,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 2.42,
    avg_round_7: 2.75,
    app_flat_7: 0,
    char_8: 'Rina',
    app_rate_8: 2.14,
    avg_round_8: 2.79,
    app_flat_8: 0,
    char_9: 'Corin',
    app_rate_9: 1.49,
    avg_round_9: 2.7,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 1.07,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 1.0,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Seth',
    app_rate_12: 0.93,
    avg_round_12: 2.9,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 0.5,
    avg_round_13: 2.71,
    app_flat_13: 0,
    char_14: 'zhu-yuan',
    app_rate_14: 0.36,
    avg_round_14: 2.6,
    app_flat_14: 0,
    char_15: 'Ben',
    app_rate_15: 0.28,
    avg_round_15: 2.75,
    app_flat_15: 0,
    char_16: 'Piper',
    app_rate_16: 0.21,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.14,
    avg_round_17: 2.5,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.14,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    round: 2.88,
    char_1: 'nicole-demara',
    app_rate_1: 97.77,
    avg_round_1: 2.88,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 70.45,
    avg_round_2: 2.92,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 17.0,
    avg_round_3: 2.81,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 6.76,
    avg_round_4: 2.87,
    app_flat_4: 0,
    char_5: 'Koleda',
    app_rate_5: 2.93,
    avg_round_5: 2.66,
    app_flat_5: 0,
    char_6: 'Lycaon',
    app_rate_6: 1.74,
    avg_round_6: 2.83,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 0.63,
    avg_round_7: 2.57,
    app_flat_7: 0,
    char_8: 'Rina',
    app_rate_8: 0.49,
    avg_round_8: 3,
    app_flat_8: 0,
    char_9: 'Ellen',
    app_rate_9: 0.49,
    avg_round_9: 2.33,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 0.42,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Seth',
    app_rate_11: 0.35,
    avg_round_11: 2.6,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 0.35,
    avg_round_12: 2.6,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 0.21,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'Ben',
    app_rate_14: 0.14,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingyi',
    round: 2.88,
    char_1: 'nicole-demara',
    app_rate_1: 69.65,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 68.03,
    avg_round_2: 2.92,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 12.38,
    avg_round_3: 2.79,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 11.84,
    avg_round_4: 2.79,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 9.29,
    avg_round_5: 2.9,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 5.25,
    avg_round_6: 2.85,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 4.44,
    avg_round_7: 2.87,
    app_flat_7: 0,
    char_8: 'Lucy',
    app_rate_8: 3.63,
    avg_round_8: 2.72,
    app_flat_8: 0,
    char_9: 'Caesar',
    app_rate_9: 2.76,
    avg_round_9: 2.88,
    app_flat_9: 0,
    char_10: 'Corin',
    app_rate_10: 2.56,
    avg_round_10: 2.96,
    app_flat_10: 0,
    char_11: 'soldier-11',
    app_rate_11: 2.29,
    avg_round_11: 2.65,
    app_flat_11: 0,
    char_12: 'Anton',
    app_rate_12: 2.22,
    avg_round_12: 2.73,
    app_flat_12: 0,
    char_13: 'Nekomata',
    app_rate_13: 1.95,
    avg_round_13: 2.88,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 1.08,
    avg_round_14: 2.5,
    app_flat_14: 0,
    char_15: 'Piper',
    app_rate_15: 0.87,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 0.61,
    avg_round_16: 2.83,
    app_flat_16: 0,
    char_17: 'Ben',
    app_rate_17: 0.27,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'Lycaon',
    app_rate_18: 0.07,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 0.07,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lycaon',
    round: 2.88,
    char_1: 'Ellen',
    app_rate_1: 92.78,
    avg_round_1: 2.9,
    app_flat_1: 0,
    char_2: 'Soukaku',
    app_rate_2: 81.17,
    avg_round_2: 2.89,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 10.15,
    avg_round_3: 2.95,
    app_flat_3: 0,
    char_4: 'nicole-demara',
    app_rate_4: 4.5,
    avg_round_4: 2.85,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 3.66,
    avg_round_5: 2.73,
    app_flat_5: 0,
    char_6: 'Corin',
    app_rate_6: 3.14,
    avg_round_6: 2.7,
    app_flat_6: 0,
    char_7: 'zhu-yuan',
    app_rate_7: 2.62,
    avg_round_7: 2.83,
    app_flat_7: 0,
    char_8: 'Lucy',
    app_rate_8: 0.63,
    avg_round_8: 2.67,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 0.21,
    avg_round_9: 2.5,
    app_flat_9: 0,
    char_10: 'soldier-11',
    app_rate_10: 0.21,
    avg_round_10: 2,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 0.21,
    avg_round_11: 2.5,
    app_flat_11: 0,
    char_12: 'Ben',
    app_rate_12: 0.1,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 0.1,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Qingyi',
    app_rate_14: 0.1,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Piper',
    app_rate_15: 0.1,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Nekomata',
    round: 2.88,
    char_1: 'Lucy',
    app_rate_1: 45.62,
    avg_round_1: 2.89,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 43.75,
    avg_round_2: 2.95,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 30.0,
    avg_round_3: 2.87,
    app_flat_3: 0,
    char_4: 'Piper',
    app_rate_4: 27.5,
    avg_round_4: 2.83,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 18.12,
    avg_round_5: 2.88,
    app_flat_5: 0,
    char_6: 'jane-doe',
    app_rate_6: 10.62,
    avg_round_6: 2.93,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 10.0,
    avg_round_7: 2.62,
    app_flat_7: 0,
    char_8: 'Seth',
    app_rate_8: 6.25,
    avg_round_8: 2.9,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 4.38,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 1.25,
    avg_round_10: 2.5,
    app_flat_10: 0,
    char_11: 'Koleda',
    app_rate_11: 1.25,
    avg_round_11: 2.5,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 0.62,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Corin',
    app_rate_13: 0.62,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Rina',
    round: 2.9,
    char_1: 'jane-doe',
    app_rate_1: 60.24,
    avg_round_1: 2.93,
    app_flat_1: 0,
    char_2: 'Seth',
    app_rate_2: 47.99,
    avg_round_2: 2.93,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 19.28,
    avg_round_3: 2.82,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 15.66,
    avg_round_4: 2.85,
    app_flat_4: 0,
    char_5: 'grace-howard',
    app_rate_5: 9.84,
    avg_round_5: 2.79,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 8.23,
    avg_round_6: 2.95,
    app_flat_6: 0,
    char_7: 'Corin',
    app_rate_7: 7.03,
    avg_round_7: 2.96,
    app_flat_7: 0,
    char_8: 'Lycaon',
    app_rate_8: 7.03,
    avg_round_8: 2.73,
    app_flat_8: 0,
    char_9: 'Soukaku',
    app_rate_9: 6.02,
    avg_round_9: 2.79,
    app_flat_9: 0,
    char_10: 'Anton',
    app_rate_10: 5.82,
    avg_round_10: 2.78,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 3.21,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Piper',
    app_rate_12: 2.01,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Koleda',
    app_rate_13: 1.81,
    avg_round_13: 2.83,
    app_flat_13: 0,
    char_14: 'Nekomata',
    app_rate_14: 1.41,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'nicole-demara',
    app_rate_15: 1.41,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'zhu-yuan',
    app_rate_16: 1.41,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Lucy',
    app_rate_17: 0.4,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.4,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.4,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Ben',
    app_rate_20: 0.2,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Caesar',
    round: 2.91,
    char_1: 'jane-doe',
    app_rate_1: 37.37,
    avg_round_1: 2.94,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 29.83,
    avg_round_2: 2.87,
    app_flat_2: 0,
    char_3: 'Seth',
    app_rate_3: 28.58,
    avg_round_3: 2.96,
    app_flat_3: 0,
    char_4: 'Ellen',
    app_rate_4: 20.8,
    avg_round_4: 2.95,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 15.41,
    avg_round_5: 2.87,
    app_flat_5: 0,
    char_6: 'Soukaku',
    app_rate_6: 11.43,
    avg_round_6: 2.95,
    app_flat_6: 0,
    char_7: 'soldier-11',
    app_rate_7: 9.28,
    avg_round_7: 2.8,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 8.7,
    avg_round_8: 2.89,
    app_flat_8: 0,
    char_9: 'zhu-yuan',
    app_rate_9: 8.04,
    avg_round_9: 2.87,
    app_flat_9: 0,
    char_10: 'Lycaon',
    app_rate_10: 8.04,
    avg_round_10: 2.95,
    app_flat_10: 0,
    char_11: 'Nekomata',
    app_rate_11: 5.8,
    avg_round_11: 2.95,
    app_flat_11: 0,
    char_12: 'Corin',
    app_rate_12: 3.4,
    avg_round_12: 2.83,
    app_flat_12: 0,
    char_13: 'Rina',
    app_rate_13: 3.4,
    avg_round_13: 2.95,
    app_flat_13: 0,
    char_14: 'Qingyi',
    app_rate_14: 3.4,
    avg_round_14: 2.88,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 1.99,
    avg_round_15: 2.86,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 1.66,
    avg_round_16: 2.81,
    app_flat_16: 0,
    char_17: 'Anton',
    app_rate_17: 0.99,
    avg_round_17: 2.73,
    app_flat_17: 0,
    char_18: 'Koleda',
    app_rate_18: 0.99,
    avg_round_18: 2.8,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 0.41,
    avg_round_19: 2.6,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    round: 2.92,
    char_1: 'Seth',
    app_rate_1: 81.13,
    avg_round_1: 2.93,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 29.87,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 27.72,
    avg_round_3: 2.94,
    app_flat_3: 0,
    char_4: 'Rina',
    app_rate_4: 18.44,
    avg_round_4: 2.93,
    app_flat_4: 0,
    char_5: 'grace-howard',
    app_rate_5: 11.0,
    avg_round_5: 2.92,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 8.48,
    avg_round_6: 2.9,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 6.02,
    avg_round_7: 2.87,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 3.07,
    avg_round_8: 2.85,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 2.58,
    avg_round_9: 2.85,
    app_flat_9: 0,
    char_10: 'Koleda',
    app_rate_10: 1.66,
    avg_round_10: 2.89,
    app_flat_10: 0,
    char_11: 'Nekomata',
    app_rate_11: 1.04,
    avg_round_11: 2.93,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 0.92,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Ben',
    app_rate_13: 0.49,
    avg_round_13: 2.86,
    app_flat_13: 0,
    char_14: 'zhu-yuan',
    app_rate_14: 0.37,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'soldier-11',
    app_rate_15: 0.37,
    avg_round_15: 2.67,
    app_flat_15: 0,
    char_16: 'Corin',
    app_rate_16: 0.31,
    avg_round_16: 2.33,
    app_flat_16: 0,
    char_17: 'Lycaon',
    app_rate_17: 0.12,
    avg_round_17: 2.5,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.12,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Ellen',
    app_rate_19: 0.06,
    avg_round_19: 1,
    app_flat_19: 0,
    char_20: 'Anton',
    app_rate_20: 0.06,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seth',
    round: 2.92,
    char_1: 'jane-doe',
    app_rate_1: 98.73,
    avg_round_1: 2.93,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 28.42,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 25.8,
    avg_round_3: 2.96,
    app_flat_3: 0,
    char_4: 'Rina',
    app_rate_4: 17.88,
    avg_round_4: 2.93,
    app_flat_4: 0,
    char_5: 'grace-howard',
    app_rate_5: 9.12,
    avg_round_5: 2.9,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 4.94,
    avg_round_6: 2.87,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 2.62,
    avg_round_7: 2.86,
    app_flat_7: 0,
    char_8: 'Piper',
    app_rate_8: 2.47,
    avg_round_8: 2.83,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 2.32,
    avg_round_9: 2.79,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 0.97,
    avg_round_10: 2.9,
    app_flat_10: 0,
    char_11: 'Nekomata',
    app_rate_11: 0.75,
    avg_round_11: 2.9,
    app_flat_11: 0,
    char_12: 'Koleda',
    app_rate_12: 0.67,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 0.37,
    avg_round_13: 2.6,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 0.3,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 0.15,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.07,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: 'Lycaon',
    app_rate_17: 0.07,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
